<dialog-layout [header]="dialogHeader" (closeDialog)="close()">
  <div class="row tasks-list-filters">
    @if (!data.showOnlyFlights) {
    <mat-slide-toggle color="primary" [(ngModel)]="filtersSetting.designs.enabled" (change)="filterList()" [disabled]="isFiltersDisabled">
      <ng-container i18n="@@detailedSite.addTaskOrDesignDialog.toggleOptionDesigns">Designs</ng-container>
    </mat-slide-toggle>
    <div class="vertical-line"></div>
    <mat-slide-toggle color="primary" [(ngModel)]="filtersSetting.flights.enabled" (change)="filterList()" [disabled]="isFiltersDisabled">
      <ng-container i18n="@@detailedSite.addTaskOrDesignDialog.toggleOptionFlights">Flights</ng-container>
    </mat-slide-toggle>
    }
    <div class="field-date-control full-container">
      <input
        id="startDate"
        i18n-placeholder="@@detailedSite.addTaskOrDesignDialog.startDateFieldPlaceholder"
        placeholder="From"
        [(ngModel)]="filtersSetting.flights.startDate"
        [matDatepicker]="fromDatepicker"
        class="field-input-control small date"
        [min]="minDate"
        [max]="maxDate"
        title="Flights From"
        i18n-title="@@detailedSite.addTaskOrDesignDialog.startDateFieldTitle"
        [disabled]="!filtersSetting.flights.enabled"
        readonly
        (dateChange)="filterList()"
      />
      <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #fromDatepicker></mat-datepicker>
    </div>
    <div>-</div>
    <div class="field-date-control full-container">
      <input
        id="startDate"
        placeholder="To"
        i18n-placeholder="@@detailedSite.addTaskOrDesignDialog.endDateFieldPlaceholder"
        [(ngModel)]="filtersSetting.flights.endDate"
        [matDatepicker]="toDatepicker"
        class="field-input-control small date"
        [min]="minDate"
        [max]="maxDate"
        title="Flights To"
        i18n-title="@@detailedSite.addTaskOrDesignDialog.endDateFieldTitle"
        [disabled]="!filtersSetting.flights.enabled"
        readonly
        (dateChange)="filterList()"
      />
      <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #toDatepicker></mat-datepicker>
    </div>
  </div>
  <div class="tasks-list">
    <mat-selection-list>
      <ng-container *ngFor="let taskOrDesignOption of filteredList; let isLast = last">
        <mat-list-option
          class="task-or-design-option"
          color="accent"
          [checkboxPosition]="'before'"
          [value]="taskOrDesignOption"
          [selected]="selectedItems.isSelected(taskOrDesignOption)"
          (click)="updateSelectedItems(taskOrDesignOption)"
        >
          {{ taskOrDesignOption | taskOrDesignName }}
        </mat-list-option>

        <mat-divider *ngIf="!isLast"></mat-divider>
      </ng-container>
    </mat-selection-list>
    <div
      *ngIf="!(filteredList | isDefined)"
      class="container-message full-height row justify-center"
      i18n="@@detailedSite.addTaskOrDesignDialog.noTaskOrDesignMessage"
    >No tasks or designs founds</div>
  </div>
  <div class="row space-between tasks-list-footer">
    <mat-checkbox
      color="accent"
      class="select-all-checkbox"
      [ngModel]="allFilteredItemsSelected"
      [disabled]="!(filteredList | isDefined)"
      i18n="@@general.actionButtons.selectAll"
      (change)="toggleAllFilteredItemSelection($event)"
      >Select all</mat-checkbox
    >
    <button
      mat-flat-button
      mat-dialog-close
      class="dark-btn submit-button"
      [disabled]="isSelectionEmpty"
      title="Add"
      i18n-title="@@general.actionButtons.add"
      (click)="submitTaskOrDesignSelector()"
    >
      <ng-container i18n="@@general.actionButtons.add">Add</ng-container>
    </button>
  </div>
</dialog-layout>
