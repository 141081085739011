import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cartesian3 } from '@datumate/angular-cesium';
import { flatten, groupBy } from 'lodash';
import moment from 'moment';
import { catchError, filter, forkJoin, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { CollectProjectPlanUnitsRequest } from '../../../../generated/activity/model/collectProjectPlanUnitsRequest';
import { CollectProjectPlanUnitsResponse } from '../../../../generated/activity/model/collectProjectPlanUnitsResponse';
import { CreateProjectPlanRequest } from '../../../../generated/activity/model/createProjectPlanRequest';
import { CreateStandaloneActivityRequest } from '../../../../generated/activity/model/createStandaloneActivityRequest';
import { GetActivitiesResponse } from '../../../../generated/activity/model/getActivitiesResponse';
import { GetAllProjectPlansVersionsResponse } from '../../../../generated/activity/model/getAllProjectPlansVersionsResponse';
import { GetProjectPlanCustomFieldsResponse } from '../../../../generated/activity/model/getProjectPlanCustomFieldsResponse';
import { GetProjectPlanNameRequest } from '../../../../generated/activity/model/getProjectPlanNameRequest';
import { GetStandaloneActivitiesResponse } from '../../../../generated/activity/model/getStandaloneActivitiesResponse';
import {
  CreateActivityResponse,
  CreateActualMeasurementsListRequest,
  CreateMeasurementsListResponse,
  CreateMergeActivitiesRequest,
  CreateMergeActivitiesResponse,
  CreatePlannedMeasurementsListRequest,
  GetActivityResponse,
  GetMeasurementsResponse,
  UpdateActualMeasurementsListRequest,
  UpdatePlannedMeasurementsListRequest
} from '../../../../generated/activity/model/models';
import { UpdateActivityRequest } from '../../../../generated/activity/model/updateActivityRequest';
import { UploadProjectPlanRequest } from '../../../../generated/file/model/uploadProjectPlanRequest';
import { UploadProjectPlanResponse } from '../../../../generated/file/model/uploadProjectPlanResponse';
import { AuthQuery } from '../../../auth/state/auth.query';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { ResourceLinkType } from '../../../shared/resource-links/resource-links.model';
import { ResourceLinksQuery } from '../../../shared/resource-links/resource-links.query';
import { ResourceLinksService } from '../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { SnackBarService } from '../../../shared/services/snackbar.service';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { PolygonPolylineEditorObservable } from '../../../shared/utils/cesium-common';
import { fromMomentToDate } from '../../../shared/utils/formatting';
import { isDefined } from '../../../shared/utils/general';
import { roundTo } from '../../../shared/utils/math';
import { SiteMapService } from '../../services/site-map.service';
import { DetailedSiteQuery } from '../detailed-site.query';
import { generateTempEntityId, isNewEntity } from '../detailed-site.utils';
import { ActivityMeasurementCalcService } from './activity-measurement-calc.service';
import {
  Activity,
  ACTIVITY_FILTERING_STORAGE_KEY,
  ActivityCreatedBy,
  ActivityEntityType,
  ActivityFiltering,
  ActivityMeasurement,
  ActivityMeasurementEditor,
  ActivityMeasurementSourceType,
  ActivityMeasurementType,
  ActivityMeasurementValues,
  ActivitySorting,
  ActivityType,
  GanttActivity,
  ProjectPlan,
  ProjectPlanUploadingState,
  StandaloneActivity
} from './detailed-site-activities.model';
import { DetailedSiteActivitiesQuery, sortProjectPlanFunc } from './detailed-site-activities.query';
import { DetailedSiteActivitiesStore } from './detailed-site-activities.store';
import {
  calcMeasurementValuesSum,
  DetailedSiteActivitiesUtilsService,
  groupMeasurementsByType,
  isActual,
  isCount,
  isNewActivityMeasurement,
  isPlanned,
  sortMeasurementsByDate
} from './detailed-site-activities-utils.service';

const PROJECT_PLAN_PERMISSION_HEADER = {
  read: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.projectPlans.read },
  create: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.projectPlans.create },
  delete: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.projectPlans.delete },
  update: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.projectPlans.update }
};

const ACTIVITY_PERMISSION_HEADER = {
  read: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activities.read },
  create: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activities.create },
  delete: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activities.delete },
  update: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activities.update }
};

const ACTIVITY_MEASUREMENTS_PERMISSION_HEADER = {
  read: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activityMeasurements.read },
  create: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activityMeasurements.create },
  delete: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activityMeasurements.delete },
  update: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.activityMeasurements.update }
};

@Injectable({ providedIn: 'root' })
export class DetailedSiteActivitiesService {
  constructor(
    private activitiesStore: DetailedSiteActivitiesStore,
    private activitiesQuery: DetailedSiteActivitiesQuery,
    private activitiesUtils: DetailedSiteActivitiesUtilsService,
    private resourceLinksService: ResourceLinksService,
    private http: HttpClient,
    private siteQuery: DetailedSiteQuery,
    private analyticsService: AnalyticsService,
    private authQuery: AuthQuery,
    private calcService: ActivityMeasurementCalcService,
    private siteMapService: SiteMapService,
    private resourceLinksQuery: ResourceLinksQuery,
    private snackbar: SnackBarService,
    private analytics: AnalyticsService
  ) {}

  init() {
    // this.activitiesStore.restoreFiltering();
  }

  setSorting(sorting: ActivitySorting) {
    this.activitiesStore.setSorting(sorting);
  }

  setFiltering(filtering: ActivityFiltering) {
    this.activitiesStore.setFiltering(filtering);
    localStorage.setItem(ACTIVITY_FILTERING_STORAGE_KEY, JSON.stringify(filtering));
  }

  uploadProjectPlan(file: File) {
    this.setProjectPlanUploadingState(null);

    const siteId = this.siteQuery.getSiteId();

    const uploadRequest: UploadProjectPlanRequest = {
      fileName: file.name,
      name: file.name
    };

    this.setProjectPlanUploadingState({ totalSize: file.size });

    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/projectPlans`, uploadRequest, { headers: PROJECT_PLAN_PERMISSION_HEADER.create })
      .pipe(
        switchMap((uploadResponse: UploadProjectPlanResponse) => {
          const { url, version } = uploadResponse;

          return this.http.put(url, file, { reportProgress: true, observe: 'events', headers: PROJECT_PLAN_PERMISSION_HEADER.create }).pipe(
            tap(resp => {
              if (resp.type === HttpEventType.UploadProgress) {
                this.setProjectPlanUploadingState({ uploadedSize: resp.loaded });
              }
            }),
            filter(resp => resp.type === HttpEventType.Response),
            map(() => version)
          );
        })
      );
  }

  createProjectPlan(version: string, request: CreateProjectPlanRequest) {
    const siteId = this.siteQuery.getSiteId();
    return this.http.post<CreateMergeActivitiesResponse>(
      `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/versions/${version}`,
      request,
      { headers: PROJECT_PLAN_PERMISSION_HEADER.create }
    );
  }

  completeProjectPlanCreation(version: string, projectPlanFileName: string) {
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/projectPlans/versions/${version}/completeUpload`, null, {
        headers: PROJECT_PLAN_PERMISSION_HEADER.create
      })
      .pipe(tap(() => this.analyticsService.uploadProjectPlan(projectPlanFileName, version)));
  }

  completeMergeActivities(request: CreateMergeActivitiesRequest) {
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .put(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/completeMergeActivities`, request, {
        headers: PROJECT_PLAN_PERMISSION_HEADER.create
      })
      .pipe(
        tap(() =>
          this.analyticsService.completeMergeProjectPlanConflicts(
            request.mergeFromProjectPlanId,
            request.projectPlanId,
            request.request.length
          )
        )
      );
  }

  fetchProjectPlanCustomFields(version: string, fileName: string) {
    const siteId = this.siteQuery.getSiteId();
    const request: GetProjectPlanNameRequest = { fileName };
    return this.http.put<GetProjectPlanCustomFieldsResponse>(
      `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/versions/${version}/projectPlanCustomField`,
      request,
      { headers: PROJECT_PLAN_PERMISSION_HEADER.create }
    );
  }

  fetchProjectPlanUnknownUnits(version: string, fileName: string, unitsFieldName: string): Observable<CollectProjectPlanUnitsResponse> {
    const siteId = this.siteQuery.getSiteId();
    const request: CollectProjectPlanUnitsRequest = { fileName, unitsFieldName };
    return this.http.put(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/versions/${version}/projectPlanUnits`, request, {
      headers: PROJECT_PLAN_PERMISSION_HEADER.create
    });
  }

  fetchSiteProjectPlans(siteId: string) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.projectPlans.read)) {
      return of(null);
    }

    return this.http
      .get(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans`, { headers: PROJECT_PLAN_PERMISSION_HEADER.read })
      .pipe(
        map((resp: GetAllProjectPlansVersionsResponse) =>
          resp?.getProjectPlanVersionsResponses?.map(
            pp =>
              ({
                version: pp.versions,
                id: pp.projectPlanId,
                creationTime: pp.creationTime,
                name: pp.projectPlanName,
                active: pp.active
              }) as Partial<ProjectPlan>
          )
        ),
        tap((projectPlans: Partial<ProjectPlan>[]) => {
          if (isDefined(projectPlans)) {
            const sortedReadyProjectPlans = projectPlans.sort(sortProjectPlanFunc);
            const activeId = sortedReadyProjectPlans.find(pp => pp.active)?.id;
            this.activitiesStore.projectPlans.set(projectPlans, { activeId });
          }
        })
      );
  }

  fetchProjectPlanVersionActivities(version: string) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.projectPlans.read) || !isDefined(version)) {
      return of(null);
    }

    const siteId = this.siteQuery.getSiteId();
    return this.http
      .get(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/versions/${version}`, {
        headers: PROJECT_PLAN_PERMISSION_HEADER.read
      })
      .pipe(
        map((projectPlan: ProjectPlan) => {
          const activities = projectPlan.childActivities;
          const flattenedActivities = isDefined(activities) ? this.flatParentAndChildActivities(activities.activityResponseList) : [];
          return flattenedActivities;
        })
      );
  }

  fetchProjectPlanVersion(version?: string) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.projectPlans.read)) {
      return of(null);
    }

    this.setActivitiesLoading();

    const siteId = this.siteQuery.getSiteId();

    let request$: Observable<any>;
    version = version ?? this.activitiesQuery.getActiveVersion();
    if (isDefined(version)) {
      request$ = this.http
        .get(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/versions/${version}`, {
          headers: PROJECT_PLAN_PERMISSION_HEADER.read
        })
        .pipe(
          tap((projectPlan: ProjectPlan) => {
            if (this.activitiesQuery.getActiveVersion() === projectPlan.version) {
              this.activitiesStore.projectPlans.update(projectPlan.id, projectPlan);
            } else if (this.activitiesQuery.getProjectPlansCount() > 0) {
              this.activitiesStore.projectPlans.updateActive({ active: false });
              this.activitiesStore.projectPlans.add(projectPlan);
              this.activitiesStore.projectPlans.setActive(projectPlan.id);
            } else {
              this.activitiesStore.projectPlans.set([projectPlan], { activeId: projectPlan.id });
            }
            this.updateActivities(projectPlan.childActivities);
            this.setActivitiesLoading(false);
          })
        );
    } else {
      request$ = this.http
        .get<GetStandaloneActivitiesResponse>(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/standalone`, {
          headers: ACTIVITY_PERMISSION_HEADER.read
        })
        .pipe(
          tap(response => {
            if (isDefined(response?.getStandaloneActivityResponseList)) {
              this.updateActivities({ standaloneActivityResponseList: response.getStandaloneActivityResponseList });
            }
            this.setActivitiesLoading(false);
          })
        );
    }

    return request$;
  }

  private updateActivities(response: GetActivitiesResponse) {
    if (!isDefined(response) || (!isDefined(response.activityResponseList) && !isDefined(response.standaloneActivityResponseList))) {
      return;
    }

    this.activitiesStore.activities.reset();
    const standaloneActivities =
      response.standaloneActivityResponseList?.map(
        activity => ({ ...activity, type: ActivityEntityType.STANDALONE, tracked: true }) as Activity
      ) || [];
    const flattenedActivities = this.flatParentAndChildActivities(response.activityResponseList);
    this.activitiesStore.upsertActivities([...flattenedActivities, ...standaloneActivities]);
  }

  setActiveVersion(version: string) {
    this.setActiveActivity(null);
    const id = this.activitiesQuery.getProjectPlanIdByVersion(version);
    this.activitiesStore.projectPlans.setActive(id);
    return this.fetchProjectPlanVersion();
  }

  deleteVersion(projectPlan: ProjectPlan) {
    const siteId = this.siteQuery.getSiteId();
    const version = projectPlan.version;
    const isActiveVersion = projectPlan.id === this.activitiesQuery.getActiveProjectPlan().id;

    this.setActivitiesLoading();
    this.analyticsService.deleteProjectPlanVersion(projectPlan);
    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/projectPlans/versions/${version}`, {
        headers: PROJECT_PLAN_PERMISSION_HEADER.delete
      })
      .pipe(
        tap(() => {
          this.activitiesStore.projectPlans.remove(projectPlan.id);
          if (isActiveVersion) {
            const remainingProjectPlans = this.activitiesQuery.getAllProjectPlans();
            if (isDefined(remainingProjectPlans)) {
              const defaultActiveId = remainingProjectPlans[remainingProjectPlans.length - 1].id;
              this.activitiesStore.projectPlans.setActive(defaultActiveId);
            }
            this.deleteActiveVersionActivityLinks();
          }
          this.setActivitiesLoading(false);
        }),
        switchMap(() => (isActiveVersion ? this.fetchProjectPlanVersion() : of(null)))
      );
  }

  deleteActiveVersion() {
    const activeProjectPlan = this.activitiesQuery.getActiveProjectPlan();
    this.deleteVersion(activeProjectPlan);
  }

  private deleteActiveVersionActivityLinks() {
    const activities = this.activitiesQuery.getAllGanttActivities();
    this.resourceLinksService.removeResourcesOfType(
      activities.map(activity => activity.id),
      ResourceLinkType.ACTIVITY
    );
  }

  deleteAllVersions() {
    const siteId = this.siteQuery.getSiteId();
    this.setActivitiesLoading();
    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/projectPlans/versions`, {
        headers: PROJECT_PLAN_PERMISSION_HEADER.delete
      })
      .pipe(
        tap(() => {
          this.resourceLinksService.removeAllResourcesOfType(ResourceLinkType.ACTIVITY);
          this.analyticsService.deleteAllProjectPlanVersions();
          this.setActivitiesLoading(false);
          this.resetStore();
        })
      );
  }

  resetStore() {
    this.activitiesStore.resetStore();
  }

  private setProjectPlanUploadingState(uploadingState: Partial<ProjectPlanUploadingState>) {
    this.activitiesStore.setProjectPlanUploadingState(uploadingState);
  }

  setActivitiesLoading(isLoading: boolean = true) {
    this.activitiesStore.setActivitiesLoading(isLoading);
  }

  setActivityMeasurementsLoading(isLoading: boolean = true) {
    this.activitiesStore.setActivityMeasurementsLoading(isLoading);
  }

  addNewStandaloneActivity(defaultValues?: Partial<Activity>, shouldMakeActiveEntity = true) {
    const activity = this.initNewStandaloneActivity(defaultValues);
    this.activitiesStore.addStandaloneActivity(activity);
    if (shouldMakeActiveEntity) {
      this.setActiveActivity(activity.id);
    }
    return activity;
  }

  private initNewStandaloneActivity(defaultValues?: Partial<Activity>) {
    const userId = this.authQuery.getUserId();
    const siteId = this.siteQuery.getSiteId();
    const activity: StandaloneActivity = {
      name: $localize`:@@detailedSite.activitiesService.newActivityName:New activity`,
      ...defaultValues,
      id: generateTempEntityId(),
      siteId,
      type: ActivityEntityType.STANDALONE,
      tracked: true,
      activityOwner: userId,
      description: null,
      complete: 0,
      createdBy: ActivityCreatedBy.USER,
      geometric: true
    };
    return activity;
  }

  setActiveActivity(id: string) {
    const idToActivate = id;
    const currentActiveId = this.activitiesQuery.getActiveActivityId();

    // Deactivate current active activity
    if (currentActiveId) {
      this.activitiesStore.activities.setActive(null);
      this.resetActivityMeasurementsAndEditors();
    }

    // Activate new activity if wasn't active before
    if (idToActivate && idToActivate !== currentActiveId) {
      const siteId = this.siteQuery.getSiteId();
      this.resourceLinksService.fetchResourceLinks(siteId, idToActivate, ResourceLinkType.ACTIVITY).subscribe(() => {
        this.activitiesStore.activities.setActive(id);
        this.analyticsService.openExistingActivityDetails({
          ...this.activitiesQuery.getActivityById(id),
          projectPlanId: this.activitiesQuery.getActiveProjectPlanId(),
          version: this.activitiesQuery.getActiveVersion()
        });
      });
    }
  }

  fetchActivityMeasurements(activity: Activity) {
    const fetchedMeasurements$ = this.serverFetchActivityMeasurements(activity).pipe(
      switchMap((resp: GetMeasurementsResponse) => {
        // Count activity shouldn't have planned measurements created by user,
        // but should have a 'fixed' one (with values = activity amount) to work with count activity like with other types.
        // Thus, if count activity hasn't any measurements after fetch,
        // a new planned measurement is generated, saved to BE, fetched and returned as a result.
        // Otherway, the response of the first fetch is returned.
        if (!isCount(activity) || isDefined(resp.measurementsResponseList)) {
          return of(resp);
        }
        const countPlannedMeasurement: ActivityMeasurement = {
          measurementType: ActivityMeasurementType.PLANNED,
          sourceType: ActivityMeasurementSourceType.NONE,
          values: { amount: activity.amount },
          name: this.activitiesUtils.generatePlannedMeasurementName(activity.activityType)
        };

        const serverCreate$ = this.serverCreatePlannedMeasurements(activity.id, [countPlannedMeasurement]);
        return serverCreate$.pipe(
          switchMap(() => {
            return this.serverFetchActivityMeasurements(activity);
          })
        );
      })
    );

    return fetchedMeasurements$.pipe(
      map((resp: GetMeasurementsResponse) => {
        return resp.measurementsResponseList.map(measurement => ({
          ...measurement,
          positions: isDefined(measurement.coordinates)
            ? measurement.coordinates.map(coord => new Cesium.Cartesian3(coord.x, coord.y, coord.z))
            : null,
          isEnabledEditor: !activity.markedAsComplete,
          date: isPlanned(measurement) ? null : this.siteQuery.getTask(measurement.taskId).missionFlightDate
        }));
      }),
      tap(measurements => {
        this.activitiesStore.upsertActivityMeasurements(measurements);
        if (isCount(activity)) {
          measurements
            .filter(m => m.taskId === this.siteQuery.getActiveTaskId())
            .forEach(m => this.updateAddOrUpdateCountMeasurementsSubject(m));
        }
        if (!isDefined(this.activitiesQuery.getTempPlannedDesignMeasurement())) {
          this.setActivityMeasurementsLoading(false);
        }
      })
    );
  }

  private serverFetchActivityMeasurements(activity: Activity): Observable<GetMeasurementsResponse> {
    const siteId = this.siteQuery.getSiteId();
    this.setActivityMeasurementsLoading();
    return this.http
      .get(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activity.id}/activityMeasurements`, {
        headers: ACTIVITY_MEASUREMENTS_PERMISSION_HEADER.read
      })
      .pipe(
        catchError(error => {
          this.setActivityMeasurementsLoading(false);
          console.error('Error fetching activity measurements', error);
          return of(null);
        })
      );
  }

  serverCreatePlannedMeasurements(activityId: string, measurements: ActivityMeasurement[]): Observable<CreateMeasurementsListResponse> {
    const siteId = this.siteQuery.getSiteId();

    const request: CreatePlannedMeasurementsListRequest = {
      createPlannedMeasurementsRequests: measurements.map(m => ({ ...m, coordinates: m.positions }))
    };

    return this.http.post(
      `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activityId}/activityMeasurements/plannedMeasurements`,
      request,
      { headers: ACTIVITY_MEASUREMENTS_PERMISSION_HEADER.create }
    );
  }

  serverCreateActualMeasurements(activityId: string, measurements: ActivityMeasurement[]): Observable<CreateMeasurementsListResponse> {
    const siteId = this.siteQuery.getSiteId();

    const request: CreateActualMeasurementsListRequest = {
      createActualMeasurementsRequests: measurements.map(m => ({ ...m, coordinates: m.positions }))
    };

    return this.http.post(
      `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activityId}/activityMeasurements/actualMeasurements`,
      request,
      { headers: ACTIVITY_MEASUREMENTS_PERMISSION_HEADER.create }
    );
  }

  serverUpdatePlannedMeasurement(activityId: string, measurements: ActivityMeasurement[]) {
    const siteId = this.siteQuery.getSiteId();

    const request: UpdatePlannedMeasurementsListRequest = {
      updatePlannedMeasurementsRequests: measurements.map(m => ({ ...m, coordinates: m.positions }))
    };

    return this.http.put(
      `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activityId}/activityMeasurements/updatePlannedMeasurements`,
      request,
      { headers: ACTIVITY_MEASUREMENTS_PERMISSION_HEADER.update }
    );
  }

  serverUpdateActualMeasurement(activityId: string, measurements: ActivityMeasurement[]) {
    const siteId = this.siteQuery.getSiteId();

    const request: UpdateActualMeasurementsListRequest = {
      updateActualMeasurementsRequests: measurements.map(m => ({ ...m, coordinates: m.positions }))
    };

    return this.http.put(
      `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activityId}/activityMeasurements/updateActualMeasurements`,
      request,
      { headers: ACTIVITY_MEASUREMENTS_PERMISSION_HEADER.update }
    );
  }

  serverDeleteActivityMeasurement(activityId: string, measurementId: string) {
    const siteId = this.siteQuery.getSiteId();
    return this.http.delete(
      `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activityId}/activityMeasurements/${measurementId}`,
      { headers: ACTIVITY_MEASUREMENTS_PERMISSION_HEADER.delete }
    );
  }

  serverDeleteAllActivityMeasurements(activityId: string) {
    const siteId = this.siteQuery.getSiteId();
    return this.http.delete(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activityId}/activityMeasurements`, {
      headers: ACTIVITY_MEASUREMENTS_PERMISSION_HEADER.delete
    });
  }

  updateActivity(id: string, activity?: Partial<Activity>) {
    this.activitiesStore.activities.update(id, activity);
  }

  addActivityMeasurements(measurements: ActivityMeasurement[]) {
    if (!isDefined(measurements)) {
      return;
    }

    this.activitiesStore.upsertActivityMeasurements(measurements);

    const isCountActivity = isCount(this.activitiesQuery.getActiveActivity());
    if (isCountActivity || !measurements.some(m => m.isTemp)) {
      this.updateActivityDatesAndCompleteness();
    }
    if (isCountActivity) {
      measurements.forEach(m => {
        this.updateAddOrUpdateCountMeasurementsSubject(m);
      });
    }
  }

  setTempPlannedDesignMeasurement(measurement: ActivityMeasurement) {
    this.activitiesStore.setTempPlannedDesignMeasurement(measurement);
  }

  setMeasurementEditorAvailability(measurementId: string, editor$: PolygonPolylineEditorObservable, opts: { disable: boolean }) {
    if (opts.disable) {
      editor$.disable();
    } else {
      editor$.enable();
    }
    this.activitiesStore.activityMeasurements.update(measurementId, { isEnabledEditor: !opts.disable });
  }

  setMeasurementEditorsAvailability(opts: { disable: boolean; measurementIdsToExcept?: string[] }) {
    if (isCount(this.activitiesQuery.getActiveActivity())) {
      let allMeasurementIds = this.activitiesQuery.getActivityMeasurements().map(m => m.id);
      if (isDefined(opts.measurementIdsToExcept)) {
        allMeasurementIds = allMeasurementIds.filter(id => !opts.measurementIdsToExcept.includes(id));
      }
      this.activitiesStore.activityMeasurements.update(allMeasurementIds, { isEnabledEditor: !opts.disable });
    } else {
      let editors = this.activitiesQuery.getAllActivityMeasurementEditors();
      if (isDefined(opts.measurementIdsToExcept)) {
        editors = editors.filter(me => !opts.measurementIdsToExcept.includes(me.measurementId));
      }
      editors.forEach(me => this.setMeasurementEditorAvailability(me.measurementId, me.editor$, { disable: opts.disable }));
    }
  }

  isActivityMeasurementEditorDisabled(editorId: string) {
    const measurement = this.activitiesQuery.getActivityMeasurementByEditorId(editorId);
    return !measurement?.isEnabledEditor;
  }

  addActivityMeasurementEditors(editors: ActivityMeasurementEditor[]) {
    this.activitiesStore.addActivityMeasurementEditors(editors);
  }

  async updateActivityMeasurementPositionsAndValues(measurementId: string, positions: Cartesian3[]) {
    const measurement = this.activitiesQuery.getActivityMeasurementById(measurementId);
    const activityType = this.activitiesQuery.getActiveActivity().activityType;
    const values = await this.calcActivityMeasurementValues({ ...measurement, positions }, activityType);
    const measurementDataForUpdate: Partial<ActivityMeasurement> = { positions, isTemp: false, markedForSave: true, values };

    this.updateActivityMeasurement(measurementId, measurementDataForUpdate);
  }

  async calcActivityMeasurementValues(measurement: ActivityMeasurement, activityType: ActivityType) {
    const calcData = await this.calcService.getCalcValues(activityType, measurement);
    return (calcData.calcResult as ActivityMeasurementValues).values;
  }

  updateActivityMeasurement(id: string, measurementDataForUpdate: Partial<ActivityMeasurement>) {
    this.activitiesStore.activityMeasurements.update(id, measurementDataForUpdate);
    this.updateActivityDatesAndCompleteness();
    if (isCount(this.activitiesQuery.getActiveActivity()) && isDefined(measurementDataForUpdate.positions)) {
      this.updateAddOrUpdateCountMeasurementsSubject(this.activitiesQuery.getActivityMeasurementById(id));
    }
  }

  updateDeletedCountMeasurementIdsSubject(id: string) {
    this.activitiesStore.deletedCountMeasurementIdsSubject$.next(id);
  }

  updateAddOrUpdateCountMeasurementsSubject(measurement: ActivityMeasurement) {
    if (isDefined(measurement) && isActual(measurement)) {
      this.activitiesStore.addOrUpdateCountMeasurementsSubject$.next(measurement);
    }
  }

  removeTempActivityMeasurements() {
    const ids = this.activitiesQuery.getTempActivityMeasurements().map(m => m.id);

    if (isDefined(ids)) {
      this.activitiesStore.activityMeasurements.remove(({ isTemp }) => isTemp);
      if (isCount(this.activitiesQuery.getActiveActivity())) {
        ids.forEach(id => this.updateDeletedCountMeasurementIdsSubject(id));
        this.updateActivityDatesAndCompleteness();
      } else {
        this.removeEditorsByMeasurementIds(ids);
      }
    }
  }

  removeActivityMeasurement(id: string) {
    this.activitiesStore.activityMeasurements.remove(id);
    if (isCount(this.activitiesQuery.getActiveActivity())) {
      this.updateDeletedCountMeasurementIdsSubject(id);
      this.updateActivityDatesAndCompleteness();
    } else {
      this.removeEditorsByMeasurementIds([id]);
    }
  }

  removeEditorsByMeasurementIds(measurementIds: string[]) {
    this.activitiesStore.removeEditorsByMeasurementIds(measurementIds);
  }

  markTempActivityMeasurementsAsIsNotTemp() {
    this.activitiesStore.activityMeasurements.update(({ isTemp }) => isTemp, { isTemp: false });
  }

  markMeasurementsForDelete(ids: string[]) {
    this.activitiesStore.activityMeasurements.update(ids, { markedForDelete: true, markedForSave: false });
    if (isCount(this.activitiesQuery.getActiveActivity())) {
      ids.forEach(id => this.updateDeletedCountMeasurementIdsSubject(id));
    } else {
      this.removeEditorsByMeasurementIds(ids);
    }
    this.updateActivityDatesAndCompleteness();
  }

  updateActivityCompletion(activityId: string, markAsComplete = true) {
    this.setMeasurementEditorsAvailability({ disable: markAsComplete });

    this.activitiesStore.activities.update(activityId, {
      markedAsComplete: markAsComplete,
      userActualEndDate: markAsComplete ? this.calcActivityActualEndDate() : null
    });
  }

  removeStandaloneActivity(activity: StandaloneActivity) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.activities.delete)) {
      return of(null);
    }

    const siteId = this.siteQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/standalone/${activity.id}`, {
        headers: ACTIVITY_PERMISSION_HEADER.delete
      })
      .pipe(tap(() => this.removeLocalStandaloneActivity(activity)));
  }

  removeLocalStandaloneActivity(activity: StandaloneActivity) {
    this.activitiesStore.removeStandaloneActivity(activity);
  }

  serverSaveStandaloneActivity(activity: StandaloneActivity) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.activities.create)) {
      return of(null);
    }

    const siteId = this.siteQuery.getSiteId();
    return this.http
      .post<CreateActivityResponse>(
        `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/standalone`,
        activity as CreateStandaloneActivityRequest,
        { headers: ACTIVITY_PERMISSION_HEADER.create }
      )
      .pipe(
        map(response => ({ ...activity, id: response.id })),
        tap((newActivity: StandaloneActivity) => {
          this.activitiesStore.addStandaloneActivity(newActivity);
          this.analyticsService.addStandaloneActivity(newActivity);
        })
      );
  }

  saveActivity(activity: Activity) {
    const save$ =
      isNewEntity(activity.id) && activity.type === ActivityEntityType.STANDALONE
        ? this.serverSaveStandaloneActivity(activity)
        : this.serverUpdateActivity(activity);

    return save$.pipe(
      catchError(error =>
        this.catchServerSavingError(
          error,
          $localize`:@@detailedSite.activitiesService.errorSavingActivityMessage:Error saving activity`,
          'Error saving activity'
        )
      ),
      switchMap(activity => forkJoin([this.saveActivityMeasurements(activity), this.saveResourceLinks(activity)]).pipe(map(() => activity)))
    );
  }

  associateStandaloneActivityToGanttActivity(standalone: StandaloneActivity, activity: GanttActivity) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.activities.update)) {
      return of(null);
    }

    let saveStandalone$: Observable<StandaloneActivity> = of(standalone);
    if (isNewEntity(standalone.id)) {
      saveStandalone$ = this.saveActivity(standalone);
    }

    const siteId = this.siteQuery.getSiteId();
    return saveStandalone$.pipe(
      switchMap((savedStandalone: StandaloneActivity) => {
        const url = `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activity.id}/standalone/${
          savedStandalone.id
        }/associateStandalone`;
        return this.http
          .put<CreateActivityResponse>(url, null, { headers: ACTIVITY_PERMISSION_HEADER.update })
          .pipe(map(() => savedStandalone));
      }),
      tap((savedStandalone: StandaloneActivity) => {
        this.setActiveActivity(null);
        this.activitiesStore.removeStandaloneActivity(savedStandalone);
        this.setActiveActivity(activity.id);
      })
    );
  }

  private saveActivityMeasurements(activity: Activity) {
    const measurements = this.activitiesQuery.getActivityMeasurementsToSaveOrDelete();
    if (!isDefined(measurements)) {
      return of(null);
    }

    const measurementsToCreate = measurements.filter(m => m.isNew && !m.markedForDelete);
    const create$ = isDefined(measurementsToCreate)
      ? this.serverCreateMeasurements$(activity.id, measurementsToCreate).pipe(
          tap(() => this.analytics.saveNewActivityMeasurements(activity, measurementsToCreate.length))
        )
      : of(null);

    const measurementsToUpdate = measurements.filter(m => !m.isNew && m.markedForSave && !m.markedForDelete);
    const update$ = isDefined(measurementsToUpdate)
      ? this.serverUpdateMeasurements$(activity.id, measurementsToUpdate).pipe(
          tap(() => this.analytics.saveActivityMeasurementsEditing(activity, measurementsToUpdate.length))
        )
      : of(null);

    const measurementsToDelete = measurements.filter(m => !m.isNew && m.markedForDelete);
    const delete$ = isDefined(measurementsToDelete)
      ? this.serverDeleteMeasurements$(activity.id, measurementsToDelete).pipe(
          tap(() => this.analytics.saveActivityMeasurementsDeletion(activity, measurementsToDelete.length))
        )
      : of(null);

    return forkJoin([create$, update$, delete$]).pipe(
      catchError(error =>
        this.catchServerSavingError(
          error,
          $localize`:@@detailedSite.activitiesService.errorSavingMeasurementsMessage:Error saving measurements`,
          'Error saving measurements'
        )
      )
    );
  }

  private serverCreateMeasurements$(activityId: string, measurements: ActivityMeasurement[]) {
    const { plannedMeasurements, actualMeasurements } = groupMeasurementsByType(measurements);
    const createObservables: Observable<unknown>[] = [];

    let plannedMeasurementIdsWithRelatedActuals: string[] = [];
    if (isDefined(actualMeasurements)) {
      const actualMeasurementsForExistingPlanned = actualMeasurements.filter(
        am => !isNewActivityMeasurement(am.plannedActivityMeasurementId)
      );
      if (isDefined(actualMeasurementsForExistingPlanned)) {
        const createActualMeasurements$ = this.serverCreateActualMeasurements(activityId, actualMeasurementsForExistingPlanned).pipe(
          catchError(error =>
            this.catchServerSavingError(
              error,
              $localize`:@@detailedSite.activitiesService.errorCreatingActualMeasurementsMessage:Error creating actual measurements`,
              'Error creating actual measurements'
            )
          )
        );

        createObservables.push(createActualMeasurements$);
      }

      const actualMeasurementsForNewPlanned = actualMeasurements.filter(am => isNewActivityMeasurement(am.plannedActivityMeasurementId));
      if (isDefined(actualMeasurementsForNewPlanned)) {
        const actualMeasurementsByPlannedId = groupBy(actualMeasurements, 'plannedActivityMeasurementId');
        const plannedMeasurementWithRelatedNewActual = plannedMeasurements.filter(pm =>
          Object.keys(actualMeasurementsByPlannedId).includes(pm.id)
        );
        plannedMeasurementIdsWithRelatedActuals = plannedMeasurementWithRelatedNewActual.map(pm => pm.id);
        const createPlannedMeasurementsObervables = plannedMeasurementWithRelatedNewActual.map(pm =>
          this.serverCreatePlannedMeasurements(activityId, [pm]).pipe(
            catchError(error =>
              this.catchServerSavingError(
                error,
                $localize`:@@detailedSite.activitiesService.errorCreatingPlannedMeasurementsMessage:Error creating planned measurements`,
                'Error creating planned measurements'
              )
            ),
            switchMap(resp => {
              const relatedActualMeasurements = actualMeasurementsByPlannedId[pm.id];
              const plannedActivityMeasurementId = resp.createMeasurementsResponseList[0].id;
              return this.serverCreateActualMeasurements(
                activityId,
                relatedActualMeasurements.map(am => ({ ...am, plannedActivityMeasurementId }))
              ).pipe(
                catchError(error =>
                  this.catchServerSavingError(
                    error,
                    $localize`:@@detailedSite.activitiesService.errorCreatingActualMeasurementsMessage:Error creating actual measurements`,
                    'Error creating actual measurements'
                  )
                )
              );
            })
          )
        );
        createObservables.push(...createPlannedMeasurementsObervables);
      }
    }

    if (isDefined(plannedMeasurements)) {
      const plannedMeasurementsWithoutRelatedActuals = isDefined(plannedMeasurementIdsWithRelatedActuals)
        ? plannedMeasurements.filter(pm => !plannedMeasurementIdsWithRelatedActuals.includes(pm.id))
        : plannedMeasurements;
      if (isDefined(plannedMeasurementsWithoutRelatedActuals)) {
        const createPlannedMeasurements$ = this.serverCreatePlannedMeasurements(activityId, plannedMeasurementsWithoutRelatedActuals).pipe(
          catchError(error =>
            this.catchServerSavingError(
              error,
              $localize`:@@detailedSite.activitiesService.errorCreatingPlannedMeasurementsMessage:Error creating planned measurements`,
              'Error creating planned measurements'
            )
          )
        );
        createObservables.push(createPlannedMeasurements$);
      }
    }

    return isDefined(createObservables) ? forkJoin(createObservables) : of(null);
  }

  private serverUpdateMeasurements$(activityId: string, measurements: ActivityMeasurement[]) {
    const { plannedMeasurements, actualMeasurements } = groupMeasurementsByType(measurements);

    const updateObservables: Observable<unknown>[] = [];

    if (isDefined(plannedMeasurements)) {
      const updatePlannedMeasurements$ = this.serverUpdatePlannedMeasurement(activityId, plannedMeasurements).pipe(
        catchError(error =>
          this.catchServerSavingError(
            error,
            $localize`:@@detailedSite.activitiesService.errorUpdatingPlannedMeasurementsMessage:Error updating planned measurements`,
            'Error updating planned measurements'
          )
        )
      );
      updateObservables.push(updatePlannedMeasurements$);
    }

    if (isDefined(actualMeasurements)) {
      const updateActualMeasurements$ = this.serverUpdateActualMeasurement(activityId, actualMeasurements).pipe(
        catchError(error =>
          this.catchServerSavingError(
            error,
            $localize`:@@detailedSite.activitiesService.errorUpdatingActualMeasurementsMessage:Error updating actual measurements`,
            'Error updating actual measurements'
          )
        )
      );

      updateObservables.push(updateActualMeasurements$);
    }

    return isDefined(updateObservables) ? forkJoin(updateObservables) : of(null);
  }

  private serverDeleteMeasurements$(activityId: string, measurements: ActivityMeasurement[]) {
    if (!this.activitiesQuery.hasActivityMeasurements() || this.activitiesQuery.hasOnlyNewActivityMeasurements()) {
      return this.serverDeleteAllActivityMeasurements(activityId).pipe(
        catchError(error =>
          this.catchServerSavingError(
            error,
            $localize`:@@detailedSite.activitiesService.errorDeletingMeasurementsMessage:Error deleting measurements`,
            'Error deleting measurements'
          )
        )
      );
    }

    const deleteObservables = measurements.map(m => this.serverDeleteActivityMeasurement(activityId, m.id));
    return forkJoin(deleteObservables);
  }

  private saveResourceLinks(activity: Activity) {
    if (!this.resourceLinksQuery.isResourceLinksDirty(activity.id, ResourceLinkType.ACTIVITY)) {
      return of(null);
    }

    // Add resources to new activity ID and remove temp activity resource links
    if (isNewEntity(activity.id)) {
      const tempId = activity.id;
      const links = this.resourceLinksQuery.getResourceLinks(tempId, ResourceLinkType.ACTIVITY);
      this.resourceLinksService.insertResourceLinks(activity.id, ResourceLinkType.ACTIVITY, links);
      this.resourceLinksService.removeResource(tempId, ResourceLinkType.ACTIVITY);
    }

    const siteId = this.siteQuery.getSiteId();
    return this.resourceLinksService
      .serverUpdateResourceLinks(siteId, activity.id, ResourceLinkType.ACTIVITY)
      .pipe(
        catchError(error =>
          this.catchServerSavingError(
            error,
            $localize`:@@detailedSite.activitiesService.errorSavingLinksMessage:Error saving links`,
            'Error saving links'
          )
        )
      );
  }

  private catchServerSavingError(error: any, userMessage: string, consoleMessage: string) {
    this.snackbar.openError(userMessage, consoleMessage, error);
    return throwError(() => error);
  }

  serverUpdateActivity(activity: Activity) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.activities.update)) {
      return of(null);
    }

    const siteId = this.siteQuery.getSiteId();
    const version = this.activitiesQuery.getActiveVersion();
    return this.http
      .put(
        `${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activity.id}/versions/${version}`,
        activity as UpdateActivityRequest,
        {
          headers: ACTIVITY_PERMISSION_HEADER.update
        }
      )
      .pipe(
        tap(() => {
          this.activitiesStore.activities.update(activity.id, activity);

          this.analyticsService.editActivity({
            ...activity,
            projectPlanId: this.activitiesQuery.getActiveProjectPlanId(),
            version: this.activitiesQuery.getActiveVersion()
          });
        }),
        map(() => activity)
      );
  }

  unpinAllActivities() {
    this.activitiesStore.activities.update(null, { pinned: false });
  }

  private resetActivityMeasurementsAndEditors() {
    this.activitiesStore.activityMeasurements.reset();
    this.activitiesStore.resetActivityMeasurementEditors();
    this.activitiesStore.resetCountMapBehaviorSubject();
  }

  private flatParentAndChildActivities(activities: GetActivityResponse[]) {
    if (!isDefined(activities)) {
      return [];
    }

    // put all parent and child activities to the flatten array
    const flatActivitiesList: Activity[] = [];

    let rootActivity: Activity;
    const activitiesToCheck: Activity[] = activities.map(activity => ({ ...activity, type: ActivityEntityType.ACTIVITY }));
    let countOfActivitiesForCheck = activitiesToCheck?.length;

    while (countOfActivitiesForCheck !== 0) {
      // always check first element and remove it after that
      const checkedActivity: Activity = { ...activitiesToCheck[0], type: ActivityEntityType.ACTIVITY };
      checkedActivity.hasChildren = isDefined(checkedActivity.childActivities?.activityResponseList);

      let checkingActivityChildren: Activity[] = checkedActivity.childActivities.activityResponseList.map(activity => ({
        ...activity,
        type: ActivityEntityType.ACTIVITY
      }));
      const countOfCheckingActivityChildren = checkingActivityChildren?.length;

      if (countOfCheckingActivityChildren > 0) {
        checkingActivityChildren = checkingActivityChildren.map(activity => ({
          ...activity,
          parentNames: isDefined(checkedActivity?.parentNames)
            ? [...checkedActivity.parentNames, checkedActivity.name]
            : [checkedActivity.name]
        }));
        activitiesToCheck.push(...checkingActivityChildren);
        countOfActivitiesForCheck += countOfCheckingActivityChildren;
      }

      // Don't add root activity to list
      if (!checkedActivity.parentActivityId) {
        rootActivity = checkedActivity;
      } else {
        // Fix parent ID of activities lined to the root
        if (checkedActivity.parentActivityId === rootActivity.id) {
          checkedActivity.parentActivityId = null;
        }

        flatActivitiesList.push(checkedActivity);
      }

      // remove checked first element
      activitiesToCheck.shift();
      countOfActivitiesForCheck -= 1;
    }

    return flatActivitiesList;
  }

  /**
   * Update data depending on measurement action:
   * - ADD measurement: actual start date; forecast end date; completeness
   * - EDIT (update) measurement: forecast end date; completeness
   * - DELETE measurement: actual start date; forecast end date; completeness
   */
  private updateActivityDatesAndCompleteness() {
    const activity = this.activitiesQuery.getActiveActivity();
    const activityDataToUpdate = {
      userActualStartDate: this.calcActivityActualStartDate(),
      forecastEndDate: this.calcForecastEndDate(),
      complete: this.calcActivityCompletenessPercent()
    };

    this.updateActivity(activity.id, activityDataToUpdate);
  }

  private calcActivityCompletenessPercent() {
    const actualMeasurements = this.activitiesQuery.getActualMeasurements();
    const plannedMeasurements = this.activitiesQuery.getPlannedMeasurements();

    if (!isDefined(actualMeasurements) || !isDefined(plannedMeasurements)) {
      return 0;
    }

    const totalPlannedValue = calcMeasurementValuesSum(plannedMeasurements);

    const groupedActualMeasurements = groupBy(actualMeasurements, 'plannedActivityMeasurementId');

    const totalActualValue = Object.values(groupedActualMeasurements)
      .map(actualMeasurementsOfPlanned => {
        if (!isDefined(actualMeasurementsOfPlanned)) {
          return 0;
        }
        const sortedActualMeasurements =
          actualMeasurementsOfPlanned.length === 1 ? actualMeasurementsOfPlanned : actualMeasurementsOfPlanned.sort(sortMeasurementsByDate);

        const latestActualTaskId = sortedActualMeasurements.at(-1).taskId;
        const latestActualMeausurements = sortedActualMeasurements.filter(m => m.taskId === latestActualTaskId);

        return calcMeasurementValuesSum(latestActualMeausurements);
      })
      .reduce((sum, v) => sum + v, 0);

    const status = totalPlannedValue && totalPlannedValue !== 0 && totalActualValue ? (totalActualValue / totalPlannedValue) * 100 : 0;
    return roundTo(status, 0);
  }

  private calcActivityActualStartDate() {
    const activity = this.activitiesQuery.getActiveActivity();
    if (!this.activitiesQuery.hasActualMeasurements()) {
      return activity?.userActualStartDate;
    }

    const allActualDates = this.activitiesQuery.getActualMeasurements().map(am => moment(am.date));
    if (isDefined(activity.userActualStartDate)) {
      allActualDates.push(moment(activity.userActualStartDate));
    }

    const minDate = moment.min(allActualDates);
    return fromMomentToDate(minDate);
  }

  private calcActivityActualEndDate() {
    if (!this.activitiesQuery.hasActualMeasurements()) {
      return;
    }

    const allActualDates = this.activitiesQuery.getActualMeasurements().map(am => moment(am.date));
    const maxDate = moment.max(allActualDates);
    return fromMomentToDate(maxDate);
  }

  private calcForecastEndDate() {
    const plannedMeasurements = this.activitiesQuery.getPlannedMeasurements();
    if (!isDefined(plannedMeasurements)) {
      return;
    }

    const actualMeasurements = this.activitiesQuery.getActualMeasurements();
    if (!isDefined(actualMeasurements)) {
      return;
    }

    const groupedByDateActuals = Object.entries(groupBy(actualMeasurements, 'date'));
    if (groupedByDateActuals.length === 1) {
      return;
    }

    const actualDatesAndValues = groupedByDateActuals
      .map(([date, actualMeasurements]) => {
        return {
          sumValue: calcMeasurementValuesSum(actualMeasurements),
          date
        };
      })
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1));

    const [lastActual, lastButOneActual] = actualDatesAndValues.slice(0, 2);

    const plannedSumValue = calcMeasurementValuesSum(plannedMeasurements);

    if (lastActual.sumValue >= plannedSumValue) {
      return;
    }

    // If last actual value is less than last but one, the extrapolated value will be less than last value
    // => planned value will never be reached => don't extrapolate
    if (lastActual.sumValue <= lastButOneActual.sumValue) {
      return;
    }

    const actualTwoLastDatesDiff = moment(lastActual.date).diff(lastButOneActual.date, 'days');
    const coef = (plannedSumValue - lastActual.sumValue) / (lastActual.sumValue - lastButOneActual.sumValue);
    const forecastEndDate = fromMomentToDate(moment(lastActual.date).add(actualTwoLastDatesDiff * coef, 'days'));

    return forecastEndDate;
  }

  fetchActivityById(activityId: string) {
    const siteId = this.siteQuery.getSiteId();
    return this.http.get(`${getServiceUrl('activity')}/sites/${siteId}/projectPlans/activities/${activityId}`, {
      headers: ACTIVITY_PERMISSION_HEADER.read
    });
  }

  async zoomInto() {
    const plannedMeasurements = this.activitiesQuery.getPlannedMeasurements();
    const positions = plannedMeasurements.map(pm => pm.positions);
    return await this.siteMapService.zoomInto(flatten(positions));
  }
}
