<div class="column full-container">
  <div class="flight-info-title" i18n="@@uploadWizard.flightInfo.stepTitle">Flight Info & Preferences</div>
  <form [formGroup]="flightInfoForm" (ngSubmit)="submit()" class="flight-info-form full-container column">
    <div class="row align-start category">
      <div class="column category-title">
        <div class="row">
          <mat-icon svgIcon="flight-details"></mat-icon>
          <ng-container i18n="@@uploadWizard.flightInfo.subtitleFlightDetails">Flight Details</ng-container>
        </div>
        <span *ngIf="mode === Mode.APPROVE" class="title-note" i18n="@@uploadWizard.flightInfo.approveModeNote">Flight details were entered by the drone operator. Can be modified if necessary.</span>
      </div>

      <div class="column category-fields">
        <div class="row info-field-row">
          <label class="info-field-label"><ng-container i18n="@@uploadWizard.flightInfo.fieldDateLabel">Date</ng-container> *</label>
          <div class="field-date-control">
            <input formControlName="date" [placeholder]="dateFormat" [matDatepicker]="datePicker" [max]="todayDate" />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </div>
        </div>

        <div class="row info-field-row">
          <label for="cameraModelLabel" class="info-field-label" i18n="@@uploadWizard.flightInfo.fieldCameraModelLabel">Camera Model</label>
          <ng-select
            class="custom field-select-control"
            [items]="selectCameraModelList"
            [selectOnTab]="true"
            [clearable]="false"
            bindValue="value"
            labelForId="cameraModelLabel"
            formControlName="cameraModel"
            i18n-placeholder="@@uploadWizard.flightInfo.fieldCameraModelPlaceholder"
            placeholder="Select camera model"
          >
          </ng-select>
        </div>
        <div class="row info-field-row switch-field-row">
          <label class="info-field-label" i18n="@@uploadWizard.flightInfo.fieldRTKLabel">RTK</label>
          <mat-slide-toggle color="primary" formControlName="rtk"></mat-slide-toggle>
          <div class="info-field-label field-hint">
            <label class="info-field-label" i18n="@@uploadWizard.flightInfo.fieldRTKHint">Images with RTK Data</label>
            <hint-tag *ngIf="hasAllValidRTKImages === false" i18n-label="@@uploadWizard.flightInfo.fieldRTKMissingHint" label="Some or all images do not hold valid RTK data" width="270" height="32"></hint-tag>
          </div>
        </div>
        <div class="row info-field-row switch-field-row">
          <label class="info-field-label" i18n="@@uploadWizard.flightInfo.fieldRollingShutterLabel">Rolling Shutter</label>
          <mat-slide-toggle color="primary" formControlName="rollingShutter"></mat-slide-toggle>
          <div class="info-field-label field-hint">
            <label class="info-field-label" i18n="@@uploadWizard.flightInfo.fieldRollingShutterHint">Camera has a rolling shutter and images were taken in motion</label>
            <hint-tag *ngIf="rollingShutterHint" [label]="rollingShutterHint" width="270" height="32"></hint-tag>
          </div>
        </div>
        <div class="row info-field-row">
          <label for="operatorLabel" class="info-field-label"><ng-container i18n="@@uploadWizard.flightInfo.fieldOperatorLabel">Operator</ng-container> *</label>
          <ng-select
            class="custom field-select-control"
            [items]="selectOperatorList"
            [selectOnTab]="true"
            [clearable]="false"
            bindLabel="name"
            bindValue="id"
            labelForId="operatorLabel"
            formControlName="operator"
            i18n-placeholder="@@uploadWizard.flightInfo.fieldOperatorPlaceholder"
            placeholder="Select operator name"
          >
          </ng-select>
        </div>
        <div class="row info-field-row">
          <label class="info-field-label textarea-label" i18n="@@uploadWizard.flightInfo.fieldCommentsLabel">Comments</label>
          <textarea
            formControlName="comments"
            i18n-placeholder="@@uploadWizard.flightInfo.fieldCommentsPlaceholder"
            placeholder="Insert here additional info about the flight such as weather conditions etc."
            class="field-input-control"
            [maxLengthCounter]="512"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="row align-start category">
      <div class="row category-title">
        <mat-icon svgIcon="geo-referencing"></mat-icon>
        <ng-container i18n="@@uploadWizard.flightInfo.subtitleGeoReferencing">Geo Referencing</ng-container>
      </div>

      <div class="column category-fields">
        <mat-radio-group class="column" formControlName="geoReferencing" (change)="georefChanged = true">
          <ng-container *ngFor="let option of sortedGeoRefOptions">
            <mat-radio-button color="primary" [value]="option.value" [class.hidden]="!option.visible" [disabled]="option.disabled">
              {{ formatGeorefMethod(option.value) }}
              <mat-icon class="info-icon" matTooltipClass="tooltip-break-spaces" [matTooltip]="getGeorefMethodHint(option)">
                help
              </mat-icon>
              <hint-tag *ngIf="option.best" label="Best Precision" i18n-label="@@uploadWizard.flightInfo.georefMethodBestPrecisionTag" class="best-precision-tag" width="64" height="28"></hint-tag>
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
    </div>

    <div class="bottom-row">
      <button mat-flat-button type="submit" [disabled]="!flightInfoForm.valid" class="dark-btn continue-btn">
        <ng-container i18n="@@uploadWizard.flightInfo.reprocessModelBtnLabel" *ngIf="mode === Mode.EDIT; else continueBtn">Reprocess model</ng-container>
        <ng-template #continueBtn i18n="@@general.actionButtons.continue">Continue</ng-template>
      </button>
    </div>
  </form>
</div>
