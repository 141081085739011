/**
 * Flow Management Service
 * APIs document (version: 2.8.7)
 *
 * OpenAPI spec version: 2.8.7
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FlightAreaPolygonModel {
  coordinates?: Array<Array<number>>;
  type?: FlightAreaPolygonModel.TypeEnum;
}
export namespace FlightAreaPolygonModel {
  export type TypeEnum = 'UPLOADED' | 'MANUAL';
  export const TypeEnum = {
    UPLOADED: 'UPLOADED' as TypeEnum,
    MANUAL: 'MANUAL' as TypeEnum
  };
}
