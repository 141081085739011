import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Papa, ParseError, ParseResult } from 'ngx-papaparse';
import { lastValueFrom } from 'rxjs';

import { Issue, IssueTypeEnum } from '../../issues-dialog/issues-dialog.component';
import { IssuesDialogService } from '../../issues-dialog/issues-dialog.service';
import { AnalyticsService } from '../../services/analytics.service';
import { SnackBarService } from '../../services/snackbar.service';
import { cleanString, saveCSV } from '../../utils/file-utils';
import { capitalize } from '../../utils/formatting';
import { isDefined } from '../../utils/general';
import { User } from '../state/users-and-teams.model';
import { UsersAndTeamsQuery } from '../state/users-and-teams.query';
import { UsersAndTeamsService } from '../state/users-and-teams.service';

const CSV_FIELDS_MAPPING: Record<string, keyof User> = {
  'first name': 'firstName',
  'last name': 'lastName',
  email: 'email',
  'employee title': 'employeeTitle',
  'employee number': 'employeeNumber'
};

@UntilDestroy()
@Component({
  selector: 'update-users-dialog',
  templateUrl: './update-users-dialog.component.html',
  styleUrls: ['./update-users-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateUsersDialogComponent {
  fileControl = new FormControl<File>(null, Validators.required);

  constructor(
    private dialogRef: MatDialogRef<UpdateUsersDialogComponent>,
    private papa: Papa,
    private service: UsersAndTeamsService,
    private snackbar: SnackBarService,
    private query: UsersAndTeamsQuery,
    private analyticsService: AnalyticsService,
    private designIssuesDialog: IssuesDialogService
  ) {}

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (isDefined(target?.files)) {
      const file = target.files.item(0);
      this.fileControl.patchValue(file);
    }
  }

  downloadTemplate() {
    const header = Object.keys(CSV_FIELDS_MAPPING)
      .map(h => h.split(' ').map(capitalize).join(' '))
      .join(',');
    const exampleLine = ['John', 'Doe Smith', 'john@email.com', 'Project Manager', '123456'].join(',');
    saveCSV(`${header}\n${exampleLine}`, 'update_users_template.csv');
  }

  close() {
    this.dialogRef.close();
  }

  async submit() {
    const file = this.fileControl.value;
    if (!file) {
      return;
    }

    if (!file.type.includes('text')) {
      this.snackbar.open('Invalid file type. Must be CSV file');
      return;
    }

    let result: { data: Partial<User>; errors: string[] }[];
    try {
      result = await this.parseFile(this.fileControl.value);
    } catch (error) {
      const msg = 'Error processing CSV file' + (error.message ? ': ' + error.message : '');
      this.snackbar.openError(msg, error);
      return;
    }

    const requestPromises = result.map(userData => {
      if (isDefined(userData.errors)) {
        return Promise.reject(userData.errors);
      }

      const user = this.query.getUserByEmail(userData.data.email);
      if (!user) {
        return Promise.reject([`User with email ${userData.data.email} does not exist`]);
      }

      return lastValueFrom(
        this.service.updateUser({
          ...user,
          email: userData.data.email,
          firstName: userData.data.firstName,
          lastName: userData.data.lastName,
          employeeTitle: userData.data.employeeTitle,
          employeeNumber: userData.data.employeeNumber
        })
      );
    });

    const responses = await Promise.allSettled(requestPromises);

    let updatedUsersCount = 0;
    const issues: Issue[] = [];
    responses.forEach((response, i) => {
      if (response.status === 'rejected') {
        const errorMessages = isDefined(response.reason) ? response.reason : ['Unknown error'];
        issues.push(
          ...errorMessages.map((error: string) => ({
            type: IssueTypeEnum.ERROR,
            description: `(Line ${i + 1}) ${error}`
          }))
        );
      } else {
        updatedUsersCount++;
      }
    });

    this.analyticsService.updateMultipleUsersFinished(result.length, issues.length);

    if (isDefined(issues)) {
      this.designIssuesDialog.openDialog({
        title: updatedUsersCount === 0 ? 'Processing failed' : 'Processing finished with errors',
        topText:
          updatedUsersCount === 0
            ? 'The process has failed with the following errors:'
            : `The process has updated ${updatedUsersCount} user${updatedUsersCount > 1 ? 's' : ''} and encountered the following errors:`,
        issues
      });
    } else {
      this.snackbar.open(`Updated ${updatedUsersCount} user${updatedUsersCount > 1 ? 's' : ''} successfully`);
      this.close();
    }
  }

  private parseFile(file: File) {
    return new Promise<{ data: Partial<User>; errors: string[] }[]>((resolve, reject) => {
      this.papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header: string) => cleanString(header).toLocaleLowerCase(),
        transform: (value: string) => cleanString(value),
        complete: (result: ParseResult) => {
          // If there's a parse error, show first one
          if (result.errors.length > 0) {
            const firstError: ParseError = result.errors[0];
            const errorMessage = firstError.message;
            const rowNumber = firstError.row;

            reject({
              message: $localize`:@@shared.csvParsing.errorInRow:${errorMessage} in row ${rowNumber}`
            });
            return;
          }

          // If there's no data, means there are no rows
          if (!isDefined(result.data)) {
            reject({ message: $localize`:@@shared.csvParsing.errorMissingFields:Missing fields` });
            return;
          }

          const firstRow = result.data[0];
          if (!Object.keys(CSV_FIELDS_MAPPING).every(field => field in firstRow)) {
            reject({ message: $localize`:@@shared.csvParsing.errorMissingFields:Missing fields` });
            return;
          }

          const linesData = result.data.map((line: any) => {
            const errors = [];
            const data = Object.entries(line).reduce((userData, [field, value]) => {
              if (field in CSV_FIELDS_MAPPING) {
                const fieldName = CSV_FIELDS_MAPPING[field];
                if (['email', 'firstName', 'lastName'].includes(fieldName) && !value) {
                  errors.push('Missing value in field: "' + field + '"');
                }

                userData[fieldName] = value;
              }

              return userData;
            }, {});

            return { data, errors };
          });

          resolve(linesData);
        }
      });
    });
  }

  get fileName() {
    const file: File = this.fileControl.value;
    return file?.name;
  }
}
