import { Injectable, Injector } from '@angular/core';

import { CalcService, PolygonWithSamplesCalcService, PolylineCalcService, PrecalcData } from '../../services/calc-services';
import { CalcModelOption, CalcModelType } from '../../state/detailed-site.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { getLatestDataVersion } from '../../state/detailed-site.utils';
import {
  AnalyticType,
  CalcModelValues,
  MapEntity,
  Measurement,
  MeasurementType,
  ModelEditType,
  PolygonType
} from '../../state/detailed-site-entities/detailed-site-entities.model';
import { AngleCalcService } from './angle-calc.service';
import { AreaCalcService } from './area-calc.service';
import { CrossSectionCalcService } from './cross-section-calc.service';
import { DeltaElevationCalcService } from './delta-elevation-calc.service';
import { DistanceCalcService } from './distance-calc.service';
import { ModelEditCalcService } from './model-edit-calc.service';
import { PointCalcService } from './point-calc.service';
import { VolumeCalcService } from './volume-calc.service';

export const CALC_SERVICE_MAPPING = {
  [MeasurementType.ANGLE]: AngleCalcService,
  [MeasurementType.AREA]: AreaCalcService,
  [MeasurementType.CROSS_SECTION]: CrossSectionCalcService,
  [MeasurementType.DISTANCE]: DistanceCalcService,
  [MeasurementType.POINT]: PointCalcService,
  [MeasurementType.VOLUME]: VolumeCalcService,
  [AnalyticType.CROSS_SECTION_PROGRESS]: CrossSectionCalcService,
  [AnalyticType.POINT_DELTA_ELEVATION]: PointCalcService,
  [AnalyticType.POLYGON_DELTA_ELEVATION]: DeltaElevationCalcService,
  [AnalyticType.POLYGON_DELTA_VOLUME]: VolumeCalcService,
  [ModelEditType.FILTER]: ModelEditCalcService
};

interface CalcResultAndVersion {
  calcResult?: CalcModelValues[];
  dataVersion?: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeneralCalcService {
  constructor(
    private injector: Injector,
    private siteQuery: DetailedSiteQuery
  ) {}

  async makeEntityCalculations(entity: MapEntity): Promise<CalcResultAndVersion> {
    let taskId = this.siteQuery.getActiveTaskId();
    if (entity.type in MeasurementType) {
      taskId = (entity as Measurement).taskId;
    }

    const singleCalcResult = await this.makeSingleCalculation(entity, { id: taskId, type: CalcModelType.TASK });

    return {
      dataVersion: getLatestDataVersion(entity.type),
      calcResult: [singleCalcResult]
    };
  }

  private async makeSingleCalculation(entity: MapEntity, modelOption: CalcModelOption): Promise<CalcModelValues> {
    const entityVertices = entity.positions;
    if (!entityVertices || entityVertices.length === 0) {
      return;
    }

    const siteId = this.siteQuery.getSiteId();
    const calcService = this.injector.get<CalcService>(CALC_SERVICE_MAPPING[entity.type]);

    let precalcData: PrecalcData;
    if (calcService instanceof PolygonWithSamplesCalcService || calcService instanceof PolylineCalcService) {
      precalcData = calcService.precalc(entity.type in PolygonType ? [...entityVertices, entityVertices[0]] : entityVertices);
    }

    const { calcResult } = await calcService.calcResults(entity, siteId, [modelOption], precalcData);

    return calcResult[0];
  }
}
