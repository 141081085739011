import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

import { isDefined } from '../../../shared/utils/general';
import { CalcModelType } from '../../state/detailed-site.model';
import { DetailedSiteQuery, oldestToNewestTasksSorting } from '../../state/detailed-site.query';
import { DesignType } from '../../state/detailed-site-designs/detailed-site-designs.model';
import { AddTaskOrDesignDialogData, ModelOption, ModelOptions } from './add-task-or-design-dialog.service';

function datepickerShortYear() {
  const localDateFormat = moment.localeData().longDateFormat('L');
  const ShortYearFormat = localDateFormat.replace('YYYY', 'YY');

  return {
    parse: {
      dateInput: localDateFormat
    },
    display: {
      dateInput: ShortYearFormat,
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  };
}

const isDateBetween = (date: Date | string, startDate: Date | string, endDate: Date | string) =>
  moment(date).isBetween(startDate, endDate, null, '[]');

interface FilterSetting {
  enabled: boolean;
  startDate?: Date | string;
  endDate?: Date | string;
}

@Component({
  selector: 'add-task-or-design-dialog',
  templateUrl: './add-task-or-design-dialog.component.html',
  styleUrl: './add-task-or-design-dialog.component.scss',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useFactory: () => datepickerShortYear()
    }
  ]
})
export class AddTaskOrDesignDialogComponent implements OnInit {
  protected maxDate: Date;
  protected minDate: Date;
  protected filteredList: ModelOptions;
  protected isFiltersDisabled = false;
  protected isFiltersHidden = false;
  protected selectedItems = new SelectionModel<ModelOption>(true);
  protected allFilteredItemsSelected = false;
  protected filtersSetting: { designs: FilterSetting; flights: FilterSetting } = {
    designs: {
      enabled: false
    },
    flights: {
      enabled: false,
      startDate: null,
      endDate: null
    }
  };

  get dialogHeader() {
    return (
      this.data.dialogHeader ?? $localize`:@@detailedSite.addTaskOrDesignDialog.dialogHeaderAddFlightsAndDesigns:Add Flights & Designs`
    );
  }

  constructor(
    private dialogRef: MatDialogRef<AddTaskOrDesignDialogComponent, ModelOptions>,
    @Inject(MAT_DIALOG_DATA) protected data: AddTaskOrDesignDialogData,
    private siteQuery: DetailedSiteQuery
  ) {}

  ngOnInit() {
    this.updateAllSelectedFlag();
    this.setAvailableFilters();
    this.filteredList = this.data.availableModelOptions;
  }

  protected submitTaskOrDesignSelector() {
    if (this.isSelectionEmpty) {
      return;
    }

    this.close(this.selectedItems.selected as ModelOptions);
    this.selectedItems.clear();
    this.updateAllSelectedFlag();
  }

  protected get isSelectionEmpty() {
    return !isDefined(this.selectedItems.selected);
  }

  protected close(result?: ModelOptions) {
    this.dialogRef.close(result);
  }

  protected filterList() {
    const {
      designs: { enabled: isDesignsEnabled },
      flights: { enabled: isFlightsEnabled, startDate, endDate }
    } = this.filtersSetting;

    this.filteredList = this.data.availableModelOptions.filter((item: ModelOption) => {
      const isTask = item.type === CalcModelType.TASK;
      const isTypeMatched = (isDesignsEnabled && !isTask) || (isFlightsEnabled && isTask);
      const isDateMatched = isTask ? isDateBetween(this.siteQuery.getTask(item.id)?.missionFlightDate, startDate, endDate) : true;

      return isTypeMatched && isDateMatched;
    }) as ModelOptions;

    this.updateAllSelectedFlag();
  }

  private setAvailableFilters() {
    const hasDesigns = this.data.availableModelOptions.some((model: ModelOption) => model.type in DesignType);
    const hasTasks = this.data.availableModelOptions.some((model: ModelOption) => model.type === CalcModelType.TASK);

    if (this.data.showOnlyFlights) {
      this.isFiltersHidden = true;
      this.filtersSetting.flights.enabled = true;
      this.filtersSetting.designs.enabled = false;
    } else {
      this.isFiltersDisabled = !hasDesigns || !hasTasks;
      this.filtersSetting.flights.enabled = hasTasks;
      this.filtersSetting.designs.enabled = hasDesigns;
    }

    if (hasTasks) {
      this.setDefaultFlightsFilterDates();
    }
  }

  private setDefaultFlightsFilterDates() {
    const sortedByDatesTasks = this.data.availableModelOptions
      .filter(model => model.type === CalcModelType.TASK)
      .map(model => this.siteQuery.getTask(model.id))
      .sort(oldestToNewestTasksSorting);

    this.filtersSetting.flights.startDate = this.minDate = sortedByDatesTasks.at(0)?.missionFlightDate;
    this.filtersSetting.flights.endDate = this.maxDate = sortedByDatesTasks.at(-1)?.missionFlightDate;
  }

  protected updateSelectedItems(item: ModelOption) {
    if (!this.selectedItems.isSelected(item)) {
      this.selectedItems.select(item);
    } else {
      this.selectedItems.deselect(item);
    }

    this.updateAllSelectedFlag();
  }

  private updateAllSelectedFlag() {
    this.allFilteredItemsSelected = isDefined(this.filteredList) && this.filteredList.every(item => this.selectedItems.isSelected(item));
  }

  protected toggleAllFilteredItemSelection(e: MatCheckboxChange) {
    if (e.checked) {
      this.selectedItems.select(...this.filteredList);
    } else {
      this.selectedItems.deselect(...this.filteredList);
    }
    this.updateAllSelectedFlag();
  }
}
