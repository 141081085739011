import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';

import { Position2D } from '../utils/geo';

type Position = { x: number; y: number };
type Size = { width: number; height: number };
type GrabbingPosition = Position | null;

@Component({
  selector: 'zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.scss']
})
export class ZoomImageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('resizeableContainerRef') resizeableContainerRef: ElementRef<HTMLDivElement>;
  @ViewChild('imageRef') imageRef: ElementRef<HTMLImageElement>;
  @ViewChild('imageContainerRef') imageContainerRef: ElementRef<HTMLDivElement>;

  @Input() src: string;
  @Input() alt: string;
  @Input() maxZoom = 25;
  @Input() zoomStep = 0.2;
  @Input() hideHint = false;
  @Input() grabOnCtrl = false;

  @Output() imageContainerContextMenu: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() imageContainerMouseDown: EventEmitter<MouseEvent> = new EventEmitter();

  private minZoom = 1;
  private grabbing: GrabbingPosition = null;
  private originalZoom = 1;
  private imagePosition: Position = { x: 0, y: 0 };
  private resizeableContainerResizeObserver: ResizeObserver;
  protected scrolled = false;
  protected loaded = false;

  private get limitedZoom() {
    return this.limitZoom(this.originalZoom);
  }

  ngAfterViewInit() {
    this.subscribeOnResizeContainer();
    this.subscribeImageLoad();
  }

  ngOnDestroy() {
    this.unsubscribeOnResizeContainer();
    this.unsubscribeImageLoad();
  }

  public reset() {
    this.originalZoom = 1;
    this.imagePosition = { x: 0, y: 0 };
    this.imageContainerRef.nativeElement.style.width = '';
    this.imageContainerRef.nativeElement.style.height = '';
    this.imageContainerRef.nativeElement.style.transform = '';
  }

  private onLoadStart = () => {
    this.loaded = false;
  };

  private onLoad = () => {
    this.loaded = true;
  };

  private subscribeImageLoad() {
    this.imageRef.nativeElement.addEventListener('loadstart', this.onLoadStart);
    this.imageRef.nativeElement.addEventListener('load', this.onLoad);
  }

  private unsubscribeImageLoad() {
    this.imageRef.nativeElement.removeEventListener('loadstart', this.onLoadStart);
    this.imageRef.nativeElement.removeEventListener('load', this.onLoad);
  }

  private subscribeOnResizeContainer() {
    this.resizeableContainerResizeObserver = new ResizeObserver(() => {
      this.onContainerResize();
    });

    this.resizeableContainerResizeObserver.observe(this.resizeableContainerRef.nativeElement);
  }

  private unsubscribeOnResizeContainer() {
    this.resizeableContainerResizeObserver.disconnect();
  }

  private onContainerResize() {
    this.reset();
  }

  private isScrollUp(event: WheelEvent): boolean {
    return event.deltaY < 0;
  }

  private isScrollDown(event: WheelEvent): boolean {
    return event.deltaY > 0;
  }

  private adjustZoom(event: WheelEvent): number {
    const zoomFactor = 1 + this.zoomStep;

    if (this.isScrollUp(event) && this.originalZoom < this.maxZoom) {
      return this.originalZoom * zoomFactor;
    } else if (this.isScrollDown(event) && this.originalZoom > this.minZoom) {
      return this.originalZoom / zoomFactor;
    }

    return this.originalZoom;
  }

  private calcZoomByWheelEvent(event: WheelEvent) {
    const defaultResult = {
      originalZoom: this.originalZoom,
      limitedZoom: this.limitedZoom
    };

    if (!this.isScrollUp(event) && !this.isScrollDown(event)) {
      return defaultResult;
    }

    const adjustedZoom = this.adjustZoom(event);

    if (adjustedZoom === this.originalZoom) {
      return defaultResult;
    }

    return {
      originalZoom: adjustedZoom,
      limitedZoom: this.limitZoom(adjustedZoom)
    };
  }

  private calcImageShift({ mouse, prevZoom, newZoom }: { mouse: Position; prevZoom: number; newZoom: number }): Position {
    // We calculate how many pixels the image position shifts after you change zoom with zoom step
    const zoomDelta = Math.abs(newZoom - prevZoom);

    const x = (mouse.x * zoomDelta) / prevZoom;
    const y = (mouse.y * zoomDelta) / prevZoom;

    return { x, y };
  }

  // Set zoomed image position
  private setImagePosition({ x, y }: Position) {
    const { offsetWidth, offsetHeight } = this.resizeableContainerRef.nativeElement;

    this.imagePosition.x = x > 0 ? Math.min(0, x) : Math.max(-(offsetWidth * (this.limitedZoom - 1)), x);
    this.imagePosition.y = y > 0 ? Math.min(0, y) : Math.max(-(offsetHeight * (this.limitedZoom - 1)), y);
    this.imageContainerRef.nativeElement.style.transform = `translate(${this.imagePosition.x}px, ${this.imagePosition.y}px)`;
  }

  // Set zoomed image size
  private setImageSize({ width, height }: Size) {
    this.imageContainerRef.nativeElement.style.width = `${width}px`;
    this.imageContainerRef.nativeElement.style.height = `${height}px`;
  }

  private setGrabbing(newGrabbing: GrabbingPosition) {
    if (newGrabbing) {
      this.imageContainerRef.nativeElement.style.cursor = 'grab';
      this.grabbing = newGrabbing;
    } else {
      this.imageContainerRef.nativeElement.style.cursor = '';
      this.grabbing = null;
    }
  }

  private limitZoom(zoom: number) {
    return Math.max(this.minZoom, Math.min(this.maxZoom, zoom));
  }

  public zoomPoint(point: Position2D): Position2D {
    const zoom = this.limitZoom(this.originalZoom);

    return {
      x: zoom * point.x,
      y: zoom * point.y
    };
  }

  public unzoomPoint(point: Position2D): Position2D {
    const zoom = this.limitZoom(this.originalZoom);

    return {
      x: point.x / zoom,
      y: point.y / zoom
    };
  }

  protected onImageWheel(event: WheelEvent) {
    if ((event.ctrlKey || event.metaKey) && this.loaded) {
      event.preventDefault();
      this.scrolled = true;

      const { originalZoom, limitedZoom: newLimitedZoom } = this.calcZoomByWheelEvent(event);
      const prevLimitedZoom = this.limitedZoom;
      const zoomDelta = newLimitedZoom - prevLimitedZoom;

      if (zoomDelta === 0) {
        return;
      }

      this.originalZoom = originalZoom;

      const { offsetWidth, offsetHeight } = this.resizeableContainerRef.nativeElement;

      const newImageWidth = offsetWidth * newLimitedZoom;
      const newImageHeight = offsetHeight * newLimitedZoom;
      const mouse = { x: event.offsetX, y: event.offsetY };
      const shift = this.calcImageShift({ mouse, prevZoom: prevLimitedZoom, newZoom: newLimitedZoom });

      if (newLimitedZoom <= 1) {
        this.setImagePosition({ x: 0, y: 0 });
      } else if (zoomDelta > 0) {
        this.setImagePosition({ x: this.imagePosition.x - shift.x, y: this.imagePosition.y - shift.y });
      } else {
        this.setImagePosition({ x: this.imagePosition.x + shift.x, y: this.imagePosition.y + shift.y });
      }

      this.setImageSize({ width: newImageWidth, height: newImageHeight });
    }
  }

  protected onImageMouseDown(event: MouseEvent) {
    if (this.loaded) {
      event.preventDefault();

      if ((this.grabOnCtrl && (event.ctrlKey || event.metaKey)) || !this.grabOnCtrl) {
        this.setGrabbing({ x: event.offsetX, y: event.offsetY });
      }
    }

    this.imageContainerMouseDown.emit(event);
  }

  protected onImageMouseUp(event: MouseEvent) {
    if (this.loaded) {
      event.preventDefault();
      this.setGrabbing(null);
    }
  }

  protected onImageMouseLeave(event: MouseEvent) {
    if (this.loaded) {
      event.preventDefault();
      this.setGrabbing(null);
    }
  }

  protected onImageMouseMove(event: MouseEvent) {
    if (this.loaded) {
      event.preventDefault();
      if (this.grabbing) {
        const mouse: Position = { x: event.offsetX, y: event.offsetY };
        const shift: Position = { x: mouse.x - this.grabbing.x, y: mouse.y - this.grabbing.y };

        this.setImagePosition({
          x: this.imagePosition.x + shift.x,
          y: this.imagePosition.y + shift.y
        });
      }
    }
  }
}
