import { ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

import { GetNFIHintResponse } from '../../../generated/nms/model/getNFIHintResponse';
import { isDefined } from '../utils/general';
import { FeatureHintsQuery } from './state/feature-hints.query';
import { FeatureHintsService } from './state/feature-hints.service';
import { FeatureHintData, FeatureHintPlace, featureHintsContents } from './state/feature-hints.store';

type OverlayPosition =
  | 'top-center'
  | 'top-left'
  | 'top-right'
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'center-right'
  | 'center-left';

type DotPosition =
  | 'top-center'
  | 'top-left'
  | 'top-right'
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'center-right'
  | 'center-left';

const OVERLAY_Y_OFFSET = 6;
const OVERLAY_X_OFFSET = 0;

const OVERLAY_POSITIONS: Record<OverlayPosition, ConnectedPosition> = {
  'top-center': {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -OVERLAY_Y_OFFSET
  },
  'top-left': {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetY: -OVERLAY_Y_OFFSET,
    offsetX: OVERLAY_X_OFFSET
  },
  'top-right': {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -OVERLAY_Y_OFFSET,
    offsetX: -OVERLAY_X_OFFSET
  },
  'bottom-center': {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: OVERLAY_Y_OFFSET
  },
  'bottom-left': {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: OVERLAY_Y_OFFSET,
    offsetX: OVERLAY_X_OFFSET
  },
  'bottom-right': {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: OVERLAY_Y_OFFSET,
    offsetX: -OVERLAY_X_OFFSET
  },
  'center-right': {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: OVERLAY_X_OFFSET
  },
  'center-left': {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -OVERLAY_X_OFFSET
  }
};

@UntilDestroy()
@Component({
  selector: 'feature-hint',
  templateUrl: './feature-hint.component.html',
  styleUrls: ['./feature-hint.component.scss']
})
export class FeatureHintComponent implements OnInit, AfterViewInit {
  @ViewChild('dot') dot: ElementRef;

  /**
   * The position of the overlay.
   * Default is 'top-right'.
   * Available positions:
   * - 'top-center'
   * - 'top-left'
   * - 'top-right'
   * - 'bottom-center'
   * - 'bottom-left'
   * - 'bottom-right'
   * - 'center-right'
   * - 'center-left'
   */
  @Input()
  overlayPosition: OverlayPosition = 'top-right';

  /**
   * The position of the dot.
   * Default is 'top-right'.
   * Available positions:
   * - 'top-center'
   * - 'top-left'
   * - 'top-right'
   * - 'bottom-center'
   * - 'bottom-left'
   * - 'bottom-right'
   * - 'center-right'
   * - 'center-left'
   */
  @Input() dotPosition: DotPosition = 'top-right';

  // If passed, the hint will use it as a trigger element. If not passed, the hint will use the wrapper element as trigger.
  @Input() hintParentElement: HTMLElement;

  // Class for wrapper element
  @Input() class: string;
  @HostBinding('class')
  get classBinding(): string {
    const classes = [];

    if (this.class) {
      classes.push(this.class);
    }

    if (this.inline) {
      classes.push('inline');
    }

    return classes.join(' ');
  }

  @Input() place: FeatureHintPlace;
  @Input() dotOffsetX = 0;
  @Input() dotOffsetY = 0;
  @Input() disabled = false;
  @Input() stopPropagation = false;
  @Input() inline = false;

  title?: string;
  description?: string;
  moreInfoLink?: string;
  hints: FeatureHintData[];
  showOnHover: boolean;
  kept: boolean;
  isFeatureHint: boolean;
  isOverlayOpen = false;
  isDismissed = true;
  currentOverlayPosition: OverlayPosition = this.overlayPosition;

  triggerElement: HTMLElement;

  get hasPlace(): boolean {
    return !!this.place;
  }

  constructor(
    private readonly featureHintService: FeatureHintsService,
    private readonly featureHintsQuery: FeatureHintsQuery,
    private readonly cd: ChangeDetectorRef,
    private readonly host: ElementRef
  ) {}

  ngOnInit() {
    this.featureHintsQuery
      .selectHintsByPlace(this.place)
      .pipe(untilDestroyed(this))
      .subscribe(hints => {
        this.isDismissed = !isDefined(hints);

        if (!this.isDismissed) {
          this.hints = hints;
          this.showOnHover = hints.some(h => h.showOnHover);
          this.kept = hints.some(h => h.kept);
          this.isFeatureHint = hints.length === 1 && this.hints[0].type === GetNFIHintResponse.TypeEnum.FEATURE;
          this.title = this.isFeatureHint ? featureHintsContents[this.place].title : undefined;
          this.description = this.isFeatureHint ? featureHintsContents[this.place].description : undefined;
          this.moreInfoLink = this.isFeatureHint ? featureHintsContents[this.place].moreInfoLink : undefined;
        }
      });
  }

  ngAfterViewInit(): void {
    this.triggerElement = this.hintParentElement || this.host?.nativeElement;

    if (this.triggerElement) {
      fromEvent(this.triggerElement, 'click')
        .pipe(untilDestroyed(this))
        .subscribe(() => this.onTriggerClick());

      fromEvent(this.triggerElement, 'mouseenter')
        .pipe(untilDestroyed(this))
        .subscribe(() => this.onTriggerHover());
    }

    this.cd.detectChanges();
  }

  get overlayPositionConfig(): ConnectedPosition[] {
    const positions: ConnectedPosition[] = [OVERLAY_POSITIONS[this.overlayPosition]];

    if (this.overlayPosition.startsWith('top')) {
      positions.push(OVERLAY_POSITIONS[this.overlayPosition.replace('top', 'bottom')]);
    }

    if (this.overlayPosition.startsWith('bottom')) {
      positions.push(OVERLAY_POSITIONS[this.overlayPosition.replace('bottom', 'top')]);
    }

    if (this.overlayPosition.endsWith('right')) {
      positions.push(OVERLAY_POSITIONS[this.overlayPosition.replace('right', 'left')]);
    }

    if (this.overlayPosition.endsWith('left')) {
      positions.push(OVERLAY_POSITIONS[this.overlayPosition.replace('left', 'right')]);
    }

    return positions;
  }

  get currentOverlayPositionConfig(): ConnectedPosition {
    return OVERLAY_POSITIONS[this.currentOverlayPosition];
  }

  onDotHover() {
    if (!this.disabled) {
      this.openOverlay();
    }
  }

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
  }

  onTriggerClick() {
    if (!this.disabled && !this.isDismissed) {
      this.featureHintService.passHints(this.place);
    }
  }

  onTriggerHover() {
    if (!this.disabled && !this.isDismissed && this.isFeatureHint && this.showOnHover && !this.kept) {
      this.openOverlay();
      this.featureHintService.disableHover(this.place);
    }

    this.cd.detectChanges();
  }

  onRemindLater() {
    this.featureHintService.keepHint(this.place);
    this.closeOverlay();
  }

  closeOverlay(event?: MouseEvent) {
    this.isOverlayOpen = false;
    event?.stopPropagation();
  }

  openOverlay() {
    this.isOverlayOpen = true;
  }

  findPosition(position: ConnectedPosition): OverlayPosition {
    const positionKey = Object.keys(OVERLAY_POSITIONS).find(key => {
      const pos = OVERLAY_POSITIONS[key];
      return (
        pos.originX === position.originX &&
        pos.originY === position.originY &&
        pos.overlayX === position.overlayX &&
        pos.overlayY === position.overlayY
      );
    });

    return positionKey as OverlayPosition;
  }

  onPositionChange(event: ConnectedOverlayPositionChange): void {
    const position = this.findPosition(event.connectionPair);
    this.currentOverlayPosition = position;
  }

  onDismiss() {
    this.featureHintService.dismissHint(this.place);
    this.closeOverlay();
  }
}
