<div class="row align-center left-title-part">
  <div class="column align-start row-title">
    <input
      class="name-input"
      [disableByAccess]="editAccessLevel"
      [disableByAccessExtraConditions]="entity.type === 'ANNOTATION' && !entity.isEditable"
      [value]="title"
      [title]="title + (entity.type === 'ANNOTATION' && !entity.isEditable ? '\n\n' + userIsNotCreatorToEditNameMessage : '')"
      (input)="title = $event.target.value"
    />
    <mat-icon class="edit-icon">edit</mat-icon>
    <label class="row-sub-title">
      {{ subtitle }} |
      <underline-tooltip [text]="sourceModelHintText" *ngIf="sourceModelHintText">{{ sourceModelName }}</underline-tooltip>
    </label>
  </div>

  <div *ngIf="shownFeatures?.layers" class="subcontainer">
    <div class="layer-circle" [style.backgroundColor]="layerColor"></div>
    <mat-select
      class="row-select-input"
      panelClass="select-options-panel"
      #layersSelect
      hideSingleSelectionIndicator
      [disableByAccess]="editAccessLevel"
      [disableByAccessExtraConditions]="entity.type === 'ANNOTATION' && !entity.isEditable"
      [title]="layersTooltip + (entity.type === 'ANNOTATION' && !entity.isEditable ? '\n\n' + userIsNotCreatorToEditLayerMessage : '')"
      [(value)]="layerId"
      (selectionChange)="layerId = $event.value"
      (openedChange)="$event && layersSelectOpen()"
    >
      <div class="select-options-container">
        <mat-option *ngFor="let layer of layers$ | async" [value]="layer.id" [title]="layer.name">
          <div class="row">
            <div class="layer-circle" [style.backgroundColor]="layer.color"></div>
            <span class="layer-text">{{ layer.name }}</span>
          </div>
        </mat-option>
      </div>
      <mat-divider></mat-divider>
      <div class="select-actions column">
        <a (click)="createNew('layer')" matRipple> Create new layer </a>
        <a (click)="manage('layer')" matRipple> Manage layers </a>
      </div>
    </mat-select>
  </div>

  <div *ngIf="shownFeatures?.groups" class="subcontainer">
    <label class="select-label">Group</label>
    <mat-select
      class="row-select-input"
      panelClass="select-options-panel"
      #groupsSelect
      hideSingleSelectionIndicator
      [disableByAccess]="editAccessLevel"
      [disableByAccessExtraConditions]="entity.type === 'ANNOTATION' && !entity.isEditable"
      [title]="groupsTooltip + (entity.type === 'ANNOTATION' && !entity.isEditable ? '\n\n' + userIsNotCreatorToEditGroupMessage : '')"
      [(value)]="groupId"
      (selectionChange)="groupId = $event.value"
      (openedChange)="$event && groupsSelectOpen()"
    >
      <div class="select-options-container">
        <mat-option *ngFor="let group of groups$ | async" [value]="group.id" [title]="group.name">
          {{ group.name }}
        </mat-option>
      </div>
      <mat-divider></mat-divider>
      <div class="select-actions column">
        <a (click)="createNew('group')" matRipple> Create new group </a>
        <a (click)="manage('group')" matRipple> Manage groups </a>
      </div>
    </mat-select>
  </div>

  <button
    *ngIf="shownFeatures?.invalidateEntityReasons | isDefined"
    mat-icon-button
    class="model-edit-warning"
    [matTooltip]="invalidateTooltip"
    (click)="invalidateEntity.emit()"
    [disabled]="invalidateEntityDisabled"
  >
    <mat-icon class="recalc" svgIcon="recalc"></mat-icon>
    <mat-icon class="warning" svgIcon="warning"></mat-icon>
  </button>
</div>

<div class="right-title-part subcontainer">
  <button
    *ngIf="shownFeatures?.preview"
    mat-button
    class="light-btn modal-title-btn"
    [class.reduced-width]="isReducedWidthMode"
    (click)="previewModelEdit()"
    title="Preview"
    i18n-title="@@detailedSite.actionButtons.preview"
    [disabled]="previewDisabled || isResultsLoading"
  >
    <mat-icon svgIcon="model-edits-tab"></mat-icon>
    <ng-container *ngIf="!isReducedWidthMode" i18n="@@detailedSite.actionButtons.preview">Preview</ng-container>
  </button>

  <button
    *ngIf="shownFeatures?.syncSnapshot"
    mat-button
    class="light-btn modal-title-btn"
    [class.reduced-width]="isReducedWidthMode"
    (click)="snapshotClick.emit()"
    [title]="syncSnapshotTitleText"
    [disableByAccess]="PERMISSIONS.annotations.update"
    [disableByAccessExtraConditions]="snapshotDisabled || isResultsLoading"
  >
    <mat-icon svgIcon="sync-camera"></mat-icon>
    <ng-container *ngIf="!isReducedWidthMode" i18n="@@detailedSite.actionButtons.syncSnapshot">Sync Snapshot</ng-container>
  </button>

  <button
    *ngIf="shownFeatures?.exportReport"
    mat-button
    class="light-btn modal-title-btn"
    [class.reduced-width]="isReducedWidthMode"
    (click)="reportClick.emit()"
    title="Report"
    i18n-title="@@detailedSite.actionButtons.report"
    [disableByAccess]="PERMISSIONS.downloads"
    [disableByAccessExtraConditions]="exportDisabled || isResultsLoading"
  >
    <mat-icon svgIcon="report"></mat-icon>
    <ng-container *ngIf="!isReducedWidthMode" i18n="@@detailedSite.actionButtons.report">Report</ng-container>
  </button>

  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="(entity.positions | isDefined) || (entity.type === 'ANNOTATION' && entity.drawings | isDefined)"
      (click)="zoomIntoClick.emit()"
      title="Zoom into entity on map"
      i18n-title="@@detailedSite.actionButtons.zoomIntoBtnTitle"
    >
      <mat-icon svgIcon="my-location"></mat-icon>
      <span i18n="@@detailedSite.actionButtons.zoomIntoBtnName">Zoom into</span>
    </button>

    @if(isMeasurement) {
    <button mat-menu-item title="Clone" i18n-title="@@general.actionButtons.clone" (click)="onCloneMeasurement()">
      <mat-icon svgIcon="create-clone"></mat-icon>
      <span i18n="@@general.actionButtons.clone">Clone</span>
    </button>
    }

    <ng-container *ngIf="entity.type === 'ANNOTATION' && (entity.positions | isDefined) && (entity.drawings | isDefined)">
      <button
        mat-menu-item
        (click)="toggleGeoPositionVizClick.emit()"
        [title]="
          getToggleGeoPositionVizText(entity.hiddenPosition) +
          (entity.type === 'ANNOTATION' && !entity.isEditable ? '\n\n' + userIsNotCreatorToTogglePositionVizMessage : '')
        "
        [disableByAccess]="PERMISSIONS.annotations.update"
        [disableByAccessExtraConditions]="entity.type === 'ANNOTATION' && !entity.isEditable"
      >
        <mat-icon>{{ entity.hiddenPosition ? 'location_on' : 'location_off' }}</mat-icon>
        <span>{{ getToggleGeoPositionVizText(entity.hiddenPosition) }}</span>
      </button>
    </ng-container>
    <button
      mat-menu-item
      *ngIf="!(entity.positions | isDefined)"
      (click)="addPositionClick.emit()"
      [disableByAccess]="PERMISSIONS.annotations.update"
      [disableByAccessExtraConditions]="entity.type === 'ANNOTATION' && !entity.isEditable"
      [title]="addPositionTitle + (entity.type === 'ANNOTATION' && !entity.isEditable ? '\n\n' + userIsNotCreatorToAddPositionMessage : '')"
    >
      <mat-icon>add_location</mat-icon>
      <span i18n="@@detailedSite.actionButtons.addPositionBtnName">Add geo-position</span>
    </button>
    <button
      mat-menu-item
      *ngIf="shownFeatures?.exportDXF"
      (click)="exportDXFClick.emit()"
      [disableByAccess]="PERMISSIONS.downloads"
      [disableByAccessExtraConditions]="exportDisabled || isResultsLoading || !(entity.positions | isDefined)"
      [title]="(entity.positions | isDefined) ? downloadDxfActionBtnTitle : disabledDownloadDxfActionBtnTitle"
    >
      <mat-icon svgIcon="export-dxf"></mat-icon>
      <span i18n="@@detailedSite.actionButtons.downloadDxfBtnName">Download DXF</span>
    </button>
    <button
      mat-menu-item
      *ngIf="shownFeatures?.exportCSV"
      (click)="exportCSVClick.emit()"
      [disableByAccess]="PERMISSIONS.downloads"
      [disableByAccessExtraConditions]="exportDisabled || isResultsLoading"
      title="Download as CSV file"
      i18n-title="@@detailedSite.actionButtons.downloadCsvBtnTitle"
    >
      <mat-icon svgIcon="export-csv"></mat-icon>
      <span i18n="@@detailedSite.actionButtons.downloadCsvBtnName">Download CSV</span>
    </button>
    <ng-container *ngIf="!isNewEntity">
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="shareResource.emit()">
        <mat-icon>share</mat-icon>
        <span i18n="@@general.actionButtons.share">Share</span>
      </button>
    </ng-container>
    <mat-divider></mat-divider>
    <button
      mat-menu-item
      (click)="deleteClick.emit()"
      [title]="entity.type === 'ANNOTATION' && !entity.isEditable ? userIsNotCreatorToDeleteAnnotationMessage : ''"
      [disableByAccess]="deleteAccessLevel"
      [disableByAccessExtraConditions]="isResultsLoading || (entity.type === 'ANNOTATION' && !entity.isEditable)"
    >
      <mat-icon>delete</mat-icon>
      <span i18n="@@general.actionButtons.delete">Delete</span>
    </button>
  </mat-menu>
</div>
