import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityDirtyCheckPlugin, Query, QueryEntity } from '@datorama/akita';
import moment from 'moment';
import { map, Observable, of } from 'rxjs';

import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { isDefined } from '../../../shared/utils/general';
import { generateViewerCredentialsQueryParams } from '../detailed-site.utils';
import {
  Design,
  DesignChatSessionsHistory,
  DesignState,
  DesignType,
  DesignVersion,
  IntegrationDesignNode,
  RoadDesignType
} from './detailed-site-designs.model';
import { DetailedSiteDesignsState, DetailedSiteDesignsStore } from './detailed-site-designs.store';

const integrationDesignNodeComparator = (head: IntegrationDesignNode, current: IntegrationDesignNode) => {
  return current.sync !== head.originalSync || current.designType !== head.originalDesignType;
};

const sortChatSessionsByLastModified = (t1: DesignChatSessionsHistory, t2: DesignChatSessionsHistory) => {
  return moment(t1.lastModifiedTime).isAfter(t2.lastModifiedTime) ? -1 : 1;
};

@Injectable({ providedIn: 'root' })
export class DetailedSiteDesignsQuery extends Query<DetailedSiteDesignsState> {
  loading$ = this.selectLoading();

  designsUploadingState$ = this.select(state => state.designsUploading);
  designsQuery = new QueryEntity(this.store.designs);
  designs$ = this.designsQuery.selectAll();
  readyDesigns$ = this.designsQuery.selectAll({ filterBy: design => design.state === DesignState.READY });

  roadDesignsQuery = new QueryEntity(this.store.roadDesigns);
  roadDesigns$ = this.roadDesignsQuery.selectAll();
  readyRoadDesigns$ = this.roadDesignsQuery.selectAll({ filterBy: roadDesign => roadDesign.state === DesignState.READY });

  designCategoriesQuery = new QueryEntity(this.store.designCategories, { sortBy: 'name' });
  designCategories$ = this.designCategoriesQuery.selectAll();

  expandedDesignIDs$ = this.select(state => state.expandedDesignIDs);

  activeDesignLayer$ = this.select(state => state.activeDesignLayer);

  integrationDesignQuery = new QueryEntity(this.store.integrationDesignNodes);
  activeIntegrationDesignNodes$ = this.integrationDesignQuery.selectActive();

  activeDesignChat$ = this.select(state => state.activeDesignChatId);
  designsChatHistoryQuery = new QueryEntity(this.store.designChatSessionsHistory);
  designsChatFavoritesQuery = new QueryEntity(this.store.designChatSessionsFavorites);
  designsChatHistory$ = this.designsChatHistoryQuery.selectAll({
    sortBy: sortChatSessionsByLastModified
  });
  designsChatFavorites$ = this.designsChatFavoritesQuery.selectAll({
    sortBy: sortChatSessionsByLastModified
  });

  constructor(
    protected store: DetailedSiteDesignsStore,
    private http: HttpClient
  ) {
    super(store);
  }

  getSiteId() {
    return this.getValue().siteId;
  }

  getViewerCredentials() {
    return this.getValue().viewerUrlsCredentials;
  }

  getAllDesigns() {
    return this.designsQuery.getAll();
  }

  getAllRoadDesigns() {
    return this.roadDesignsQuery.getAll();
  }

  getReadyRoadDesigns() {
    return this.roadDesignsQuery.getAll({ filterBy: roadDesign => roadDesign.state === DesignState.READY });
  }

  getAllDesignTypesCount() {
    return this.designsQuery.getCount() + this.roadDesignsQuery.getCount();
  }

  getDesignByType(designType: DesignType, designId: string) {
    return this.selectQueryByDesignType(designType).getEntity(designId);
  }

  getDesign(designId: string) {
    return this.designsQuery.getEntity(designId);
  }

  getDesignBySharedVersionId(sharedVersionId: string) {
    return this.getAllDesigns().find(design => design.sharedVersionId === sharedVersionId);
  }

  /**
   * Given a design ID returns the design ID of the active version
   */
  getActiveDesignByVersionId(versionId: string) {
    const design = this.getDesign(versionId);
    if (design) {
      return design;
    }

    for (let design of this.getAllDesigns()) {
      if (design.versions.some(version => version.id === versionId)) {
        const activeVersion = design.versions.find(version => version.activeVersion);
        return activeVersion && this.getDesign(activeVersion.id);
      }
    }
  }

  getCurrentDesignIdByVersionId(versionId: string) {
    const activeDesign = this.getActiveDesignByVersionId(versionId);
    return activeDesign?.versions.find(v => v.currentVersion)?.id;
  }

  fetchCurrentDesign(currentDesignId: string): Observable<Design> {
    const activeDesign = this.getActiveDesignByVersionId(currentDesignId);
    if (activeDesign.id === currentDesignId) {
      return of(activeDesign);
    }

    const siteId = this.getSiteId();
    return this.http
      .get(`${getServiceUrl('file')}/sites/${siteId}/designs/${currentDesignId}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designs.read }
      })
      .pipe(map(design => ({ ...design, type: DesignType.DESIGN })));
  }

  getRoadDesign(roadDesignId: string) {
    return this.roadDesignsQuery.getEntity(roadDesignId);
  }

  getRoadDesignBySharedVersionId(sharedVersionId: string) {
    return this.getAllRoadDesigns().find(design => design.sharedVersionId === sharedVersionId);
  }

  getRoadDesignsWithMesh() {
    return this.roadDesignsQuery.getAll({
      filterBy: roadDesign => roadDesign.roadDesignType === RoadDesignType.SURFACE && roadDesign.meshReady
    });
  }

  getDesignsWithTerrain() {
    return this.designsQuery.getAll({ filterBy: design => design.terrainReady });
  }

  getSurfaceDesignsWithTerrain() {
    return this.designsQuery.getAll({ filterBy: design => design.hasSurface && design.terrainReady });
  }

  getDesignUrls(designId: string) {
    const credentials = this.getViewerCredentials();
    const queryParameters = generateViewerCredentialsQueryParams(credentials);
    return {
      terrain: `${credentials.url}/designs/${designId}/terrain`,
      geojson: `${credentials.url}/designs/${designId}/design.json?${queryParameters}`,
      tiles: `${credentials.url}/designs/${designId}/tiles`,
      tiles2DMetadata: `${credentials.url}/designs/${designId}/tiles/metadata.json?${queryParameters}`,
      tiles3DMetadata: `${credentials.url}/designs/${designId}/3dTiles/tileset.json?${queryParameters}`
    };
  }

  getAllDesignCategories() {
    return this.designCategoriesQuery.getAll();
  }

  getDesignCategory(id: string) {
    return this.designCategoriesQuery.getEntity(id);
  }

  getFullyShownDesigns() {
    return [
      ...this.designsQuery.getAll({ filterBy: design => design.allIsShown }),
      ...this.roadDesignsQuery.getAll({ filterBy: roadDesign => roadDesign.allIsShown })
    ];
  }

  getShownLayers(): { designId: string; designType: DesignType; layerName: string }[] {
    const shownLayers: { designId: string; designType: DesignType; layerName: string }[] = [];

    [...this.getAllDesigns(), ...this.getAllRoadDesigns()]
      .filter(design => !!design && !design.allIsShown && !!design.layers)
      .forEach(design => {
        Object.entries(design.layers)
          .filter(([, isShown]) => isShown)
          .forEach(([layerName]) => shownLayers.push({ designId: design.id, designType: design.type, layerName }));
      });
    return shownLayers;
  }

  isDesignExpanded(expandedDesignID: string) {
    return this.getExpandedDesignIDs().has(expandedDesignID);
  }

  getExpandedDesignIDs() {
    return this.getValue().expandedDesignIDs;
  }

  private selectQueryByDesignType(type: DesignType) {
    return type === DesignType.DESIGN ? this.designsQuery : this.roadDesignsQuery;
  }

  getActiveLayerFeaturesId() {
    return this.getValue().activeDesignLayer?.featureIds || null;
  }

  getActiveLayerId() {
    return this.getValue().activeDesignLayer?.layerId;
  }

  getActiveLayerProperties() {
    return this.getValue().activeDesignLayer?.properties;
  }

  getActiveLayerDesignId() {
    return this.getValue().activeDesignLayer?.designId;
  }

  isFeatureActive(featureId: string) {
    return this.getActiveLayerFeaturesId()?.has(featureId);
  }

  getAllIntegrationDesignNodes() {
    return this.integrationDesignQuery.getAll();
  }

  getIntegrationDesignNodesByIds(ids: string[]) {
    return isDefined(ids) ? ids.map(id => this.integrationDesignQuery.getEntity(id)) : [];
  }

  getIntegrationDesignNodesDirtyCheck() {
    const dirtyCheck = new EntityDirtyCheckPlugin(this.integrationDesignQuery, {
      entityIds: this.getAllIntegrationDesignNodes().map(node => node.id),
      comparator: integrationDesignNodeComparator
    });
    dirtyCheck.setHead();
    return dirtyCheck;
  }

  designAllIsShown$(designId: string) {
    return this.designsQuery.selectEntity(designId).pipe(map(entity => entity?.allIsShown ?? false));
  }

  getDesignName(designId: string) {
    return this.getDesign(designId)?.name;
  }

  getActiveDesignChatId() {
    return this.getValue().activeDesignChatId;
  }

  getDesignNameWithVersion(design: Design, version: DesignVersion) {
    // Add a version name only if the design has more than one version.
    return design.versions.length === 1 ? design.name : `${design.name} (${version.name})`;
  }
}
