<!-- Show only ng-content if place is not provided -->
<ng-container *ngIf="!hasPlace">
  <ng-container *ngTemplateOutlet="content" />
</ng-container>

<ng-container *ngIf="hasPlace">
  <ng-container *ngTemplateOutlet="content" />
  <!-- Red dot with overlay functionality -->
  <span
    *ngIf="isFeatureHint && !kept && !isDismissed"
    #dot
    cdkOverlayOrigin
    class="dot"
    (mouseenter)="onDotHover()"
    type="button"
    [ngClass]="[dotPosition]"
    [style.--dot-offset-x]="dotOffsetX + 'px'"
    [style.--dot-offset-y]="dotOffsetY + 'px'"
  ></span>

  <!-- Red dot without overlay functionality -->
  <span
    *ngIf="!isFeatureHint && !kept && !isDismissed"
    #dot
    class="dot dot-with-hole cursor-auto"
    [ngClass]="[dotPosition]"
    [style.--dot-offset-x]="dotOffsetX + 'px'"
    [style.--dot-offset-y]="dotOffsetY + 'px'"
  ></span>
</ng-container>

<!-- This template displays the overlay content and is connected to the dot -->
<ng-template
  *ngIf="isFeatureHint && hasPlace"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="dot"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  [cdkConnectedOverlayPositions]="overlayPositionConfig"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="closeOverlay($event)"
  (positionChange)="onPositionChange($event)"
>
  <div class="overlay-wrapper">
    <div class="overlay">
      <div class="header">
        <div class="tag" *ngIf="hints[0].tag">{{ hints[0].tag }}</div>
        <div class="title">{{ title }}</div>
        <button mat-icon-button class="close-button" title="Close" (click)="onDismiss()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="description">{{ description }}</div>

      <div class="actions">
        <button mat-button (click)="onRemindLater()" i18n="@@allSites.featureHint.remindLater">Snooze</button>
        <a mat-stroked-button *ngIf="!!moreInfoLink" [href]="moreInfoLink" target="_blank" rel="noopener">
          <ng-container i18n="@@allSites.featureHint.moreInfo">More Info</ng-container>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<!-- We use this template for ng-content, because we need to use 2 and more ng-content -->
<ng-template #content>
  <ng-content />
</ng-template>
