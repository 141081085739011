import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs';

import { UserEmployeeTitleModel } from '../../../../generated/tenant/model/userEmployeeTitleModel';
import { GetUserTitleResponse } from '../../../../generated/ums/model/getUserTitleResponse';
import { AuthQuery } from '../../../auth/state/auth.query';
import { AccessLevelEnum, getAccessLevelLabel, UserRole } from '../../../auth/state/auth.utils';
import { Site, SiteStateEnum } from '../../../tenant/tenant.model';
import { TenantQuery } from '../../../tenant/tenant.query';
import { LimitReachedPopupService } from '../../limit-reached-popup/limit-reached-popup.service';
import { formatName } from '../../utils/formatting';
import { Team, User } from '../state/users-and-teams.model';
import { UsersAndTeamsQuery } from '../state/users-and-teams.query';
import { TeamDialogComponent } from '../team-dialog/team-dialog.component';

enum Tab {
  DETAILS = 'details',
  PERMISSIONS = 'permissions'
}

export interface UserDialogData {
  user?: User;
  initialValues?: Partial<User>;
  openAddTeamDialog?: () => MatDialogRef<TeamDialogComponent, Team>;
  isOperatorDisabled?: boolean;
}

interface UserForm {
  [Tab.DETAILS]: FormGroup<{
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    employeeTitle: FormControl<GetUserTitleResponse>;
    employeeNumber: FormControl<string>;
  }>;
  [Tab.PERMISSIONS]: FormGroup<{
    userRole: FormControl<UserRole>;
    permissionLevel: FormControl<AccessLevelEnum>;
    teams: FormControl<Team[]>;
    sites: FormControl<Site[]>;
  }>;
}

@UntilDestroy()
@Component({
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  Tab = Tab;
  UserRole = UserRole;

  userForm: FormGroup<UserForm>;
  activeTabIndex: number;
  employeeTitles: UserEmployeeTitleModel[];

  constructor(
    private fb: FormBuilder,
    public usersQuery: UsersAndTeamsQuery,
    public tenantQuery: TenantQuery,
    private authQuery: AuthQuery,
    @Inject(MAT_DIALOG_DATA) private data: UserDialogData,
    private dialogRef: MatDialogRef<UserDialogComponent, User>,
    private limitReachedPopup: LimitReachedPopupService
  ) {}

  ngOnInit() {
    this.activeTabIndex = 0;
    const userData = this.user ?? this.data.initialValues;
    this.userForm = this.fb.group({
      [Tab.DETAILS]: this.fb.group({
        firstName: [userData?.firstName ?? '', Validators.required],
        lastName: [userData?.lastName ?? '', Validators.required],
        email: [userData?.email ?? '', [Validators.required, Validators.email]],
        employeeTitle: [userData?.employeeTitle ?? null],
        employeeNumber: [userData?.employeeNumber ?? '']
      }),
      [Tab.PERMISSIONS]: this.fb.group({
        userRole: [userData?.accessLevel === AccessLevelEnum.OPERATORUSER ? UserRole.OPERATOR : UserRole.ACCOUNT_USER],
        permissionLevel: [userData?.accessLevel ?? AccessLevelEnum.BASICUSER],
        teams: [userData?.teams ?? []],
        sites: [userData?.sites ?? []]
      })
    });

    // Don't allow changing current user access level
    if (this.user && this.authQuery.getUserId() === this.user.id) {
      this.userForm.get(Tab.PERMISSIONS).get('permissionLevel').disable();
    }

    // Don't allow operator users to have sites that are not live
    const userRoleControl = this.userForm.controls[Tab.PERMISSIONS].controls.userRole;
    userRoleControl.valueChanges.pipe(untilDestroyed(this), startWith(userRoleControl.value)).subscribe(userRole => {
      if (userRole === UserRole.OPERATOR) {
        const sitesControl = this.userForm.controls[Tab.PERMISSIONS].controls.sites;
        sitesControl.patchValue(sitesControl.value.filter(site => site.state === SiteStateEnum.LIVE));
      }
    });

    // Don't allow changing role in edit mode
    if (this.isOperatorDisabled) {
      userRoleControl.disable();
    }

    this.employeeTitles = this.tenantQuery.getUsersEmployeeTitles();
  }

  get user() {
    return this.data?.user;
  }

  get isOperator() {
    return this.userForm.get(Tab.PERMISSIONS).get('userRole').value === UserRole.OPERATOR;
  }

  get isOperatorDisabled() {
    return this.data?.isOperatorDisabled || !!this.user;
  }

  get currentAccessLevel() {
    return this.isOperator ? AccessLevelEnum.OPERATORUSER : this.userForm.get(Tab.PERMISSIONS).get('permissionLevel').value;
  }

  get isTenantAdmin() {
    return this.currentAccessLevel === AccessLevelEnum.TENANTADMIN;
  }

  get isFormValid() {
    if (!this.userForm.valid) {
      return false;
    }

    // Don't allow removing last tenant admin
    const allTenantAdmins = this.usersQuery.getTenantAdmins();
    if (
      this.user &&
      this.user.accessLevel === AccessLevelEnum.TENANTADMIN &&
      this.currentAccessLevel !== AccessLevelEnum.TENANTADMIN &&
      allTenantAdmins.length === 1
    ) {
      return false;
    }

    // Don't allow changing current user access level
    if (this.user && this.authQuery.getUserId() === this.user.id) {
      return false;
    }

    return true;
  }

  get isAccessLevelLimitReached() {
    return !this.tenantQuery.getCanSaveUserByAccessLevelCount(this.currentAccessLevel, this.user?.accessLevel);
  }

  submit(event: MouseEvent) {
    if (this.isAccessLevelLimitReached) {
      const accessLevel = this.currentAccessLevel;

      // To show in the popup. Tenant admins are counted as analytic users
      const userTypeStr = getAccessLevelLabel(accessLevel === AccessLevelEnum.TENANTADMIN ? AccessLevelEnum.ANALYTICSUSER : accessLevel);

      const target = event.currentTarget as HTMLElement;
      const button = (target instanceof HTMLButtonElement ? target : target.parentElement) as HTMLButtonElement;
      const boundingRect = button.getBoundingClientRect();
      const windowHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;
      this.limitReachedPopup.open({
        position: {
          bottom: windowHeight - boundingRect.bottom + 47 + 'px',
          left: boundingRect.left - 17 + 'px'
        },
        direction: 'BOTTOM_LEFT',
        title: `Upgrade to remove users limit`,
        content: `You have reached the allowed number of ${userTypeStr} users for your account.

        To add additional ${userTypeStr} users, you will need to upgrade your account.`
      });
    } else if (this.isFormValid) {
      const currentUser = this.user ?? {};
      const user = this.userForm.value;
      const name = formatName({ firstName: user[Tab.DETAILS].firstName.trim(), lastName: user[Tab.DETAILS].lastName.trim() }) || '';
      const newUser = {
        ...currentUser,
        name,
        firstName: user[Tab.DETAILS].firstName?.trim(),
        lastName: user[Tab.DETAILS].lastName?.trim(),
        email: user[Tab.DETAILS].email?.trim(),
        employeeTitle: user[Tab.DETAILS].employeeTitle,
        employeeNumber: user[Tab.DETAILS].employeeNumber?.trim(),
        accessLevel:
          user[Tab.PERMISSIONS].userRole === UserRole.OPERATOR ? AccessLevelEnum.OPERATORUSER : user[Tab.PERMISSIONS].permissionLevel,
        teams: user[Tab.PERMISSIONS].teams,
        sites: user[Tab.PERMISSIONS].sites
      };
      this.close(newUser);
    }
  }

  nextTab() {
    this.activeTabIndex++;
  }

  get isLastTab() {
    return this.activeTabIndex === Object.keys(Tab).length - 1;
  }

  isTabValid(tab: Tab) {
    return this.userForm.get(tab).valid;
  }

  close(user?: User) {
    this.dialogRef.close(user);
  }

  addNewTeam() {
    const dialogRef = this.data.openAddTeamDialog?.();
    if (!dialogRef) {
      return;
    }

    dialogRef.afterClosed().subscribe(newTeam => {
      if (newTeam) {
        const teamsField = this.userForm.get(Tab.PERMISSIONS).get('teams');
        teamsField.patchValue([...teamsField.value, newTeam]);
      }
    });
  }

  get allowAddTeam() {
    return !!this.data.openAddTeamDialog;
  }
}
