import { Injectable } from '@angular/core';
import { Order, Query, QueryEntity } from '@datorama/akita';
import { Cartesian3 } from 'cesium';
import moment from 'moment';
import { combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { BaseSurface } from '../../../generated/mms/model/baseSurface';
import { isLocalCS } from '../../shared/utils/backend-services';
import { convertCoordinateCSToPosition, convertCoordinateToSiteCS } from '../../shared/utils/cs-conversions';
import { Cartographic, GeoUtils, Position2D, Position3D } from '../../shared/utils/geo';
import { roundTo } from '../../shared/utils/math';
import { convertFromMeters, DistanceUnitsEnum, getUnitSign } from '../../shared/utils/unit-conversion';
import { ArtifactEnum, SiteStateEnum } from '../../tenant/tenant.model';
import {
  FlightSourceEnum,
  GEOREF_METHODS_WITH_GCP,
  MapTextureType,
  OverlaysEnum,
  SurfaceModelType,
  TabEnum,
  Task,
  TaskStateEnum
} from './detailed-site.model';
import { CalcModelOption, CalcModelType } from './detailed-site.model';
import { DetailedSiteState, DetailedSiteStore } from './detailed-site.store';
import { DetailedSiteDesignsQuery } from './detailed-site-designs/detailed-site-designs.query';
import {
  BaseSurfaceOption,
  BaseSurfaceType,
  CalcModelValues,
  MapEntity,
  PolygonType,
  SourceModel
} from './detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from './detailed-site-entities/detailed-site-entities.query';

export const oldestToNewestTasksSorting = (t1: Task, t2: Task) => (moment(t1.missionFlightDate).isAfter(t2.missionFlightDate) ? 1 : -1);
export const newestToOldestTasksSorting = (t1: Task, t2: Task) => (moment(t1.missionFlightDate).isAfter(t2.missionFlightDate) ? -1 : 1);

export const finishedTasksFilter = (task: Task) => task.state === TaskStateEnum.SUCCESS;
export const taskHasGCP = (t: Task) =>
  t.state === TaskStateEnum.SUCCESS && t.flightSource !== FlightSourceEnum.IMPORTED && t.georefMethod in GEOREF_METHODS_WITH_GCP;

export function getSourceModelFromTexture(mapTexture: MapTextureType) {
  switch (mapTexture) {
    case MapTextureType.TERRAIN:
      return SourceModel.DTM;

    case MapTextureType.SURFACE:
    case MapTextureType.TILES_3D:
    case MapTextureType.POINT_CLOUD:
      return SourceModel.DSM;

    case MapTextureType.FLAT:
    default:
      return SourceModel.FLAT;
  }
}

@Injectable({ providedIn: 'root' })
export class DetailedSiteQuery extends Query<DetailedSiteState> {
  siteLoading$ = this.selectLoading();
  site$ = this.select(state => state.site);
  siteBounds$ = this.select(state => state.siteBounds);
  siteName$ = this.select(state => state.site && state.site.name);
  siteDescription$ = this.select(state => state.site && state.site.description);
  siteAssociations$ = this.select(state => state.site && state.site.associations);
  isLocalCS$ = this.select(state => isLocalCS(state.site?.coordinateSystem));
  mapDiffTask$ = this.select(state => state.ui.mapDiffTask);
  isMapFullScreen$ = this.select(state => state.ui.isMapFullScreen);
  siteImageryLayer$ = this.select(state => state.ui.siteImageryLayer);
  isSimpleView$ = this.select(state => state.ui.isSimpleView);
  isScreenshotGenerating$ = this.select(state => state.ui.isScreenshotGenerating);
  mapPositionPin$ = this.select(state => state.ui.mapPositionPin);
  myPositionPin$ = this.select(state => state.ui.myPositionPin);
  goToPin$ = this.select(state => state.ui.goToPin);

  cursorLocation$ = this.select(state => state.ui.cursorLocation);
  imageViewerToolShow$ = this.select(state => state.ui.imageViewerTool.show);
  imageViewerToolImages$ = this.select(state => state.ui.imageViewerTool.images);
  gcps$ = this.select(state => state.ui.overlays[OverlaysEnum.GCPS].gcps);
  showGCPs$ = this.select(state => state.ui.overlays[OverlaysEnum.GCPS].show);
  mapTextureType$ = this.select(state => state.ui.mapTexture);
  sourceModel$ = this.select(state => getSourceModelFromTexture(state.ui.mapTexture));
  isDisplayingTerrain$ = this.select(state =>
    [MapTextureType.TERRAIN, MapTextureType.SURFACE, MapTextureType.FLAT].includes(state.ui.mapTexture)
  );
  dsmAvailable$ = this.select(state => state.ui.dsmAvailable);
  viewerCredentials$ = this.select(state => state.viewerUrlsCredentials);
  mapLoading$ = this.select(state => state.ui.mapLoading).pipe(distinctUntilChanged());
  contourOverlay$ = this.select(state => state.ui.overlays[OverlaysEnum.CONTOUR]);
  elevationOverlay$ = this.select(state => state.ui.overlays[OverlaysEnum.ELEVATION]);

  tasksQuery = new QueryEntity(this.store.tasks, { sortBy: oldestToNewestTasksSorting, sortByOrder: Order.ASC });
  tasks$ = this.tasksQuery.selectAll({ filterBy: task => task.state !== TaskStateEnum.UPLOADING });
  tasksLoading$ = this.tasksQuery.selectLoading();
  finishedTasks$ = this.tasksQuery.selectAll({ filterBy: finishedTasksFilter });
  activeTask$ = this.tasksQuery.selectActive().pipe(distinctUntilChanged((x, y) => x && y && x.id === y.id));
  siteDisabled$ = combineLatest([this.tasksQuery.selectActive(), this.siteLoading$, this.mapLoading$]).pipe(
    map(([task, siteLoading, mapLoading]) => !task || siteLoading || mapLoading)
  );

  mapOpacity$ = this.select(state => state.mapOpacity);
  get mapOpacity() {
    return this.getValue().mapOpacity;
  }

  mapStyle$ = this.select(state => state.mapStyle);
  activeTab$ = this.select(state => state.ui.activeTab);
  get mapStyle() {
    return this.getValue().mapStyle;
  }

  constructor(
    protected store: DetailedSiteStore,
    private designsQuery: DetailedSiteDesignsQuery,
    private siteEntitiesQuery: DetailedSiteEntitiesQuery
  ) {
    super(store);
  }

  sortCalcModels = (a: CalcModelValues | CalcModelOption, b: CalcModelValues | CalcModelOption) => {
    if (a.type !== b.type) {
      return a.type === CalcModelType.TASK ? -1 : 1;
    } else {
      if (a.type === CalcModelType.TASK && b.type === CalcModelType.TASK) {
        const taskA = this.getTask(a.id);
        const taskB = this.getTask(b.id);
        if (!taskA || !taskB) {
          return 0;
        }

        return newestToOldestTasksSorting(taskA, taskB);
      } else if (a.type === CalcModelType.DESIGN && b.type === CalcModelType.DESIGN) {
        const designA = this.designsQuery.getActiveDesignByVersionId(a.id);
        const designB = this.designsQuery.getActiveDesignByVersionId(b.id);
        if (!designA || !designB) {
          return 0;
        }
        return designA.name.localeCompare(designB.name);
      }
    }
  };

  getTerrainHeightOffset() {
    return this.getValue().siteOffset ?? 0;
  }

  getIsSiteUnarchived() {
    return this.getValue().site?.state === SiteStateEnum.UNARCHIVED;
  }

  getMapPositionPin() {
    return this.getValue().ui.mapPositionPin;
  }

  getMyPositionPin() {
    return this.getValue().ui.myPositionPin;
  }

  isSiteDisabled() {
    return !this.getActiveTask();
  }

  getMapTexture() {
    return this.getValue().ui.mapTexture;
  }

  isDisplayingTerrain() {
    const currentTexture = this.getValue().ui.mapTexture;
    return [MapTextureType.FLAT, MapTextureType.TERRAIN, MapTextureType.SURFACE].includes(currentTexture);
  }

  getCurrentSourceModel() {
    const currentTexture = this.getValue().ui.mapTexture;
    return getSourceModelFromTexture(currentTexture);
  }

  getActiveTask() {
    return this.tasksQuery.getActive();
  }

  getActiveTaskId() {
    const activeTask = this.getActiveTask();
    return activeTask?.id;
  }

  getTaskIndex(taskId: string) {
    const allTasks = this.getAllTasks();
    return allTasks.findIndex(task => task.id === taskId);
  }

  getTask(taskId: string) {
    return this.tasksQuery.getEntity(taskId);
  }

  getTaskState(taskId: string) {
    return this.getTask(taskId)?.state;
  }

  selectTask(taskId: string) {
    return this.tasksQuery.selectEntity(taskId);
  }

  getTaskOrDesignName(id: string, type: CalcModelType | SurfaceModelType) {
    switch (type) {
      case CalcModelType.TASK: {
        const task = this.getTask(id);
        return task?.flightDateLabel;
      }
      case CalcModelType.DESIGN: {
        const design = this.designsQuery.getActiveDesignByVersionId(id);
        return design?.name;
      }
      case SurfaceModelType.ROAD_DESIGN: {
        const roadDesign = this.designsQuery.getRoadDesign(id);
        return roadDesign?.name;
      }
    }
  }

  getSite() {
    return this.getValue().site;
  }

  getIsLocalCS() {
    return isLocalCS(this.getSite()?.coordinateSystem);
  }

  getSiteUnits(): DistanceUnitsEnum {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.units;
  }

  getSiteVolumeUnits(): DistanceUnitsEnum {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.volumeUnits;
  }

  getSiteId() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.id;
  }

  getSiteCoordinateSystem() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.coordinateSystem;
  }

  getSiteLocation() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return { longitude: site.longitude, latitude: site.latitude };
  }

  getSiteDescription() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.description;
  }

  getSiteBounds() {
    return this.getValue().siteBounds;
  }

  getAllTasks() {
    return this.tasksQuery.getAll();
  }

  getAllFinishedTasks(sortAsc = true) {
    return this.tasksQuery.getAll({
      filterBy: finishedTasksFilter,
      sortBy: sortAsc ? oldestToNewestTasksSorting : newestToOldestTasksSorting
    });
  }

  // if taskId is falsy, we go over all current tasks
  getPrevTaskHadGCP(taskId?: string) {
    const tasks = this.getAllTasks();
    for (const task of tasks) {
      if (taskId && taskId === task.id) {
        break;
      }

      if (taskHasGCP(task)) {
        return true;
      }
    }

    return false;
  }

  getPrevTask(taskId: string, onlyFinished = false) {
    let prevTask: Task;
    const tasks = this.getAllTasks();
    for (const task of tasks) {
      if (taskId === task.id) {
        break;
      }

      if (!onlyFinished || finishedTasksFilter(task)) {
        prevTask = task;
      }
    }

    return prevTask;
  }

  getAllTasksWithGCP() {
    return this.tasksQuery.getAll({ filterBy: taskHasGCP, sortBy: newestToOldestTasksSorting });
  }

  canUpdateSiteSettings() {
    const tasksCount = this.getAllTasksCount();
    const designsCount = this.designsQuery.getAllDesignTypesCount();
    const entitiesCount = this.siteEntitiesQuery.getAllEntitiesCount();

    return tasksCount + designsCount + entitiesCount === 0;
  }

  private getAnalyticEntitiesObservable() {
    return combineLatest([this.siteEntitiesQuery.analyticsQuery.selectAll(), this.activeTask$]).pipe(map(([entities]) => entities));
  }

  getMapMarkersNotifications$() {
    return merge(
      this.siteEntitiesQuery.createMapNotificationsObserver(this.getAnalyticEntitiesObservable(), 'point'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.measurementsQuery.selectAll(), 'point'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.annotationsQuery.selectAll(), 'point')
    );
  }

  getMapPolygonsNotifications$() {
    return merge(
      this.siteEntitiesQuery.createMapNotificationsObserver(this.getAnalyticEntitiesObservable(), 'polygon'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.measurementsQuery.selectAll(), 'polygon')
    );
  }

  getMapLineNotifications$() {
    return merge(
      this.siteEntitiesQuery.createMapNotificationsObserver(this.getAnalyticEntitiesObservable(), 'line'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.measurementsQuery.selectAll(), 'line')
    );
  }

  getMapModelEditNotifications$() {
    return this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.modelEditsQuery.selectAll(), 'modelEdit');
  }

  getTasksLoading() {
    return this.tasksQuery.getValue().loading;
  }

  getAllTasksCount() {
    return this.tasksQuery.getCount();
  }

  getFinishedTasksCount() {
    return this.tasksQuery.getCount(finishedTasksFilter);
  }

  getMapDiffTask() {
    return this.getValue().ui.mapDiffTask;
  }

  getSiteImageryLayer() {
    return this.getValue().ui.siteImageryLayer;
  }

  getOverlays() {
    return this.getValue().ui.overlays;
  }

  getOverlay(overlay: OverlaysEnum) {
    return this.getValue().ui.overlays[overlay];
  }

  getViewerCredentials() {
    return this.getValue().viewerUrlsCredentials;
  }

  getElevationOptions() {
    return this.getValue().ui.overlays[OverlaysEnum.ELEVATION].options;
  }

  getContourOptions() {
    return this.getValue().ui.overlays[OverlaysEnum.CONTOUR].options;
  }

  convertCoordinateToSiteCS({ longitude, latitude, height = 0 }: Cartographic) {
    const cs = this.getValue().site?.coordinateSystem;
    return convertCoordinateToSiteCS({ longitude, latitude, height }, cs);
  }

  convertCartesian3ToSiteCS(cartesian: Cartesian3) {
    return this.convertCoordinateToSiteCS(GeoUtils.cartesian3ToDeg(cartesian));
  }

  convertCoordinateCSToPosition(pos: Position2D | Position3D) {
    const cs = this.getValue().site?.coordinateSystem;
    const { x, y } = pos;
    const z = 'z' in pos ? pos.z : 0;

    return convertCoordinateCSToPosition({ x, y, z }, cs);
  }

  getBaseSurfaceName(baseSurface: BaseSurface, addValueToCustomElevation = true) {
    if (!baseSurface) {
      return 'Interpolated';
    }
    switch (baseSurface.type) {
      case BaseSurfaceType.INTERPOLATED:
        return 'Interpolated';
      case BaseSurfaceType.MINELEVATION:
        return 'Minimum Elevation';
      case BaseSurfaceType.CUSTOMELEVATION:
        if (addValueToCustomElevation) {
          const siteUnits = this.getSiteUnits();
          const unitsSign = getUnitSign(siteUnits);
          const elevation = roundTo(convertFromMeters(baseSurface.elevation, siteUnits));
          return `Custom Elevation (${elevation} ${unitsSign})`;
        } else {
          return 'Custom Elevation';
        }
      case BaseSurfaceType.TASK: {
        const task = this.getTask(baseSurface.id);
        if (task) {
          return task.flightDateLabel;
        }
      }
      case BaseSurfaceType.DESIGN: {
        const design = this.designsQuery.getActiveDesignByVersionId(baseSurface.id);
        if (design) {
          return design.name;
        }
      }
      default:
        return baseSurface.type;
    }
  }

  // TODO: use somewhere to check base surface existance or delete
  isBaseSurfaceExist(baseSurface: BaseSurface) {
    if (!baseSurface) {
      return false;
    }
    switch (baseSurface.type) {
      case BaseSurfaceType.DESIGN:
        // Design must exist and have terrain
        const design = this.designsQuery.getActiveDesignByVersionId(baseSurface.id);
        return design && design.terrainReady;
      case BaseSurfaceType.TASK:
        const task = this.getTask(baseSurface.id);
        // Task must exist and be in state success
        return task && task.state === TaskStateEnum.SUCCESS;
      case BaseSurfaceType.CUSTOMELEVATION:
      case BaseSurfaceType.INTERPOLATED:
      case BaseSurfaceType.MINELEVATION:
        return true;
    }
  }

  getFlightWizardDialogId() {
    return this.getValue().ui.flightWizardDialogId;
  }

  getAllAvailableCalcModelOptions(withActiveTask = true, usedDesignIds?: string[]): CalcModelOption[] {
    const taskOptions = this.getAllAvailableTaskOptions(withActiveTask);

    const designs = this.designsQuery.getDesignsWithTerrain();
    const designOptions: CalcModelOption[] = designs?.map(d => {
      const designVersions = d.versions;
      const usedVersion = usedDesignIds && designVersions.find(v => usedDesignIds.includes(v.id));
      const currentVersion = designVersions.find(v => v.currentVersion);
      return {
        // Add the design version used for calc instead of the current one for selectors to work correctly in the details box
        id: usedVersion?.id || currentVersion?.id,
        type: CalcModelType.DESIGN,
        name: this.designsQuery.getDesignNameWithVersion(d, usedVersion || currentVersion)
      };
    });

    return [...taskOptions, ...designOptions];
  }

  getAllAvailableTaskOptions(withActiveTask: boolean, sortAsc = true): CalcModelOption[] {
    let taskOptions: CalcModelOption[] = this.getAllFinishedTasks(sortAsc).map(t => ({
      id: t.id,
      type: CalcModelType.TASK,
      name: this.getTaskOrDesignName(t.id, CalcModelType.TASK)
    }));
    if (!withActiveTask) {
      const activeTaskId = this.getActiveTaskId();
      taskOptions = taskOptions.filter(task => task.id !== activeTaskId);
    }
    return taskOptions;
  }

  getBaseSurfacesOptions(entity: MapEntity) {
    const entityType = entity.type as PolygonType;
    const designs = this.designsQuery.getDesignsWithTerrain();
    const usedDesignBaseSurfaceId = entity.baseSurface?.type === BaseSurfaceType.DESIGN && entity.baseSurface.id;

    let tasks = this.getAllFinishedTasks(false);
    const withActiveTask = entityType !== PolygonType.VOLUME; // Don't add active task to base surface list for Volume entities
    if (!withActiveTask) {
      const activeTaskId = this.getActiveTaskId();
      tasks = tasks.filter(t => t.id !== activeTaskId);
    }

    let baseSurfaceOptions: BaseSurfaceOption[] = [
      { id: 'CUSTOM_ELEVATION', name: 'Custom Elevation', type: BaseSurfaceType.CUSTOMELEVATION },
      ...tasks.map(t => ({
        id: t.id,
        name: t.flightDateLabel,
        type: BaseSurfaceType.TASK
      })),
      ...designs?.map(d => {
        const designVersions = d.versions;
        const usedVersion = usedDesignBaseSurfaceId && designVersions.find(v => v.id === usedDesignBaseSurfaceId);
        const currentVersion = designVersions.find(v => v.currentVersion);
        return {
          // Add the design version used for calc instead of the current one for selector to work correctly in the details box
          id: usedVersion?.id || currentVersion?.id,
          type: BaseSurfaceType.DESIGN,
          name: this.designsQuery.getDesignNameWithVersion(d, usedVersion || currentVersion)
        };
      })
    ];

    if (entityType === PolygonType.VOLUME || entityType === PolygonType.POLYGON_DELTA_VOLUME) {
      baseSurfaceOptions = [
        { id: 'INTERPOLATED', name: 'Interpolated', type: BaseSurfaceType.INTERPOLATED },
        { id: 'MIN_ELEVATION', name: 'Minimum Elevation', type: BaseSurfaceType.MINELEVATION },
        ...baseSurfaceOptions
      ];
    }
    return baseSurfaceOptions;
  }

  getTabExpandedGroups(tab: TabEnum) {
    return this.getValue().ui.tabsExpandedGroups[tab];
  }

  getActiveTab() {
    return this.getValue().ui.activeTab;
  }

  isGroupExpanded(tab: TabEnum, groupId: string) {
    const expanded = this.getTabExpandedGroups(tab);
    return expanded?.has(groupId);
  }

  getAssociationByArtifact(artifact: ArtifactEnum) {
    return this.getSite().associations?.find(association => association.artifact === artifact);
  }
}
