import { Injectable } from '@angular/core';

import { CalcService } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { CalcModelOption } from '../../state/detailed-site.model';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';
import { CalcModelValues, MapEntity } from '../../state/detailed-site-entities/detailed-site-entities.model';

@Injectable({
  providedIn: 'root'
})
export class PointCalcService extends CalcService {
  constructor(
    private terrainSampling: TerrainSamplingService,
    private siteService: DetailedSiteService
  ) {
    super();
  }

  async calcResults(entity: MapEntity, siteId: string, modelOptions: CalcModelOption[]): Promise<{ calcResult: CalcModelValues[] }> {
    let calcResult: CalcModelValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelOptions.length; i++) {
      const modelOption = modelOptions[i];
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelOption.id, modelOption.type, entity.sourceModel);
      const positionsWithHeight = await this.terrainSampling.sampleTerrain(entity.positions, modelTerrain);
      calcResult = [
        ...calcResult.filter(res => res.id !== modelOption.id || res.type !== modelOption.type),
        {
          id: modelOption.id,
          type: modelOption.type,
          values: [{ ...FIELD_MAPPING.elevation, value: positionsWithHeight[0].height }]
        }
      ];
    }

    return { calcResult };
  }
}
