import { Injectable } from '@angular/core';
import { Feature, MultiPoint } from 'geojson';

import { Cartographic } from '../../../shared/utils/geo';
import { GeoJsonProperties, PolylineCalcService, PrecalcData } from '../../services/calc-services';
import { CalcModelOption } from '../../state/detailed-site.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { CalcModelValues, MapEntity } from '../../state/detailed-site-entities/detailed-site-entities.model';
import { DistanceCalcService } from './distance-calc.service';

export interface SamplePoint {
  point: Cartographic;
  distance: number;
}

@Injectable({
  providedIn: 'root'
})
export class CrossSectionCalcService extends PolylineCalcService {
  constructor(protected siteQuery: DetailedSiteQuery, private distanceCalc: DistanceCalcService) {
    super(siteQuery);
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelOptions: CalcModelOption[],
    precalcData: PrecalcData
  ): Promise<{ calcResult: CalcModelValues[]; samplePoints: Feature<MultiPoint, GeoJsonProperties> }> {
    return await this.distanceCalc.calcResults(entity, siteId, modelOptions, precalcData);
  }
}
