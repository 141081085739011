import { GetSiteResponse } from '../../../generated/tenant/model/getSiteResponse';
export import DistanceUnitsEnum = GetSiteResponse.UnitsEnum;

export type UnitsEnum = DistanceUnitsEnum | 'DEGREE' | 'PERCENT' | 'TON';
export const UnitsEnum = {
  ...DistanceUnitsEnum,
  DEGREE: 'DEGREE' as UnitsEnum,
  PERCENT: 'PERCENT' as UnitsEnum,
  TONNE: 'TONNE' as UnitsEnum,
  TON: 'TON' as UnitsEnum
};

export const METERS_PER_KILOMETER = 1000;
export const FEET_PER_MILE = 5280;
export const YARDS_PER_MILE = 1760;
export const INCH_TO_FEET = 1 / 12;

export function getUnitName(unit: UnitsEnum, plural = false) {
  switch (unit) {
    case DistanceUnitsEnum.INTFOOT:
    case DistanceUnitsEnum.USFOOT:
      return plural ? $localize`:@@unit.footPlural:feet` : $localize`:@@unit.foot:foot`;
    case DistanceUnitsEnum.YARD:
      return plural ? $localize`:@@unit.yardPlural:yards` : $localize`:@@unit.yard:yard`;
    case DistanceUnitsEnum.METER:
      return plural ? $localize`:@@unit.meterPlural:meters` : $localize`:@@unit.meter:meter`;
    case UnitsEnum.DEGREE:
      return plural ? $localize`:@@unit.degreePlural:degrees` : $localize`:@@unit.degree:degree`;
    case UnitsEnum.PERCENT:
      return $localize`:@@unit.percent:percent`;
    case UnitsEnum.TONNE:
      return plural ? $localize`:@@unit.tonnePlural:tonnes` : $localize`:@@unit.tonne:tonne`;
    case UnitsEnum.TON:
      return plural ? $localize`:@@unit.tonPlural:tons` : $localize`:@@unit.ton:ton`;
  }
}

export const getUnitSign = (unit: UnitsEnum, exp = 1) => {
  if (exp === 0) {
    return '';
  }

  let sign: string;
  switch (unit) {
    case UnitsEnum.PERCENT:
      sign = '%';
      break;

    case UnitsEnum.DEGREE:
      sign = '°';
      break;

    case UnitsEnum.TONNE:
      sign = $localize`:@@unit.tonneSign:t`;
      break;
    case UnitsEnum.TON:
      sign = $localize`:@@unit.tonSign:t`;
      break;

    case DistanceUnitsEnum.INTFOOT:
    case DistanceUnitsEnum.USFOOT:
      sign = $localize`:@@unit.footSign:ft`;
      break;

    case DistanceUnitsEnum.YARD:
      sign = $localize`:@@unit.yardSign:yd`;
      break;

    case DistanceUnitsEnum.METER:
    default:
      sign = $localize`:@@unit.meterSign:m`;
      break;
  }

  if (exp === 2) {
    sign += '²';
  } else if (exp === 3) {
    sign += '³';
  }

  return sign;
};

// Taken from https://www.sfei.org/it/gis/map-interpretation/conversion-constants
const FEET_US_TO_METER = 1200 / 3937;
const FEET_INTER_TO_METER = 0.3048;
const YARD_TO_METER = 0.9144;

export const feetUsToMeter = (value: number, exp = 1) => value * FEET_US_TO_METER ** exp;
export const feetInterToMeter = (value: number, exp = 1) => value * FEET_INTER_TO_METER ** exp;
export const yardToMeter = (value: number, exp = 1) => value * YARD_TO_METER ** exp;

export const meterToFeetUs = (meter: number, exp = 1) => meter / FEET_US_TO_METER ** exp;
export const meterToFeetInter = (meter: number, exp = 1) => meter / FEET_INTER_TO_METER ** exp;
export const meterToYard = (meter: number, exp = 1) => meter / YARD_TO_METER ** exp;

export const convertToMeters = (value: number, fromUnit: UnitsEnum, exp = 1) => {
  switch (fromUnit) {
    case DistanceUnitsEnum.INTFOOT:
      return feetInterToMeter(value, exp);

    case DistanceUnitsEnum.USFOOT:
      return feetUsToMeter(value, exp);

    case DistanceUnitsEnum.YARD:
      return yardToMeter(value, exp);

    case DistanceUnitsEnum.METER:
    default:
      return value;
  }
};

export const convertFromMeters = (meter: number, wantedUnit: UnitsEnum, exp = 1) => {
  switch (wantedUnit) {
    case DistanceUnitsEnum.INTFOOT:
      return meterToFeetInter(meter, exp);

    case DistanceUnitsEnum.USFOOT:
      return meterToFeetUs(meter, exp);

    case DistanceUnitsEnum.YARD:
      return meterToYard(meter, exp);

    case DistanceUnitsEnum.METER:
    default:
      return meter;
  }
};

export const convertFromMetersWithUnits = (meter: number, wantedUnit: UnitsEnum, exp = 1) => {
  return {
    value: convertFromMeters(meter, wantedUnit, exp),
    units: getUnitSign(wantedUnit, exp)
  };
};
