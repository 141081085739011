import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LoadingModalCloseResult, LoadingModalComponent, LoadingModalData } from './loading-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingModalService {
  constructor(private dialog: MatDialog) {}

  open({ title, closeInfo, disableClose = true, loadingProgress$ = null }: LoadingModalData) {
    return this.dialog.open<LoadingModalComponent, LoadingModalData, LoadingModalCloseResult>(LoadingModalComponent, {
      disableClose,
      autoFocus: false,
      restoreFocus: false,
      width: '40vw',
      height: '40%',
      minWidth: '500px',
      minHeight: '250px',
      data: {
        title,
        closeInfo,
        loadingProgress$
      }
    });
  }
}
