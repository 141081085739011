{
  "name": "datubim",
  "version": "5.4.0",
  "type": "module",
  "scripts": {
    "ng": "node --max_old_space_size=16384 ./node_modules/@angular/cli/bin/ng",
    "start": "ng serve",
    "build": "yarn ng build --configuration=dev --extract-licenses=false",
    "build:sourcemaps": "yarn ng build --configuration=dev --output-path=localSourceMaps",
    "build:local": "yarn ng build -c=local --extract-licenses=false",
    "build:qa": "yarn ng build -c=qa --extract-licenses=false",
    "build:staging": "yarn ng build -c=staging --extract-licenses=false",
    "build:docker": "yarn ng build -c=docker --extract-licenses=false",
    "build:prod": "yarn ng build --configuration production --extract-licenses=false",
    "build:prod-us": "yarn ng build -c=prod-us --extract-licenses=false",
    "build:prod-eu": "yarn ng build -c=prod-eu --extract-licenses=false",
    "deploy:dev": "aws s3 sync dist/datubim/ s3://devbim.datumate.com/ --delete --profile datubim --cache-control \"max-age=10\"",
    "deploy:prod": "aws s3 sync dist/datubim/ s3://bim.datumate.com/ --delete --profile datubim --cache-control \"max-age=10\"",
    "build:deploy:dev": "yarn build; yarn deploy:dev",
    "build:deploy:prod": "yarn build:prod; yarn deploy:prod",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "precommit": "lint-staged",
    "postinstall": "patch-package",
    "gen:types:tenant": "swagger-codegen generate -i https://devbimapi.datumate.com/tenant/v2/api-docs -l typescript-angular -o ./src/generated/tenant -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/tenant/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:ums": "swagger-codegen generate -i https://devbimapi.datumate.com/ums/v2/api-docs -l typescript-angular -o ./src/generated/ums -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/ums/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:fms": "swagger-codegen generate -i https://devbimapi.datumate.com/fms/v2/api-docs -l typescript-angular -o ./src/generated/fms -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/fms/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:file": "swagger-codegen generate -i https://devbimapi.datumate.com/file/v2/api-docs -l typescript-angular -o ./src/generated/file -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/file/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:mms": "swagger-codegen generate -i https://devbimapi.datumate.com/mms/v2/api-docs -l typescript-angular -o ./src/generated/mms -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/mms/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:jms": "swagger-codegen generate -i https://devbimapi.datumate.com/jms/v2/api-docs -l typescript-angular -o ./src/generated/jms -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/jms/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:nms": "swagger-codegen generate -i https://devbimapi.datumate.com/nms/v2/api-docs -l typescript-angular -o ./src/generated/nms -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/nms/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:package": "swagger-codegen generate -i https://devbimapi.datumate.com/package/v2/api-docs -l typescript-angular -o ./src/generated/package -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/package/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:integration": "swagger-codegen generate -i https://devbimapi.datumate.com/integration/v2/api-docs -l typescript-angular -o ./src/generated/integration -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/integration/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:activity": "swagger-codegen generate -i https://devbimapi.datumate.com/activity/v2/api-docs -l typescript-angular -o ./src/generated/activity -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/activity/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:resourcelinks": "swagger-codegen generate -i https://devbimapi.datumate.com/resourcelinks/v2/api-docs -l typescript-angular -o ./src/generated/resourcelinks -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/resourcelinks/**/*.{json,css,scss,md,js,ts}\"",
    "gen:types:aimanagement": "swagger-codegen generate -i https://devbimapi.datumate.com/aimanagement/v2/api-docs -l typescript-angular -o ./src/generated/aimanagement -Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true && prettier --write \"./src/generated/aimanagement/**/*.{json,css,scss,md,js,ts}\""
  },
  "config": {
    "gen_arguments": "-Dmodels --additional-properties modelPropertyNaming=original ngVersion=13.0.2 supportsES6=true"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.10",
    "@angular/cdk": "^18.2.11",
    "@angular/common": "^18.2.10",
    "@angular/compiler": "^18.2.10",
    "@angular/core": "^18.2.10",
    "@angular/forms": "^18.2.10",
    "@angular/material": "^18.2.11",
    "@angular/material-moment-adapter": "^18.2.11",
    "@angular/platform-browser": "^18.2.10",
    "@angular/platform-browser-dynamic": "^18.2.10",
    "@angular/router": "^18.2.10",
    "@auth0/angular-jwt": "^5.2.0",
    "@datorama/akita": "^8.0.1",
    "@datorama/akita-ng-router-store": "^8.0.0",
    "@datumate/angular-cesium": "^0.0.87",
    "@datumate/dhtmlx-gantt": "^8.0.6",
    "@mapbox/vector-tile": "^2.0.3",
    "@ng-select/ng-select": "13.9.1",
    "@ngneat/until-destroy": "^10.0.0",
    "@turf/turf": "^7.1.0",
    "aws-sdk": "^2.1692.0",
    "cesium": "1.92.0",
    "chart.js": "^3.9.1",
    "chartjs-adapter-moment": "^1.0.0",
    "chartjs-plugin-annotation": "^2.0.1",
    "chartjs-plugin-zoom": "^1.2.1",
    "dxf-parser": "^1.1.2",
    "exifr": "^7.1.3",
    "file-saver": "^2.0.5",
    "grapick": "^0.1.13",
    "html2canvas": "^1.4.1",
    "html2pdf.js": "^0.10.2",
    "immer": "^10.1.1",
    "jszip": "^3.10.1",
    "jszip-utils": "^0.1.0",
    "localforage": "^1.10.0",
    "lodash": "^4.17.21",
    "mathjs": "^14.0.1",
    "mixpanel-browser": "^2.56.0",
    "moment": "^2.30.1",
    "ng-click-outside2": "^16.0.0",
    "ng2-charts": "^3.1.2",
    "ngx-color": "^9.0.0",
    "ngx-device-detector": "8.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-papaparse": "^8.0.0",
    "pako": "^2.1.0",
    "pbf": "^4.0.1",
    "pdfjs-dist": "^4.10.38",
    "proj4": "^2.15.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.8.1",
    "util": "^0.12.5",
    "uuid": "^11.0.3",
    "viewerjs": "^1.11.7",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-eslint/builder": "18",
    "@angular-eslint/eslint-plugin": "18",
    "@angular-eslint/eslint-plugin-template": "18",
    "@angular-eslint/schematics": "18",
    "@angular-eslint/template-parser": "18",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.10",
    "@angular/language-service": "^18.2.10",
    "@angular/localize": "18.2.10",
    "@datorama/akita-cli": "^6.1.1",
    "@types/chart.js": "^2.9.31",
    "@types/file-saver": "^2.0.5",
    "@types/geojson": "^7946.0.15",
    "@types/jasmine": "^4.3.0",
    "@types/lodash": "^4.14.189",
    "@types/mixpanel-browser": "^2.50.2",
    "@types/node": "20",
    "@types/pbf": "^3.0.2",
    "@types/proj4": "^2.5.2",
    "@types/uuid": "^9.0.2",
    "@typescript-eslint/eslint-plugin": "^5.59.5",
    "@typescript-eslint/parser": "^5.59.5",
    "eslint": "^8.40.0",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-jsdoc": "^44.2.3",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "5.0.0",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "eslint-plugin-unused-imports": "^4.1.3",
    "husky": "^3.1.0",
    "jasmine-core": "^4.5.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "~6.4.1",
    "karma-chrome-launcher": "~3.1.1",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "lint-staged": "^13.2.2",
    "ng-extract-i18n-merge": "^2.12.0",
    "patch-package": "^8.0.0",
    "postinstall-postinstall": "^2.1.0",
    "prettier": "^3.4.2",
    "protractor": "~7.0.0",
    "ts-node": "^10.9.1",
    "typescript": "~5.4.5"
  },
  "packageManager": "yarn@1.22.22"
}
