import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { MeasurementIdResponse } from '../../../../generated/mms/model/measurementIdResponse';
import { ACCOUNT_USER_ACCESS_LEVELS } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import {
  AddTaskOrDesignDialogService,
  ModelOption
} from '../../../detailed-site/shared/add-task-or-design-dialog/add-task-or-design-dialog.service';
import { ENTITY_TYPE_NAMES } from '../../../detailed-site/site-map/map-tools/measure-tools/measurements-consts';
import { DetailedSiteQuery } from '../../../detailed-site/state/detailed-site.query';
import { entityTypeName, isNewEntity } from '../../../detailed-site/state/detailed-site.utils';
import {
  DEFAULT_GROUP_LAYER_ID,
  Group,
  LATEST_GROUP_SELECTED_STORAGE_KEY,
  LATEST_LAYER_SELECTED_STORAGE_KEY,
  Layer,
  MapEntity,
  MeasurementType,
  SourceModel
} from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.query';
import { DetailedSiteEntitiesService } from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.service';
import { DeviceService } from '../../services/device.service';
import { SnackBarService } from '../../services/snackbar.service';
import { isDefined } from '../../utils/general';
import { GroupLayerDetailsService } from '../group-layer-details/group-layer-details.service';
import { GroupsLayersManagerService } from '../groups-layers-manager/groups-layers-manager.service';

export enum InvalidateEntityReason {
  ON_MODEL_EDIT = 'onModelEdit',
  BASE_SURFACE_IS_NOT_CURRENT = 'baseSurfaceIsNotCurrent',
  NOT_ALL_ANALYTICS_DESIGNS_ARE_CURRENT = 'notAllAnalyticsDesignsAreCurrent'
}

export interface ModalTitleShownFeatures {
  groups?: boolean;
  layers?: boolean;
  exportDXF?: boolean;
  exportCSV?: boolean;
  exportReport?: boolean;
  syncSnapshot?: boolean;
  preview?: boolean;
  invalidateEntityReasons?: Set<InvalidateEntityReason>;
}

@UntilDestroy()
@Component({
  selector: 'map-modal-title',
  templateUrl: './map-modal-title.component.html',
  styleUrls: ['./map-modal-title.component.scss']
})
export class MapModalTitleComponent implements OnInit {
  protected readonly PERMISSIONS = PERMISSIONS;

  protected readonly downloadDxfActionBtnTitle = $localize`:@@detailedSite.actionButtons.downloadDxfBtnTitle:Download as DXF file`;
  protected readonly disabledDownloadDxfActionBtnTitle = $localize`:@@detailedSite.actionButtons.disabledDownloadDxfBtnTitle:No geo-position to export`;

  protected readonly addPositionTitle = $localize`:@@detailedSite.actionButtons.addPositionBtnTitle:Add geo-position on map`;
  protected readonly userIsNotCreatorToAddPositionMessage = $localize`:@@detailedSite.annotation.userIsNotCreatorToAddPositionMessage:Only the user who created the annotation can add geo-position`;
  protected readonly userIsNotCreatorToTogglePositionVizMessage = $localize`:@@detailedSite.detailsBox.annotationDetails.userIsNotCreatorToTogglePositionVizMessage:Only the user who created the annotation can toggle geo-position visibility`;
  protected readonly userIsNotCreatorToDeleteAnnotationMessage = $localize`:@@detailedSite.annotation.userIsNotCreatorToDeleteAnnotationMessage:Only the user who created the annotation can delete it`;
  protected readonly userIsNotCreatorToEditNameMessage = $localize`:@@detailedSite.annotation.userIsNotCreatorToEditNameMessage:Only the user who created the annotation can edit its name`;
  protected readonly userIsNotCreatorToEditLayerMessage = $localize`:@@detailedSite.annotation.userIsNotCreatorToEditLayerMessage:Only the user who created the annotation can change the layer`;
  protected readonly userIsNotCreatorToEditGroupMessage = $localize`:@@detailedSite.annotation.userIsNotCreatorToEditGroupMessage:Only the user who created the annotation can change the group`;

  @ViewChild('layersSelect') layersSelect: MatSelect;
  @ViewChild('groupsSelect') groupsSelect: MatSelect;

  @Output() deleteClick = new EventEmitter();
  @Output() exportDXFClick = new EventEmitter();
  @Output() reportClick = new EventEmitter();
  @Output() snapshotClick = new EventEmitter();
  @Output() exportCSVClick = new EventEmitter();
  @Output() zoomIntoClick = new EventEmitter();
  @Output() previewClick = new EventEmitter();
  @Output() addPositionClick = new EventEmitter();
  @Output() toggleGeoPositionVizClick = new EventEmitter();
  @Output() shareResource = new EventEmitter();
  @Output() invalidateEntity = new EventEmitter();

  @Input() editAccessLevel = ACCOUNT_USER_ACCESS_LEVELS[0];
  @Input() deleteAccessLevel = ACCOUNT_USER_ACCESS_LEVELS[0];
  @Input() isResultsLoading: boolean;
  @Input() isReducedWidthMode: boolean;
  @Input() entity$: Observable<MapEntity>;
  @Input() exportDisabled: boolean;
  @Input() invalidateEntityDisabled: boolean;
  @Input() previewDisabled: boolean;
  @Input() snapshotDisabled: boolean;
  @Input() shownFeatures: ModalTitleShownFeatures = {};

  entity: MapEntity = null;
  isNewEntity: boolean;
  groups$: Observable<Group[]>;
  layers$: Observable<Layer[]>;
  siteId: string;
  isOriginalTerrainChanged = false;

  isTablet = this.device.isTablet();

  get isMeasurement(): boolean {
    return this.entity.type in MeasurementType;
  }

  constructor(
    private managerService: GroupsLayersManagerService,
    private detailsService: GroupLayerDetailsService,
    private cd: ChangeDetectorRef,
    private siteQuery: DetailedSiteQuery,
    private siteEntitiesService: DetailedSiteEntitiesService,
    private siteEntitiesQuery: DetailedSiteEntitiesQuery,
    private snackbar: SnackBarService,
    private device: DeviceService,
    private addTaskOrDesignDialogService: AddTaskOrDesignDialogService
  ) {}

  ngOnInit() {
    this.siteId = this.siteQuery.getSiteId();

    this.entity$
      .pipe(
        untilDestroyed(this),
        filter(entity => !!entity)
      )
      .subscribe(entity => {
        this.entity = entity;
        this.isNewEntity = isNewEntity(entity?.id);
        this.cd.detectChanges();
      });

    this.groups$ = this.siteEntitiesQuery.groups$;
    this.layers$ = this.siteEntitiesQuery.layers$;

    // Fix issue with mat-select not updating disabled status via @Input changed with disableByAccess directive
    // Use setTimeout instead of AfterViewInit to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      if (this.layersSelect?._elementRef.nativeElement.disabled) {
        this.layersSelect.disabled = true;
      }
      if (this.groupsSelect?._elementRef.nativeElement.disabled) {
        this.groupsSelect.disabled = true;
      }

      if (this.layersSelect?.disabled || this.groupsSelect?.disabled) {
        this.cd.detectChanges();
      }
    }, 0);
  }

  createNew(type: 'group' | 'layer') {
    this.layersSelect.close();
    this.groupsSelect.close();

    this.detailsService.openDialog({
      type,
      onSave: (data: Group | Layer) => this.createNewGroupLayer({ type, data })
    });
  }

  manage(type: 'group' | 'layer') {
    this.layersSelect.close();
    this.groupsSelect.close();

    this.managerService.openDialog({
      type,
      values$: type === 'layer' ? this.layers$ : this.groups$,
      onCreate: () => this.createNew(type),
      onEdit: (data: Group | Layer) => this.edit(type, data),
      onDelete: (id: string) => this.delete(type, id)
    });
  }

  private delete(type: 'group' | 'layer', id: string) {
    this.deleteGroupLayer({ type, id });

    if (type === 'layer') {
      this.layerId = '';
    } else {
      this.groupId = '';
    }
  }

  private edit(type: 'group' | 'layer', values: Group | Layer) {
    this.detailsService.openDialog({
      values,
      type,
      onSave: data => this.editGroupLayer({ type, data })
    });
  }

  get sourceModel() {
    return this.entity.sourceModel;
  }

  get sourceModelName() {
    switch (this.sourceModel) {
      case SourceModel.DSM:
        return 'Surface model';

      case SourceModel.DTM:
        return 'Terrain model';

      case SourceModel.FLAT:
      default:
        return '2D model';
    }
  }

  get sourceModelHintText() {
    switch (this.sourceModel) {
      case SourceModel.DSM:
        return (
          'Measured on a surface model (unfiltered) which provides high details, ' +
          'revealing some subtle topographical features such as vegetation, structures, constructed objects and machines'
        );

      case SourceModel.DTM:
        return (
          'Measured on a terrain model (filtered) where elements such as vegetation, ' +
          'structures, constructed objects and machines were filtered out'
        );

      case SourceModel.FLAT:
      default:
        return 'Measured on a flat 2D model without elevation data';
    }
  }

  get layerColor() {
    return this.siteEntitiesQuery.getLayerColor(this.layerId);
  }

  get layerId() {
    return (this.entity && this.entity.layerId) || DEFAULT_GROUP_LAYER_ID;
  }

  set layerId(layerId: string) {
    this.siteEntitiesService.updateEntity(this.entity.id, { type: this.entity?.type, layerId: layerId || null });
    localStorage.setItem(LATEST_LAYER_SELECTED_STORAGE_KEY, layerId || DEFAULT_GROUP_LAYER_ID);
  }

  get groupId() {
    return (this.entity && this.entity.groupId) || DEFAULT_GROUP_LAYER_ID;
  }

  set groupId(groupId: string) {
    this.siteEntitiesService.updateEntity(this.entity.id, { type: this.entity?.type, groupId: groupId || null });
    localStorage.setItem(LATEST_GROUP_SELECTED_STORAGE_KEY, groupId || DEFAULT_GROUP_LAYER_ID);
  }

  get title() {
    return this.entity?.name ?? '';
  }

  set title(title: string) {
    this.siteEntitiesService.updateEntity(this.entity?.id, { type: this.entity?.type, name: title || '' });
  }

  get layersTooltip() {
    const selected = this.layersSelect?.selected as MatOption;
    return selected?.viewValue;
  }

  get groupsTooltip() {
    const selected = this.groupsSelect?.selected as MatOption;
    return selected?.viewValue;
  }

  get invalidateTooltip() {
    const entityTypeName = this.entityTypeName;
    // Show the tooltip for one reason only. Reasons above are sorted by priority.
    if (this.shownFeatures.invalidateEntityReasons.has(InvalidateEntityReason.NOT_ALL_ANALYTICS_DESIGNS_ARE_CURRENT)) {
      return $localize`:@@detailedSite.detailesBox.mapModalTitle.notAllUsedDesignsAreCurrent:This ${entityTypeName} was calculated based on designs not from the current version, click here to recalculate`;
    } else if (this.shownFeatures.invalidateEntityReasons.has(InvalidateEntityReason.BASE_SURFACE_IS_NOT_CURRENT)) {
      return $localize`:@@detailedSite.detailesBox.mapModalTitle.usedBaseSurfaceDesignVersionIsNotCurrent:This ${entityTypeName} was calculated based on design base surface not from the current version, click here to recalculate`;
    } else if (this.shownFeatures.invalidateEntityReasons.has(InvalidateEntityReason.ON_MODEL_EDIT)) {
      return $localize`:@@detailedSite.detailesBox.mapModalTitle.entityOnModelEdit:This ${entityTypeName} is on a model edit which may have affected it's calculations, click here to recalculate`;
    }
  }

  layersSelectOpen() {
    const selected = this.layersSelect?.selected as MatOption;
    selected?.focus();
  }

  groupsSelectOpen() {
    const selected = this.groupsSelect?.selected as MatOption;
    selected?.focus();
  }

  createNewGroupLayer({ type, data }: { type: 'group' | 'layer'; data: Group | Layer }) {
    if (type === 'layer') {
      this.siteEntitiesService.createNewLayer(this.siteId, data).subscribe({
        next: (response: MeasurementIdResponse) => (this.layerId = response.id),
        error: response => {
          this.snackbar.openError('Error creating layer', response);
        }
      });
    } else {
      this.siteEntitiesService.createNewGroup(this.siteId, data).subscribe({
        next: (response: MeasurementIdResponse) => (this.groupId = response.id),
        error: response => {
          this.snackbar.openError('Error creating group', response);
        }
      });
    }
  }

  editGroupLayer({ type, data }: { type: 'group' | 'layer'; data: Group | Layer }) {
    if (type === 'layer') {
      this.siteEntitiesService.updateLayer(this.siteId, data).subscribe({
        error: response => {
          this.snackbar.openError('Error updating layer', response);
        }
      });
    } else {
      this.siteEntitiesService.updateGroup(this.siteId, data).subscribe({
        error: response => {
          this.snackbar.openError('Error updating group', response);
        }
      });
    }
  }

  deleteGroupLayer({ type, id }: { type: 'group' | 'layer'; id: string }) {
    if (type === 'layer') {
      this.siteEntitiesService.deleteLayer(this.siteId, id).subscribe({
        error: response => {
          this.snackbar.openError('Error deleting layer', response);
        }
      });
    } else {
      this.siteEntitiesService.deleteGroup(this.siteId, id).subscribe({
        error: response => {
          this.snackbar.openError('Error deleting group', response);
        }
      });
    }
  }

  get subtitle() {
    return this.entity && ENTITY_TYPE_NAMES[this.entity.type];
  }

  get entityTypeName() {
    return entityTypeName(this.entity).toLowerCase();
  }

  previewModelEdit() {
    this.isOriginalTerrainChanged = true;
    this.previewClick.emit();
  }

  getToggleGeoPositionVizText(isHiddenPosition: boolean) {
    return isHiddenPosition
      ? $localize`:@@detailedSite.actionButtons.toggleAnnotationPositionVizTextHide:Show geo-position`
      : $localize`:@@detailedSite.actionButtons.toggleAnnotationPositionVizTextShow:Hide geo-position`;
  }

  get syncSnapshotTitleText() {
    let title = $localize`:@@detailedSite.actionButtons.syncSnapshot:Sync Snapshot`;

    if (this.snapshotDisabled) {
      title +=
        '\n\n' +
        $localize`:@@detailedSite.annotation.automaticSnapshotAfterSave:A snapshot will be added automatically after the first save`;
    }

    return title;
  }

  onCloneMeasurement() {
    const dialogRef = this.addTaskOrDesignDialogService.open({
      dialogHeader: $localize`:@@detailedSite.addTaskOrDesignDialog.dialogHeaderSelectFlights:Select Flights`,
      availableModelOptions: this.siteQuery.getAllAvailableTaskOptions(true, false),
      showOnlyFlights: true
    });

    const name = this.entity.name;
    dialogRef
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        switchMap(taskOptions => {
          if (!isDefined(taskOptions)) {
            return of(null);
          }
          const tasks = taskOptions.map((taskOption: ModelOption) => this.siteQuery.getTask(taskOption.id));
          return this.siteEntitiesService.cloneMeasurements(this.entity, tasks);
        })
      )
      .subscribe({
        next: success => {
          if (success) {
            this.snackbar.open($localize`:@@detailedSite.addTaskOrDesignDialog.cloneSuccessful:Successfully cloned measurement "${name}"`);
          }
        },
        error: error => {
          this.snackbar.openError(
            $localize`:@@detailedSite.addTaskOrDesignDialog.errorCloningMeasurements:Error cloning measurement "${name}"`,
            `Error cloning measurement`,
            error
          );
        }
      });
  }
}
