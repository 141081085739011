import { Observable } from 'rxjs';

import { GetAllImagesResponse } from '../../../generated/file/model/getAllImagesResponse';
import { GetImageResponse } from '../../../generated/file/model/getImageResponse';
import { FlightAreaPolygonModel } from '../../../generated/fms/model/flightAreaPolygonModel';
import { TaskResponse } from '../../../generated/fms/model/taskResponse';
import { ShutterTypeEnum, Task, TaskStateEnum } from '../../detailed-site/state/detailed-site.model';
import { ImageExifData } from '../../shared/services/exif.service';
import { Cartographic } from '../../shared/utils/geo';
import { Site } from '../../tenant/tenant.model';
import { Step } from '../wizard-stepper/steps';

export enum UploadWizardMode {
  CREATE,
  APPROVE,
  EDIT
}

export import GeorefMethodEnum = TaskResponse.GeorefMethodEnum;
export const AUTOMODEL_GEOREF_METHODS = [GeorefMethodEnum.GEOTAG, GeorefMethodEnum.AUTOGEOREF, GeorefMethodEnum.RTK];

export const IMAGES_BACKUP_NAME = 'flight-images-backup';
export const IMAGE_RESOLUTION_BASELINE_MPX = 21;
export const DEFAULT_IMAGE_MAX_RESOLUTION_PX = 50_000_000;

export const ROLLING_SHUTTER_FLIGHT_SPEED_THRESHOLD_M_PER_SEC = 6;
export const ROLLING_SHUTTER_HINT_ELECTRONIC = $localize`:@@uploadWizard.flightInfo.rollingShutterHintElectronic:Drone's camera has a rolling shutter`;
export const ROLLING_SHUTTER_HINT_MECHANICAL = $localize`:@@uploadWizard.flightInfo.rollingShutterHintMechanical:Drone's camera has a mechanical shutter`;
export const ROLLING_SHUTTER_HINT_HIGH_SPEED = $localize`:@@uploadWizard.flightInfo.rollingShutterHintHighSpeed:Enabled due to drone's high flight speed (even if drone has mechanical shutter)`;

export import TotalValidationWarning = TaskResponse.WarningsEnum;
export import PerImagesValidationWarning = GetImageResponse.WarningsEnum;
export import RTKStatus = GetImageResponse.RtkStatusEnum;

export enum ValidationError {
  NOEXIFDATA = 'NO_EXIF_DATA',
  INVALIDRESOLUTION = 'INVALID_RESOLUTION',
  LARGERESOLUTION = 'LARGE_RESOLUTION',
  DIFFERENTRESOLUTIONS = 'DIFFERENTRESOLUTIONS',
  NOLOCATION = 'NO_LOCATION',
  DIFFERENTMODELS = 'DIFFERENT_MODELS',
  EXCEEDEDMAXGPX = 'EXCEEDED_MAX_GPX'
}

export interface FileToUpload {
  name: string;
  file: File;
  exif?: ImageExifData;
  errors?: ValidationError[];
  warnings?: PerImagesValidationWarning[];
  rtkStatus: RTKStatus;
  nearestNoncoverageAreaCenter?: { longitude: number; latitude: number };
  originalName?: string;
}

export interface ImageToApprove extends GetImageResponse {
  nearestNoncoverageAreaCenter?: { longitude: number; latitude: number };
}

export import FlightAreaPolygonType = FlightAreaPolygonModel.TypeEnum;
export interface FlightAreaPolygon {
  positions: Cartographic[];
  type: FlightAreaPolygonType;
}

export interface ImagePoint {
  name: string;
  longitude: number;
  latitude: number;
  warnings?: PerImagesValidationWarning[];
  errors?: ValidationError[];
}

export interface ValidationLimits {
  lowerDistanceMeter: number;
  upperDistanceMeter: number;
  absHeightDeltaMeter: number;
  neighboursTimeDeltaHour: number;
  totalTimeHour: number;
  maxResolutionPx: number;
  maxTotalResolutionGpx: number;
}

export interface MapHoveredNames {
  image: string;
  issue: string;
  noncoverageWarning: string;
}

export interface UploadWizardDialogData {
  step: Step;
  mode: UploadWizardMode;
  site: Site;
  taskIndex: number;
  taskId: string;
  taskState?: TaskStateEnum;
  prevTaskHadGCP?: boolean;
  prevTaskId?: string;
  isFromBackup?: boolean;
  callbacks?: UploadWizardCallbacks;
}

export interface UploadWizardCallbacks {
  updateSite?: (site: Site) => void;
  addTask?: (task: Task) => void;
  updateTask?: (task: Partial<Task>) => void;
  selectTask?: (taskId: string) => Observable<Task>;
  fetchTaskImages?: (taskId: string, isLinked: boolean) => Observable<GetAllImagesResponse>;
}

// There are two types for UNKNOWN that we handle the same:
// - both: the camera model can work with or without RTK, so we can't be sure.
// - none: it's not mechanical or electronic.
export const CAMERA_MODEL_SHUTTER_TYPES = Object.freeze({
  'Yuneec E90': ShutterTypeEnum.ELECTRONIC,
  'WINGTRA RGB61': ShutterTypeEnum.MECHANICAL,
  'SONY UMC-R10C': ShutterTypeEnum.MECHANICAL,
  'SONY ILCE-7RM2': ShutterTypeEnum.ELECTRONIC,
  'SONY DSC-RX1RM2': ShutterTypeEnum.UNKNOWN, // both
  'SONY ILCE-5100': ShutterTypeEnum.UNKNOWN, // none
  'SONY ILCE-6000': ShutterTypeEnum.UNKNOWN, // both
  'ShareUAV SHARE202SPROV2304RIGHT': ShutterTypeEnum.MECHANICAL,
  'ShareUAV SHARE202SPROV2304DOWN': ShutterTypeEnum.MECHANICAL,
  'Percepto Blackfly-s': ShutterTypeEnum.MECHANICAL,
  'Parrot Anafi': ShutterTypeEnum.UNKNOWN, // both
  'Hasselblad L2D-20c': ShutterTypeEnum.ELECTRONIC,
  'Hasselblad L1D-20c': ShutterTypeEnum.ELECTRONIC,
  'Hasselblad A6D-100c': ShutterTypeEnum.MECHANICAL,
  'FUJIFILM X-M1': ShutterTypeEnum.MECHANICAL,
  'DJI ZH20T': ShutterTypeEnum.ELECTRONIC,
  'DJI ZenmuseP1': ShutterTypeEnum.MECHANICAL,
  'DJI M3E': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC7303': ShutterTypeEnum.ELECTRONIC,
  'DJI FC6540': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC6520': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC6510': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC6310S': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC6310R': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC6310': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC550': ShutterTypeEnum.ELECTRONIC,
  'DJI FC3682': ShutterTypeEnum.ELECTRONIC,
  'DJI FC3582': ShutterTypeEnum.ELECTRONIC,
  'DJI FC3411': ShutterTypeEnum.ELECTRONIC,
  'DJI FC330': ShutterTypeEnum.UNKNOWN, // none
  'DJI FC3170': ShutterTypeEnum.ELECTRONIC,
  'DJI FC300X': ShutterTypeEnum.UNKNOWN, // none
  'DJI FC300S': ShutterTypeEnum.UNKNOWN, // none
  'DJI FC2403': ShutterTypeEnum.UNKNOWN, // both
  'DJI FC2204': ShutterTypeEnum.UNKNOWN, // none
  'DJI FC220': ShutterTypeEnum.UNKNOWN, // none
  'DJI EP800': ShutterTypeEnum.UNKNOWN, // none
  'DJI FC350': ShutterTypeEnum.UNKNOWN, // none
  'DJI L2': ShutterTypeEnum.UNKNOWN, // both
  'DJI M3T': ShutterTypeEnum.MECHANICAL,
  'Autel Robotics XT705': ShutterTypeEnum.ELECTRONIC,
  'Autel Robotics XL705': ShutterTypeEnum.ELECTRONIC,
  'Canon Canon EOS 5DS R': ShutterTypeEnum.UNKNOWN, // none
  'Xacti C89RA': ShutterTypeEnum.UNKNOWN // none
});
