import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { isDefined } from '../utils/general';

export interface ConfirmationDialogData {
  title: string;
  /** Can be an HTML string. <b> will be marked red and <i> as warning. */
  content?: string;
  noCaption?: string;
  yesCaption?: string;
  alternativeButtonCaption?: string;
  disableClose?: boolean;
  beforeClosedValidation?: (result: ConfirmationDialogResultEnum) => void;
  beforeClosedValidationDialogResult?: ConfirmationDialogResultEnum;
  confirmationPhrase?: string;
}

export enum ConfirmationDialogResultEnum {
  NO,
  YES,
  ALTERNATIVE
}

@UntilDestroy()
@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  ConfirmationDialogResultEnum = ConfirmationDialogResultEnum;

  loading = false;
  confirmationPhraseControl: FormControl<string> = new FormControl('');
  confirmationPhraseValid: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private cd: ChangeDetectorRef
  ) {
    if (!this.data.disableClose) {
      this.dialogRef.backdropClick().subscribe(() => this.close());
      this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          this.close();
        }
      });
    }
  }

  ngOnInit() {
    if (this.data?.confirmationPhrase) {
      this.confirmationPhraseControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
        this.confirmationPhraseValid = value === this.data.confirmationPhrase;
      });
    } else {
      this.confirmationPhraseValid = true;
    }
  }

  close(event?: MouseEvent, result: ConfirmationDialogResultEnum = ConfirmationDialogResultEnum.NO) {
    if (isDefined(this.data.beforeClosedValidation)) {
      if (result === this.beforeClosedValidationDialogResult) {
        this.loading = true;
        const buttonEl = event.currentTarget as HTMLElement;
        if (buttonEl) {
          // Keep original button width
          const width = buttonEl.getBoundingClientRect().width;
          buttonEl.style.width = width + 'px';
          buttonEl.style.minWidth = width + 'px';
        }
        this.cd.detectChanges();
      }
      this.data.beforeClosedValidation(result);
    } else {
      this.dialogRef.close(result);
    }
  }

  get beforeClosedValidationDialogResult() {
    return this.data?.beforeClosedValidationDialogResult ?? ConfirmationDialogResultEnum.YES;
  }
}
