import { AcMapComponent, PolygonEditorObservable, PolylineEditorObservable, SceneMode } from '@datumate/angular-cesium';
import { Cartesian3, Cesium3DTileset, Scene, SkyBox, TileProviderError, Viewer } from 'cesium';

import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../services/analytics.service';
import { isDefined } from './general';

export type PolygonPolylineEditorObservable = PolylineEditorObservable & PolygonEditorObservable;

export const DEFAULT_VIEWER_OPTIONS = Object.freeze({
  selectionIndicator: false,
  timeline: false,
  infoBox: false,
  fullscreenButton: false,
  baseLayerPicker: false,
  animation: false,
  homeButton: false,
  geocoder: false,
  navigationHelpButton: false,
  sceneModePicker: false,
  navigationInstructionsInitiallyVisible: false,
  imageryProvider: false,
  sceneMode: SceneMode.SCENE2D,
  creditContainer: document.createElement('div'),
  // If true, this widget will automatically display an HTML panel to the user containing the error, if a render loop error occurs.
  showRenderLoopErrors: !environment.production,
  contextOptions: {
    // If this property is true, the error is rethrown after the event is raised.
    // If this property is false, the render function returns normally after raising the event.
    rethrowRenderErrors: true
  }
});

const MAPTILER_CREDITS = `
  <a href="https://www.maptiler.com/copyright/" target="_blank" title="MapTiler" aria-label="MapTiler" role="listitem">
    &copy; MapTiler
  </a>
  <a href="https://www.openstreetmap.org/copyright" target="_blank" title="OpenStreetMap contributors" aria-label="OpenStreetMap contributors" role="listitem">
    &copy; OpenStreetMap contributors
  </a>
`;

const MAPBOX_CREDITS = `
  <a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox" aria-label="Mapbox" role="listitem">
    &copy; Mapbox
  </a>
  <a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap" aria-label="OpenStreetMap" role="listitem">
    &copy; OpenStreetMap
  </a>
  <a class="mapbox-improve-map" href="https://www.mapbox.com/contribute/" target="_blank" title="Improve this map" aria-label="Improve this map" role="listitem">
    Improve this map
  </a>
`;

const MAPBOX_SATELLITE_CREDITS = `
  ${MAPBOX_CREDITS}
  <a href="https://www.maxar.com/" target="_blank" title="Maxar" aria-label="Maxar" role="listitem">
    &copy; Maxar
  </a>
`;

export const STREET_MAP_IMAGERY_INDEX = 0;
export const SATELLITE_MAP_IMAGERY_INDEX = 1;

export const BLANK_BLACK_MAP_IMAGERY_INDEX = -2;
export const BLANK_WHITE_MAP_IMAGERY_INDEX = -3;

export enum MapStyle {
  STREET = STREET_MAP_IMAGERY_INDEX,
  SATELLITE = SATELLITE_MAP_IMAGERY_INDEX,
  BLANK_BLACK = BLANK_BLACK_MAP_IMAGERY_INDEX,
  BLANK_WHITE = BLANK_WHITE_MAP_IMAGERY_INDEX
}

export const DEFAULT_MAX_DETAIL_LEVEL = 22;
export const DEFAULT_IMAGERY_TILE_SIZE = 512;

export const DEFAULT_IMAGERY_TILE_EXTENSION = 'png';

export const DEFAULT_MAP_COLOR = '#ece0ca';
export const WHITE_MAP_COLOR = '#FFFFFF';
export const BLACK_MAP_COLOR = '#000000';
export const TRANSPARENT_MAP_COLOR = '#00000000';

const WHITE_SKYBOX_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';
const BLACK_SKYBOX_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=';

export const getBlankSkyBox = (blankImage): SkyBox => {
  const planes = ['positiveX', 'positiveY', 'positiveZ', 'negativeX', 'negativeY', 'negativeZ'];
  const sources = planes.reduce((result, p) => {
    return { ...result, [p]: blankImage };
  }, {});
  return new Cesium.SkyBox({ sources });
};

export const BLACK_SKYBOX: SkyBox = getBlankSkyBox(BLACK_SKYBOX_IMAGE);
export const WHITE_SKYBOX: SkyBox = getBlankSkyBox(WHITE_SKYBOX_IMAGE);

export const getSkyBoxByMapStyle = (mapStyle: MapStyle): SkyBox => {
  switch (mapStyle) {
    case MapStyle.BLANK_BLACK:
      return BLACK_SKYBOX;
    case MapStyle.BLANK_WHITE:
      return WHITE_SKYBOX;
    default:
      return null;
  }
};

export const COLORS_BY_MAP_STYLE = {};

export const TILES_3D_DEFAULT_PROPERTIES = {
  maximumScreenSpaceError: 4,
  maximumMemoryUsage: 1024
};

COLORS_BY_MAP_STYLE[MapStyle.BLANK_BLACK] = Cesium.Color.TRANSPARENT;
COLORS_BY_MAP_STYLE[MapStyle.BLANK_WHITE] = Cesium.Color.TRANSPARENT;
COLORS_BY_MAP_STYLE[MapStyle.STREET] = Cesium.Color.fromCssColorString(DEFAULT_MAP_COLOR);
COLORS_BY_MAP_STYLE[MapStyle.SATELLITE] = Cesium.Color.fromCssColorString(DEFAULT_MAP_COLOR);

export const setCommonViewerSettings = (viewer: Viewer, mapId: string) => {
  // Improve raster image quality - https://github.com/CesiumGS/cesium/issues/3279#issuecomment-271481280
  viewer.scene.globe.maximumScreenSpaceError = 1.33;

  // Don't show entities behind terrain (unless specifically defined in the entity itself)
  viewer.scene.globe.depthTestAgainstTerrain = true;

  // Opacity will be corrected by alpha value
  viewer.scene.globe.translucency.enabled = true;

  // Allow camera to go under terrain
  viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;

  viewer.scene.screenSpaceCameraController.zoomEventTypes = [Cesium.CameraEventType.WHEEL, Cesium.CameraEventType.PINCH];
  // viewer.resolutionScale = window.devicePixelRatio || 1; // Performance  impact
  viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  viewer.scene.moon.destroy();
  viewer.scene.sun.destroy();

  // Prepare credits container
  viewer.bottomContainer.classList.add('cesium-credits-container');
  viewer.bottomContainer.setAttribute('role', 'list');

  if (environment.production) {
    viewer.scene.renderError.addEventListener((scene: Scene, error: Error) => {
      viewer.cesiumWidget.showErrorPanel(
        $localize`:@@shared.mapErrorPanel.title:The process has been interrupted`,
        $localize`:@@shared.mapErrorPanel.errorReasons:This could have happened because of one or more of: low available memory, high CPU or graphics card usage.` +
          `<br/><br/>` +
          $localize`:@@shared.mapErrorPanel.errorRemedy:In order to proceed, please reload this page and repeat your actions.`
      );

      AnalyticsService.trackGeneralEvent('Failed Map Rendereing', {
        'Error Message': error.message,
        'Error Name': error.name,
        'Map ID': mapId
      });

      console.error('Render error', error);
    });
  } else {
    viewer.scene.canvas.addEventListener('webglcontextlost', function (event) {
      console.error('WebGL context lost at:', new Date(), event);
      event.preventDefault(); // Prevent the browser from trying to restore the context automatically
    });

    viewer.scene.canvas.addEventListener('webglcontextrestored', function () {
      console.log('WebGL context restored successfully');
    });

    // Show terrain tiles inspector -- DEBUG ONLY
    // viewer.extend(Cesium.viewerCesiumInspectorMixin);
    // Add 3D tiles inspector (for debug)
    // viewer.extend(Cesium.viewerCesium3DTilesInspectorMixin);
  }

  addBaseImageryLayer({
    viewer,
    url: `https://maps.datumate.com/maps/${environment.mapTiler.streetMapStyleId}/{z}/{x}/{y}.png?key=${environment.mapTiler.key}`,
    creditsInnerHtml: MAPTILER_CREDITS,
    fallbackUrl: `https://api.mapbox.com/styles/v1/${environment.mapbox.username}/${environment.mapbox.streetMapStyleId}/tiles/${DEFAULT_IMAGERY_TILE_SIZE}/{z}/{x}/{y}?access_token=${environment.mapbox.key}`,
    fallbackCreditsInnerHtml: MAPBOX_CREDITS,
    defaultShow: true,
    index: MapStyle.STREET
  });

  addBaseImageryLayer({
    viewer,
    url: `https://maps.datumate.com/maps/${environment.mapTiler.satelliteMapStyleId}/{z}/{x}/{y}.jpg?key=${environment.mapTiler.key}`,
    creditsInnerHtml: MAPTILER_CREDITS,
    fallbackUrl: `https://api.mapbox.com/styles/v1/${environment.mapbox.username}/${environment.mapbox.satelliteMapStyleId}/tiles/${DEFAULT_IMAGERY_TILE_SIZE}/{z}/{x}/{y}?access_token=${environment.mapbox.key}`,
    fallbackCreditsInnerHtml: MAPBOX_SATELLITE_CREDITS,
    defaultShow: false,
    index: MapStyle.SATELLITE
  });

  viewer.scene.globe.baseColor = Cesium.Color.fromCssColorString(DEFAULT_MAP_COLOR);
};

function addBaseImageryLayer({
  viewer,
  url,
  creditsInnerHtml,
  fallbackUrl,
  fallbackCreditsInnerHtml,
  defaultShow,
  index
}: {
  viewer: Viewer;
  url: string;
  creditsInnerHtml: string;
  fallbackUrl?: string;
  fallbackCreditsInnerHtml?: string;
  defaultShow: boolean;
  index: number;
}) {
  const imageryProvider = new Cesium.UrlTemplateImageryProvider({
    url,
    tileWidth: 512,
    tileHeight: 512,
    maximumLevel: DEFAULT_MAX_DETAIL_LEVEL
  });

  if (fallbackUrl) {
    const errorEventRemoveCallback = imageryProvider.errorEvent.addEventListener((error: TileProviderError) => {
      const msg = 'Background Map Failed To Load';
      console.error(msg, url, error);
      AnalyticsService.trackGeneralEvent(msg, { 'Provider URL': url, 'Error Message': error.message });

      if (imageryLayer.isDestroyed()) {
        return;
      }

      const layerShown = imageryLayer.show;
      viewer.imageryLayers.remove(imageryLayer);
      imageryLayer.destroy();

      const imageryFallbackProvider = new Cesium.UrlTemplateImageryProvider({
        url: fallbackUrl,
        tileWidth: 512,
        tileHeight: 512,
        maximumLevel: DEFAULT_MAX_DETAIL_LEVEL
      });

      const imageryLayerFallback = viewer.imageryLayers.addImageryProvider(imageryFallbackProvider, index);
      imageryLayerFallback.show = layerShown;

      viewer.bottomContainer.innerHTML = fallbackCreditsInnerHtml;

      errorEventRemoveCallback();
    });
  }

  const imageryLayer = viewer.imageryLayers.addImageryProvider(imageryProvider, index);
  imageryLayer.show = defaultShow;

  viewer.bottomContainer.innerHTML = creditsInnerHtml;
}

export function applyOffsetTo3DTile(tileset: Cesium3DTileset, heightOffset: number) {
  if (!heightOffset) {
    return;
  }

  const boundingSphere = tileset.boundingSphere;
  const cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center);
  const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
  const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, -heightOffset);
  const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
  tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
}

export function screenToPositionSimple(map: AcMapComponent, screenPoint: { x: number; y: number }, sample3DTiles = false) {
  const screenPosition = map.getCoordinateConverter().screenToCartesian3(screenPoint);
  if (!screenPosition) {
    return null;
  }

  const cesiumService = map.getCesiumService();
  const scene = cesiumService.getScene();

  let position: Cartesian3;
  if (sample3DTiles) {
    const pickedPosition = scene.pickPosition(screenPoint);
    position = pickedPosition && scene.clampToHeight(pickedPosition);
  }

  // Pick position by ray
  if (!position) {
    const ray = cesiumService.getViewer().camera.getPickRay(screenPoint, new Cesium.Ray());
    position = scene.globe.pick(ray, scene);
  }

  return position;
}

export const cesiumColor = (color: string, alpha = 1) =>
  !isDefined(color) || color === 'transparent' ? Cesium.Color.TRANSPARENT : Cesium.Color.fromCssColorString(color).withAlpha(alpha);

export function getViewerZoomLevel(viewer: Viewer) {
  const imageryLayer = viewer.imageryLayers.get(viewer.imageryLayers.length - 1);
  const imageryProvider = imageryLayer.imageryProvider;
  const tilingScheme = imageryProvider.tilingScheme;
  const ellipsoid = tilingScheme.ellipsoid;
  const levelZeroTileSize = imageryProvider.tileWidth;
  const circumference = 2 * Math.PI * ellipsoid.maximumRadius;
  const levelZeroResolution = circumference / levelZeroTileSize;
  const cameraHeight = viewer.camera.positionCartographic.height;
  const cameraResolution = cameraHeight / viewer.scene.canvas.clientHeight;
  let level = 0;
  let resolution = levelZeroResolution;
  while (cameraResolution < resolution) {
    level++;
    resolution /= 2;
  }
  return level;
}
