import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CoordinateSystemDialogData, CoordinateSystemModifierComponent } from './coordinate-system-modifier.component';

@Injectable({
  providedIn: 'root'
})
export class CoordinateSystemModifierService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: CoordinateSystemDialogData) {
    return this.dialog.open<CoordinateSystemModifierComponent, CoordinateSystemDialogData>(CoordinateSystemModifierComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '450px',
      maxHeight: '80%',
      disableClose: true,
      data
    });
  }
}
