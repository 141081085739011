import { Injectable } from '@angular/core';

import { PolygonCalcService } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { CalcModelOption } from '../../state/detailed-site.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { ActivityMeasurement, ActivityMeasurementValues } from '../../state/detailed-site-activities/detailed-site-activities.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityAreaCalcService extends PolygonCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    private siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling);
  }

  async calcResults(
    measurement: ActivityMeasurement,
    siteId: string,
    calcModelOption: CalcModelOption
  ): Promise<{ calcResult: ActivityMeasurementValues }> {
    const modelTerrain = await this.siteService.getTerrainProvider(
      siteId,
      calcModelOption.id,
      calcModelOption.type,
      measurement.sourceModelType
    );
    const surfaceArea =
      modelTerrain.type === 'FLAT'
        ? this.calcHorizontalArea(measurement.positions)
        : await this.calcSurfaceArea(measurement.positions, modelTerrain);

    return {
      calcResult: {
        values: { surfaceArea }
      }
    };
  }
}
