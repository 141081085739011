import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'toggle-button-group',
  template: `
    <div class="full-height container" [class.disabled]="disabled">
      <ng-content />
    </div>
  `,
  styleUrls: ['./toggle-button-group.component.scss']
})
export class ToggleButtonGroupComponent {
  @Output() valueChange = new EventEmitter();
  @Input() disabled: boolean;
  @Input() value: any;
}
