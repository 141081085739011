import { Injectable } from '@angular/core';

import { Cartographic } from '../../../shared/utils/geo';
import { PolygonWithSamplesCalcService } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { CalcModelOption } from '../../state/detailed-site.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';
import { CalcModelValues, MapEntity } from '../../state/detailed-site-entities/detailed-site-entities.model';

@Injectable({
  providedIn: 'root'
})
export class ModelEditCalcService extends PolygonWithSamplesCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    protected siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling, siteService);
  }

  async calcResults(entity: MapEntity, siteId: string, modelOptions: CalcModelOption[]): Promise<{ calcResult: CalcModelValues[] }> {
    const { horizontalArea, pointGridFeatureCollection } = this.precalc(entity?.positions);
    let calcResult: CalcModelValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelOptions.length; i++) {
      const modelOption = modelOptions[i];
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelOption.id, modelOption.type, entity.sourceModel);
      const modelSamplePointsCartographic = modelTerrain
        ? await this.createSamplePointsCartographic(pointGridFeatureCollection, modelTerrain)
        : null;

      let elevationMin = Number.MAX_SAFE_INTEGER;
      let elevationMax = Number.MIN_SAFE_INTEGER;
      const pointsCount = modelSamplePointsCartographic.length;

      const updateElevationMin = (a: Cartographic[], index: number) => {
        if (a[index].height < elevationMin) {
          elevationMin = a[index].height;
        }
      };

      const updateElevationMax = (a: Cartographic[], index: number) => {
        if (a[index].height > elevationMax) {
          elevationMax = a[index].height;
        }
      };

      const actionsList = [updateElevationMin, updateElevationMax];

      for (let i = 0; i < pointsCount; i++) {
        actionsList.forEach(f => f(modelSamplePointsCartographic, i));
      }

      calcResult = [
        ...calcResult.filter(res => res.id !== modelOption.id || res.type !== modelOption.type),
        {
          id: modelOption.id,
          type: modelOption.type,
          values: [
            { ...FIELD_MAPPING.horizontalArea, value: horizontalArea },
            { ...FIELD_MAPPING.elevationMin, value: elevationMin },
            { ...FIELD_MAPPING.elevationMax, value: elevationMax }
          ]
        }
      ];
    }

    return { calcResult };
  }
}
