import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthQuery } from '../../auth/state/auth.query';
import { AccessLevelEnum } from '../../auth/state/auth.utils';

export type ButtonType = 'BUTTON' | 'TOGGLE' | 'MENU';

@Component({
  selector: 'map-tool-btn',
  template: `
    <div class="button-container" (clickOutside)="type === 'MENU' ? clickOutsideMenu() : undefined">
      <button
        mat-button
        class="map-button row"
        [disabled]="disabled || !hasAccessLevel"
        (click)="mainBtnClick()"
        [class.selected]="selected"
        [class.small]="!label"
        [class.menu]="type === 'MENU'"
        [class.with-dropdown-icon]="type === 'MENU' && showMenuDropdownIcon"
      >
        <div
          class="row space-between"
          [class.full-container]="label"
          [matTooltip]="
            tooltip +
            (!hasAccessLevel
              ? '

          You do not have sufficient permissions to use this action'
              : '')
          "
          matTooltipClass="map-tool-btn-tooltip"
          [matTooltipPosition]="tooltipPosition"
        >
          <div class="row justify-center">
            <mat-icon *ngIf="icon" class="tool-icon" [svgIcon]="icon" />
            <span class="tool-label" *ngIf="label">{{ label }}</span>
          </div>
          <mat-icon *ngIf="type === 'MENU' && showMenuDropdownIcon" [ngStyle]="{ transform: selected ? 'rotate(180deg)' : undefined }">
            arrow_drop_down
          </mat-icon>
        </div>
      </button>

      <ng-content />
    </div>
  `,
  styleUrls: ['./map-tool-btn.component.scss']
})
export class MapToolBtnComponent implements OnInit {
  @Input() icon: string;
  @Input() label: string;

  @Input() inLine: boolean;
  @Input() tooltip = '';
  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' = 'left';
  @Input() selected = false;
  @Input() showMenuDropdownIcon = true;
  @Input() type: ButtonType = 'BUTTON';
  @Input() disabled: boolean;
  @Input() accessLevel: AccessLevelEnum;
  @Input() isMenuOpen = false;

  @Output() buttonClick = new EventEmitter<boolean>();

  constructor(private authQuery: AuthQuery) {}

  ngOnInit() {}

  mainBtnClick() {
    if (this.type !== 'BUTTON') {
      this.selected = !this.selected;
    }
    this.buttonClick.emit(this.selected);
  }

  clickOutsideMenu() {
    if (this.selected && !this.isMenuOpen) {
      this.selected = false;
      this.buttonClick.emit(this.selected);
    }
  }

  get hasAccessLevel() {
    return !this.accessLevel || this.authQuery.hasAccessLevel(this.accessLevel);
  }
}
