import { Injectable } from '@angular/core';
import { Cartesian3 } from '@datumate/angular-cesium';
import { Feature, Point } from 'geojson';

import { isDefined } from '../../../shared/utils/general';
import { GeoUtils } from '../../../shared/utils/geo';
import { PolylineCalcService, PrecalcData } from '../../services/calc-services';
import { Terrain } from '../../services/terrain-provider.service';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { CalcModelOption } from '../../state/detailed-site.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { ActivityMeasurement, ActivityMeasurementValues } from '../../state/detailed-site-activities/detailed-site-activities.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityLengthCalcService extends PolylineCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    private terrainSampling: TerrainSamplingService,
    private siteService: DetailedSiteService
  ) {
    super(siteQuery);
  }

  async calcResults(
    measurement: ActivityMeasurement,
    siteId: string,
    calcModelOption: CalcModelOption,
    precalcData?: PrecalcData
  ): Promise<{ calcResult: ActivityMeasurementValues }> {
    const pointGridFeatureCollection = precalcData.pointGridFeatureCollection;
    const modelTerrain = await this.siteService.getTerrainProvider(
      siteId,
      calcModelOption.id,
      calcModelOption.type,
      measurement.sourceModelType
    );
    const surfaceLength = await this.calcSurfaceLength(measurement.positions, pointGridFeatureCollection.features, modelTerrain);
    return {
      calcResult: {
        values: { surfaceLength }
      }
    };
  }

  private async calcSurfaceLength(positions: Cartesian3[], linePoints: Feature<Point>[], modelTerrain: Terrain) {
    const cartographicPositions = await this.terrainSampling.sampleTerrain(positions, modelTerrain);
    let horizontalLength = 0;
    let index = 0;
    while (index < cartographicPositions.length - 1) {
      horizontalLength += GeoUtils.distance(cartographicPositions[index], cartographicPositions[index + 1], false);
      index++;
    }

    if (modelTerrain.type === 'FLAT') {
      return horizontalLength;
    }

    // Sample each position to get accurate height
    const coordinates: Cartesian3[] = linePoints.map(point =>
      Cesium.Cartesian3.fromDegrees(point.geometry.coordinates[0], point.geometry.coordinates[1])
    );
    const sampleCoordinates = this.smoothCoordinates(
      modelTerrain.type,
      await this.terrainSampling.sampleTerrain(coordinates, modelTerrain)
    );

    const coordinatesWithHeight = sampleCoordinates.filter(c => isDefined(c.height));
    const surfaceLength = this.calcSurfaceDistanceAndElevationLimits(coordinatesWithHeight).surfaceDistance;
    return surfaceLength;
  }
}
