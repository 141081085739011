import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';

import { GetNFIHintResponse } from '../../../../generated/nms/model/models';

export enum FeatureHintPlace {
  ReportTab = 'ReportTab',
  NewReportBtn = 'NewReportBtn',
  RoadGradeCheckingReport = 'RoadGradeCheckingReport',
  SurfaceGradeCheckingReport = 'SurfaceGradeCheckingReport',
  WaterFlowReport = 'WaterFlowReport',
  SidenavGroupCardContextMenu = 'SidenavGroupCardContextMenu',
  SidenavCategoryCardContextMenu = 'SidenavCategoryCardContextMenu',
  SidenavDesignItemContextMenu = 'SidenavDesignItemContextMenu',
  SidenavDesignItemFavorite = 'SidenavDesignItemFavorite',
  SidenavDesignItemOpacity = 'SidenavDesignItemOpacity',
  AnalyticsTab = 'AnalyticsTab',
  MeasurementItemMenu = 'MeasurementItemMenu',
  CloneMeasurementBtn = 'CloneMeasurementBtn',
  DownloadCSVBtn = 'DownloadCSVBtn',
  VolumeGridHeatmapReport = 'VolumeGridHeatmapReport',
  RotationAngleField = 'RotationAngleField',
  MainMenu = 'MainMenu',
  ProcessingHistoryBtn = 'ProcessingHistoryBtn',
  UsersAndPermissionsBtn = 'UsersAndPermissionsBtn',
  DownloadUsersAndPermissionsBtn = 'DownloadUsersAndPermissionsBtn',
  MeasurementsTab = 'MeasurementsTab',
  AnnotationsTab = 'AnnotationsTab',
  DesignsTab = 'DesignsTab',
  ModelEditsTab = 'ModelEditsTab',
  ActivitiesTab = 'ActivitiesTab',
  OverviewTab = 'OverviewTab',
  ReportAutomatedField = 'ReportAutomatedField',
  UploadFlightPolygonAreaFileBtn = 'UploadFlightPolygonAreaFileBtn',
  MeasureToolsMenu = 'MeasureToolsMenu',
  GenerateSurfaceBtn = 'GenerateSurfaceBtn'
}

export interface FeatureHintData extends GetNFIHintResponse {
  showOnHover: boolean;
  kept: boolean;
  place: FeatureHintPlace;
}

interface FeatureHintContent {
  title: string;
  description: string;
  moreInfoLink?: string;
}

export const featureHintsContents: { [place in FeatureHintPlace]?: FeatureHintContent } = {
  [FeatureHintPlace.WaterFlowReport]: {
    title: $localize`:@@shared.featureHints.waterFlowReportTitle:Water Flow Report`,
    description: $localize`:@@shared.featureHints.waterFlowReportDescription:Effortlessly visualize and verify water movement across your project for enhanced safety and efficiency`,
    moreInfoLink: 'https://support.datumate.com/portal/en/kb/articles/water-flow-report'
  },
  [FeatureHintPlace.SidenavDesignItemFavorite]: {
    title: $localize`:@@shared.featureHints.favoriteDesignTitle:Favorite design`,
    description: $localize`:@@shared.featureHints.favoriteDesignDescription:Easily access your most-used design files by marking them as favorites - they'll always appear at the top of your list.`
  },
  [FeatureHintPlace.SidenavDesignItemOpacity]: {
    title: $localize`:@@shared.featureHints.designOpacityTitle:Design opacity control`,
    description: $localize`:@@shared.featureHints.designOpacityDescription:Control the opacity of each design element, providing enhanced visibility and flexibility. \n\nThe opacity setting is saved between sessions for a seamless experience.`
  },
  [FeatureHintPlace.ReportAutomatedField]: {
    title: $localize`:@@shared.featureHints.reportAutomatedFieldTitle:Automatic Professional Report`,
    description: $localize`:@@shared.featureHints.reportAutomatedFieldDescription:Mark a report as automated to have it cloned automatically for every new model.`,
    moreInfoLink: 'https://support.datumate.com/portal/en/kb/articles/automated-professional-reports'
  },
  [FeatureHintPlace.CloneMeasurementBtn]: {
    title: $localize`:@@shared.featureHints.cloneMeasurementTitle:Clone measurement`,
    description: $localize`:@@shared.featureHints.cloneMeasurementDescription:Clone this measurement to any flight (current or others).`
  },
  [FeatureHintPlace.DownloadCSVBtn]: {
    title: $localize`:@@shared.featureHints.downloadCSVTitle:CSV Export`,
    description: $localize`:@@shared.featureHints.downloadCSVDescription:Export a CSV file listing all designs in this category.`
  },
  [FeatureHintPlace.UploadFlightPolygonAreaFileBtn]: {
    title: $localize`:@@shared.featureHints.uploadFlightAreaPolygonFileTitle:Upload ROI File`,
    description: $localize`:@@shared.featureHints.uploadFlightAreaPolygonFileDescription:Upload ROI as .kml, .dxf, or .csv to limit the images to process. The ROI is adjustable and will be saved for your future models.`
  },
  [FeatureHintPlace.GenerateSurfaceBtn]: {
    title: $localize`:@@shared.featureHints.exportROISurfaceTitle:Export ROI as a surface`,
    description: $localize`:@@shared.featureHints.exportROISurfaceDescription:Export ROI as a surface in TIFF, LandXML, or Point Cloud format. For LandXML and Point Cloud exports, you can also select the desired surface resolution. All exports will be saved to your downloads list.`
  }
} as const;

export interface FeatureHintsState extends EntityState<FeatureHintData, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'feature-hints', producerFn: produce, idKey: 'id' })
export class FeatureHintsStore extends EntityStore<FeatureHintsState> {
  constructor() {
    super();
  }

  dismissHintsByPlace(place: FeatureHintPlace) {
    this.remove(item => item.place === place);
  }

  keepHintsByPlace(place: FeatureHintPlace) {
    this.update(item => item.place === place, { kept: true });
  }

  disableHoverByPlace(place: FeatureHintPlace) {
    this.update(item => item.place === place, { showOnHover: false });
  }

  initiateState(newState: FeatureHintData[]) {
    this.set(newState);
  }
}
