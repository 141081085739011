import { Pipe, PipeTransform } from '@angular/core';

import { CalcModelType, TaskOrDesign } from '../state/detailed-site.model';
import { CalcModelOption } from '../state/detailed-site.model';
import { DetailedSiteQuery } from '../state/detailed-site.query';
import { getCalcModelType } from '../state/detailed-site.utils';

@Pipe({
  name: 'taskOrDesignName'
})
export class TaskOrDesignNamePipe implements PipeTransform {
  constructor(private siteQuery: DetailedSiteQuery) {}

  /**
   * @param value - task or design id if string; task or design entity in other cases
   */
  transform(value: TaskOrDesign | CalcModelOption | string): string {
    if (typeof value === 'string') {
      return (
        this.siteQuery.getTaskOrDesignName(value, CalcModelType.TASK) || this.siteQuery.getTaskOrDesignName(value, CalcModelType.DESIGN)
      );
    } else {
      const type = [CalcModelType.TASK, CalcModelType.DESIGN].includes((value as CalcModelOption).type)
        ? value.type
        : getCalcModelType(value as TaskOrDesign);

      return type !== CalcModelType.TASK && 'name' in value
        ? value.name
        : this.siteQuery.getTaskOrDesignName(value.id, type as CalcModelType);
    }
  }
}
