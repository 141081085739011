import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CalcModelOption, SurfaceModelOption } from '../../state/detailed-site.model';
import { AddTaskOrDesignDialogComponent } from './add-task-or-design-dialog.component';

export type ModelOption = CalcModelOption | SurfaceModelOption;
export type ModelOptions = CalcModelOption[] | SurfaceModelOption[];

export interface AddTaskOrDesignDialogData {
  availableModelOptions: ModelOptions;
  triggerElement?: HTMLElement;
  dialogHeader?: string;
  showOnlyFlights?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AddTaskOrDesignDialogService {
  constructor(private dialog: MatDialog) {}

  dialogWidth = 550;
  dialogHeight = 400;

  open(data: AddTaskOrDesignDialogData) {
    const { top, left } = data.triggerElement?.getBoundingClientRect() ?? {};

    return this.dialog.open<AddTaskOrDesignDialogComponent, AddTaskOrDesignDialogData, ModelOptions>(AddTaskOrDesignDialogComponent, {
      autoFocus: false,
      minWidth: `min-content`,
      restoreFocus: false,
      width: `${this.dialogWidth}px`,
      height: `${this.dialogHeight}px`,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      data,
      position: data.triggerElement
        ? {
            top: `${top - this.dialogHeight < 0 ? top : top - this.dialogHeight}px`,
            left: `${left - this.dialogWidth < 0 ? left : left - this.dialogWidth}px`
          }
        : undefined
    });
  }
}
