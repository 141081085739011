import { Injectable } from '@angular/core';
import { bbox, bboxPolygon, booleanDisjoint, featureCollection, point } from '@turf/turf';
import { Feature, Polygon } from 'geojson';
import { lastValueFrom } from 'rxjs';

import { isLocalCS } from '../../shared/utils/backend-services';
import { convertCoordinateCSToPosition } from '../../shared/utils/cs-conversions';
import { UnitsEnum } from '../../shared/utils/unit-conversion';
import { CoordinateSystem, Site } from '../../tenant/tenant.model';
import { TenantService } from '../../tenant/tenant.service';

export interface CoordinateSystemValidationResponse {
  valid: boolean;
  coordinateSystems: CoordinateSystem[];
}

interface GcpLocation {
  x?: number;
  y?: number;
  z?: number;
}

export class ConversionOutOfBounds extends Error {
  constructor() {
    super('Converted GCP is out of bounds');
  }
}

export class ConversionFailed extends Error {
  constructor(siteCoordinateSystem: CoordinateSystem) {
    super(`Conversion failed using coordinate system ${siteCoordinateSystem.code}`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CoordinateSystemValidatorService {
  constructor(private tenantService: TenantService) {}

  async validateSiteCoordinateSystemFromImages(
    site: Site,
    imagePoints: { longitude: number; latitude: number }[]
  ): Promise<CoordinateSystemValidationResponse> {
    const siteCoordinateSystem = site?.coordinateSystem;

    // Don't do validation for local CS
    if (isLocalCS(siteCoordinateSystem)) {
      return { valid: true, coordinateSystems: null };
    }

    const locations = imagePoints.map(image => point([image.longitude, image.latitude]));
    const imagesBboxPolygon = bboxPolygon(bbox(featureCollection(locations)));

    if (siteCoordinateSystem) {
      const siteCoordinateSystemBboxPolygon = this.coordinateSystemBboxPolygon(siteCoordinateSystem);
      if (siteCoordinateSystemBboxPolygon && !booleanDisjoint(imagesBboxPolygon, siteCoordinateSystemBboxPolygon)) {
        return { valid: true, coordinateSystems: null };
      }
    }

    const coordinateSystems = await this.getSuitableCoordinateSystemsFromImages(site, imagesBboxPolygon);
    return {
      valid: false,
      coordinateSystems
    };
  }

  private async getSuitableCoordinateSystemsFromImages(site: Site, imagesBboxPolygon: Feature<Polygon>) {
    const siteUnits = site?.units;
    const coordinateSystems = await lastValueFrom(this.tenantService.fetchCoordinateSystems());
    const coordinateSystemBboxPolygons = coordinateSystems
      .filter(this.filterCoordinateSystems(siteUnits))
      .map(this.coordinateSystemBboxPolygon);

    return coordinateSystemBboxPolygons
      .filter(bboxPolygon => bboxPolygon && !booleanDisjoint(imagesBboxPolygon, bboxPolygon))
      .map(bboxPolygon => bboxPolygon.properties.coordinateSystem);
  }

  private filterCoordinateSystems = (siteUnits: UnitsEnum) => (coordinateSystem: CoordinateSystem) => {
    return (
      coordinateSystem.proj4 &&
      coordinateSystem.bBox &&
      coordinateSystem.units === siteUnits &&
      ![0, 180, -180].includes(coordinateSystem.bBox.bottomLeftLongitude) &&
      ![0, 180, -180].includes(coordinateSystem.bBox.bottomLeftLatitude) &&
      ![0, 90, -90].includes(coordinateSystem.bBox.topRightLongitude) &&
      ![0, 90, -90].includes(coordinateSystem.bBox.topRightLatitude)
    );
  };

  private coordinateSystemBboxPolygon(coordinateSystem: CoordinateSystem) {
    if (!coordinateSystem?.bBox) {
      return null;
    }

    const polygon = bboxPolygon(
      bbox(
        featureCollection([
          point([coordinateSystem.bBox.bottomLeftLongitude, coordinateSystem.bBox.bottomLeftLatitude]),
          point([coordinateSystem.bBox.topRightLongitude, coordinateSystem.bBox.topRightLatitude])
        ])
      )
    );
    polygon.properties = { coordinateSystem };
    return polygon;
  }

  async validateSiteCoordinateSystemFromGCPs(
    site: Site,
    gcps: GcpLocation[],
    imagePoints: { longitude: number; latitude: number }[]
  ): Promise<CoordinateSystemValidationResponse> {
    const siteCoordinateSystem = site?.coordinateSystem;

    // Don't do validation for local CS
    if (isLocalCS(siteCoordinateSystem)) {
      return { valid: true, coordinateSystems: null };
    }

    const imagesCoordinates = imagePoints.map(image => point([image.longitude, image.latitude]));
    const imagesBboxPolygon = bboxPolygon(bbox(featureCollection(imagesCoordinates)));

    if (siteCoordinateSystem) {
      const valid = this.checkCoordinateSystemWithGCPsAndImages(siteCoordinateSystem, gcps, imagesBboxPolygon);
      if (valid) {
        return { valid, coordinateSystems: null };
      }
    }

    const suitableCoordinateSystemsFromImages = await this.getSuitableCoordinateSystemsFromImages(site, imagesBboxPolygon);
    const coordinateSystems = suitableCoordinateSystemsFromImages.filter(cs =>
      this.checkCoordinateSystemWithGCPsAndImages(cs, gcps, imagesBboxPolygon)
    );
    return { valid: false, coordinateSystems };
  }

  async getRecommendedCoordinateSystemsFromGCPs(site: Site, gcps: GcpLocation[], imagePoints: { longitude: number; latitude: number }[]) {
    const imagesCoordinates = imagePoints.map(image => point([image.longitude, image.latitude]));
    const imagesBboxPolygon = bboxPolygon(bbox(featureCollection(imagesCoordinates)));

    const suitableCoordinateSystemsFromImages = await this.getSuitableCoordinateSystemsFromImages(site, imagesBboxPolygon);
    const coordinateSystems = suitableCoordinateSystemsFromImages.filter(cs =>
      this.checkCoordinateSystemWithGCPsAndImages(cs, gcps, imagesBboxPolygon)
    );

    return coordinateSystems;
  }

  private checkCoordinateSystemWithGCPsAndImages(
    coordinateSystem: CoordinateSystem,
    gcps: GcpLocation[],
    imagesBboxPolygon: Feature<Polygon>
  ) {
    try {
      const gcpCoordinates = this.convertGCPsFromCoordinateSystem(coordinateSystem, gcps);

      const gcpsBboxPolygon = bboxPolygon(bbox(featureCollection(gcpCoordinates)));
      return !booleanDisjoint(imagesBboxPolygon, gcpsBboxPolygon);
    } catch (error) {
      return false;
    }
  }

  convertGCPsFromCoordinateSystem(coordinateSystem: CoordinateSystem, gcps: GcpLocation[]) {
    const gcpCoordinates = [];
    for (const gcp of gcps) {
      const convertedGCP = convertCoordinateCSToPosition({ x: gcp.x, y: gcp.y, z: gcp.z }, coordinateSystem);

      // Check conversion error
      if (!convertedGCP || isNaN(convertedGCP.longitude) || isNaN(convertedGCP.latitude)) {
        console.error('CS conversion failed', coordinateSystem, gcp);
        throw new ConversionFailed(coordinateSystem);
      }

      // Check lon/lat bounds error
      if (convertedGCP.longitude < -180 || convertedGCP.longitude > 180 || convertedGCP.latitude < -90 || convertedGCP.latitude > 90) {
        throw new ConversionOutOfBounds();
      }

      gcpCoordinates.push(point([convertedGCP.longitude, convertedGCP.latitude]));
    }

    return gcpCoordinates;
  }
}
