import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, interval, map, Observable, Subscription, switchMap, take, timer } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { environment } from '../../../environments/environment';
import { jwtHelper } from '../../auth/state/auth.utils';

interface GetWSTokenResponse {
  token: string;
}

export interface WebSocketMessage<T> {
  action: string;
  data?: T;
  errorMessage?: string;
}

const HEARTBEAT_ACTION = 'ping';
const HEARTBEAT_INTERVAL_MS = 9 * 60 * 100; // The WS disconnects after 10 minutes of inactivity send a ping every 9 minutes to keep it alive
const RECONNECT_TIMER_MS = 3000;
const MAX_RECONNECT_ATTEMPTS = 5;

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private isInitInProgress: boolean;
  private connectionToken: string;
  private webSocketSubject = new BehaviorSubject<WebSocketSubject<WebSocketMessage<unknown>>>(null);
  private heartbeatSubscription: Subscription;
  private reconnectAttempts = 0;

  constructor(private http: HttpClient) {}

  private get websocket$() {
    // Force to create new connection if connection token expired
    if (!this.connectionToken || jwtHelper.isTokenExpired(this.connectionToken)) {
      this.connectionToken = null;
      this.webSocketSubject.next(null);
    }

    if (!this.webSocketSubject.value) {
      return this.isInitInProgress ? this.waitForInit() : this.init();
    }

    return this.webSocketSubject;
  }

  private waitForInit() {
    return this.webSocketSubject.pipe(
      filter(subject => subject !== null),
      take(1)
    );
  }

  private init() {
    this.isInitInProgress = true;

    return this.http.post<GetWSTokenResponse>(`${environment.lambdaGatewayUrl}/getwstoken`, null).pipe(
      switchMap(tokenResponse => {
        this.connectionToken = tokenResponse.token;
        this.isInitInProgress = false;

        const webSocketInstance = webSocket<WebSocketMessage<unknown>>({
          url: `${environment.webSocketUrl}?token=${this.connectionToken}`,
          closeObserver: {
            next: (event: CloseEvent) => {
              this.connectionToken = null;
              this.webSocketSubject.next(null);
              this.stopHeartbeat();
              console.error('Connection closed unexpectedly', event);
              this.handleReconnection();
            }
          },
          openObserver: {
            next: () => {
              this.startHeartbeat();
            }
          }
        });

        this.webSocketSubject.next(webSocketInstance);
        return this.webSocketSubject;
      })
    );
  }

  private handleReconnection() {
    if (this.reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
      this.reconnectAttempts++;
      timer(RECONNECT_TIMER_MS).subscribe(() => {
        this.init().subscribe();
      });
    } else {
      console.error('Maximum reconnection attempts reached');
    }
  }

  private startHeartbeat() {
    this.heartbeatSubscription = interval(HEARTBEAT_INTERVAL_MS)
      .pipe(switchMap(() => this.sendMessage({ action: HEARTBEAT_ACTION })))
      .subscribe();
  }

  private stopHeartbeat() {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
      this.heartbeatSubscription = null;
    }
  }

  observe<T>(actions: string): Observable<WebSocketMessage<T>>;
  observe<T>(...actions: string[]): Observable<WebSocketMessage<T>>;
  observe<T>(actions: [string, ...string[]]): Observable<WebSocketMessage<T>>;
  observe<T>(actions: [string, ...string[]] | string): Observable<WebSocketMessage<T>> {
    const actionsList = typeof actions === 'string' ? [actions] : actions;
    return this.websocket$.pipe(
      filter(subject => subject !== null),
      switchMap((websocketSubject: WebSocketSubject<WebSocketMessage<T>>) => {
        return websocketSubject.pipe(filter(msg => actionsList.includes(msg.action)));
      })
    );
  }

  sendMessage<T>(msg: WebSocketMessage<T>) {
    return this.websocket$.pipe(
      filter(subject => subject !== null),
      map((websocketSubject: WebSocketSubject<WebSocketMessage<T>>) => websocketSubject.next(msg))
    );
  }

  unsubscribe() {
    this.webSocketSubject.value?.complete();
    this.webSocketSubject.next(null);
    this.connectionToken = null;
    this.stopHeartbeat();
  }
}
