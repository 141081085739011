import { Injectable } from '@angular/core';
import { Cartesian3 } from '@datumate/angular-cesium';
import { multiPoint } from '@turf/turf';
import { Feature, MultiPoint, Point, Position } from 'geojson';

import { isDefined } from '../../../shared/utils/general';
import { Cartographic, GeoUtils } from '../../../shared/utils/geo';
import { roundTo } from '../../../shared/utils/math';
import { GeoJsonProperties, PolylineCalcService, PrecalcData, TerrainSamplesValues } from '../../services/calc-services';
import { Terrain } from '../../services/terrain-provider.service';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { CalcModelOption } from '../../state/detailed-site.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';
import { CalcModelValues, MapEntity } from '../../state/detailed-site-entities/detailed-site-entities.model';

@Injectable({
  providedIn: 'root'
})
export class DistanceCalcService extends PolylineCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    private terrainSampling: TerrainSamplingService,
    private siteService: DetailedSiteService
  ) {
    super(siteQuery);
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelOptions: CalcModelOption[],
    precalcData: PrecalcData
  ): Promise<{ calcResult: CalcModelValues[]; samplePoints: Feature<MultiPoint, GeoJsonProperties> }> {
    const { cellSide, pointGridFeatureCollection } = precalcData;

    const geojsonProperties = entity?.geoJson?.features?.[0]?.properties as GeoJsonProperties;
    let terrainSamplesValues: TerrainSamplesValues[] = geojsonProperties?.terrainSamplesValues ?? [];
    let calcResult: CalcModelValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelOptions.length; i++) {
      const modelOption = modelOptions[i];
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelOption.id, modelOption.type, entity.sourceModel);

      const { horizontalDistance, slopeDistance, surfaceDistance, elevationMin, elevationMax, slope, verticalDistance, sampleCoordinates } =
        await this.calcElevationDependentFields(entity.positions, pointGridFeatureCollection.features, modelTerrain);

      terrainSamplesValues = [
        ...terrainSamplesValues.filter(values => values.id !== modelOption.id || values.type !== modelOption.type),
        {
          id: modelOption.id,
          type: modelOption.type,
          samplesValues: sampleCoordinates.map(c => (isDefined(c.height) ? roundTo(c.height) : c.height))
        }
      ];

      calcResult = [
        ...calcResult.filter(res => res.id !== modelOption.id || res.type !== modelOption.type),
        {
          id: modelOption.id,
          type: modelOption.type,
          values: [
            { ...FIELD_MAPPING.surfaceDistance, value: surfaceDistance },
            { ...FIELD_MAPPING.slopeDistance, value: slopeDistance },
            { ...FIELD_MAPPING.horizontalDistance, value: horizontalDistance },
            { ...FIELD_MAPPING.verticalDistance, value: verticalDistance },
            { ...FIELD_MAPPING.elevationMin, value: elevationMin },
            { ...FIELD_MAPPING.elevationMax, value: elevationMax },
            { ...FIELD_MAPPING.slopePercent, value: slope }
          ]
        }
      ];
    }

    const samplePointsProps: GeoJsonProperties = { cellSide, terrainSamplesValues };
    const samplePositions: Position[] = pointGridFeatureCollection.features.map(f =>
      f.geometry.coordinates.map(value => roundTo(value, 7))
    );

    return {
      calcResult,
      samplePoints: multiPoint(samplePositions, samplePointsProps)
    };
  }

  private async calcElevationDependentFields(positions: Cartesian3[], linePoints: Feature<Point>[], modelTerrain: Terrain) {
    const cartographicPositions = await this.terrainSampling.sampleTerrain(positions, modelTerrain);

    let horizontalDistance = 0;
    let slopeDistance = 0;
    let index = 0;
    while (index < cartographicPositions.length - 1) {
      slopeDistance += GeoUtils.distance(cartographicPositions[index], cartographicPositions[index + 1]);
      horizontalDistance += GeoUtils.distance(cartographicPositions[index], cartographicPositions[index + 1], false);
      index++;
    }

    if (modelTerrain.type === 'FLAT') {
      const sampleCoordinates: Cartographic[] = linePoints.map(point => ({
        longitude: point.geometry.coordinates[0],
        latitude: point.geometry.coordinates[1],
        height: 0
      }));
      return {
        horizontalDistance,
        slopeDistance,
        surfaceDistance: horizontalDistance,
        elevationMin: 0,
        elevationMax: 0,
        slope: 0,
        sampleCoordinates
      };
    }

    // Sample each position to get accurate height
    const coordinates: Cartesian3[] = linePoints.map(point =>
      Cesium.Cartesian3.fromDegrees(point.geometry.coordinates[0], point.geometry.coordinates[1])
    );
    const sampleCoordinates = this.smoothCoordinates(
      modelTerrain.type,
      await this.terrainSampling.sampleTerrain(coordinates, modelTerrain)
    );

    const coordinatesWithHeight = sampleCoordinates.filter(c => isDefined(c.height));
    const { surfaceDistance, elevationMin, elevationMax } = this.calcSurfaceDistanceAndElevationLimits(coordinatesWithHeight);
    const { slope, verticalDistance } = this.calcFieldsBetweenFirstAndLastPositions(cartographicPositions);

    return {
      horizontalDistance,
      slopeDistance,
      surfaceDistance,
      elevationMin,
      elevationMax,
      slope,
      verticalDistance,
      sampleCoordinates
    };
  }

  private calcFieldsBetweenFirstAndLastPositions(cartographicPositions: Cartographic[]) {
    if (cartographicPositions.length < 2) {
      return { slope: 0, verticalDistance: 0 };
    }

    const firstPosition = cartographicPositions.at(0);
    const lastPosition = cartographicPositions.at(-1);

    if (!isDefined(firstPosition.height) || !isDefined(lastPosition.height)) {
      return { slope: 0, verticalDistance: 0 };
    }

    const firstToLastPositionDistance = GeoUtils.distance(lastPosition, firstPosition, false);
    const verticalDistance = lastPosition.height - firstPosition.height;
    const slope = firstToLastPositionDistance > 0 ? (verticalDistance / firstToLastPositionDistance) * 100 : 0;
    return { slope, verticalDistance };
  }
}
