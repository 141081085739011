import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { merge, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UpdateUploadedTaskRequest } from '../../../generated/fms/model/updateUploadedTaskRequest';
import { AuthQuery } from '../../auth/state/auth.query';
import { Task, TaskStateEnum } from '../../detailed-site/state/detailed-site.model';
import { ConfirmationDialogResultEnum } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { CoordinateSystemModifierService } from '../../shared/coordinate-system-modifier/coordinate-system-modifier.service';
import { LoadingModalService } from '../../shared/loading-modal/loading-modal.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { LocaleService } from '../../shared/services/locale.service';
import { SnackBarService } from '../../shared/services/snackbar.service';
import { ServerError } from '../../shared/utils/backend-services';
import { isDefined } from '../../shared/utils/general';
import { User } from '../../tenant/tenant.model';
import { TenantQuery } from '../../tenant/tenant.query';
import { CoordinateSystemValidatorService } from '../services/coordinate-system-validator.service';
import { AUTOMODEL_GEOREF_METHODS, GeorefMethodEnum, UploadWizardMode } from '../state/upload-wizard.model';
import { UploadWizardQuery } from '../state/upload-wizard.query';
import { UploadWizardService } from '../state/upload-wizard.service';
import { Step } from '../wizard-stepper/steps';

const GEOREF_SORT_ORDER = [
  GeorefMethodEnum.AUTOGEOREF,
  GeorefMethodEnum.MANUAL,
  GeorefMethodEnum.RTKGCP,
  GeorefMethodEnum.MLRTKGCP,
  GeorefMethodEnum.RTK,
  GeorefMethodEnum.GCP,
  GeorefMethodEnum.MLGCP,
  GeorefMethodEnum.GEOTAG
];

interface GeoReferencingOption {
  value: GeorefMethodEnum;
  visible: boolean;
  best: boolean;
  disabled: boolean;
  disabledReason?: string;
}

@UntilDestroy()
@Component({
  selector: 'flight-info',
  templateUrl: './flight-info.component.html',
  styleUrls: ['./flight-info.component.scss']
})
export class FlightInfoComponent implements OnInit {
  GeorefMethodEnum = GeorefMethodEnum;
  flightInfoForm: UntypedFormGroup;
  task: Task;
  georefChanged: boolean;
  isAutomodel: boolean;
  hasAllValidRTKImages: boolean;
  rollingShutterHint: string;
  todayDate = moment();

  private isPrevTaskGCP: boolean;
  @Input() set prevTaskHadGCP(value: boolean) {
    this.isPrevTaskGCP = value;
    this.recalcGeorefOptions();
  }
  get prevTaskHadGCP() {
    return this.isPrevTaskGCP;
  }

  geoReferencingOptions: GeoReferencingOption[] = [
    { value: GeorefMethodEnum.RTKGCP, visible: false, best: false, disabled: false },
    { value: GeorefMethodEnum.MLRTKGCP, visible: false, best: false, disabled: false },
    { value: GeorefMethodEnum.RTK, visible: false, best: false, disabled: false },
    { value: GeorefMethodEnum.GEOTAG, visible: false, best: false, disabled: false },
    { value: GeorefMethodEnum.GCP, visible: false, best: false, disabled: false },
    { value: GeorefMethodEnum.MLGCP, visible: false, best: false, disabled: false },
    { value: GeorefMethodEnum.AUTOGEOREF, visible: false, best: false, disabled: false },
    { value: GeorefMethodEnum.MANUAL, visible: false, best: false, disabled: false }
  ];
  selectCameraModelList: string[] = [];
  selectOperatorList: Array<User> = [];

  mode: UploadWizardMode;
  Mode = UploadWizardMode;

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: LocaleService,
    private authQuery: AuthQuery,
    private tenantQuery: TenantQuery,
    private uploadQuery: UploadWizardQuery,
    private uploadService: UploadWizardService,
    private analytics: AnalyticsService,
    private confirmationDialog: ConfirmationDialogService,
    private coordinateSystemValidator: CoordinateSystemValidatorService,
    private coordinateSystemModifierDialog: CoordinateSystemModifierService,
    private loadingModal: LoadingModalService,
    private snackbar: SnackBarService
  ) {}

  ngOnInit() {
    this.task = this.uploadQuery.getTask();

    this.selectOperatorList = this.tenantQuery.getAllUsers(true);

    if (this.task) {
      if (this.task.droneType) {
        this.selectCameraModelList.push(this.task.droneType);
      }
    }

    this.mode = this.uploadQuery.getMode();

    this.flightInfoForm = this.fb.group({
      date: [{ value: moment(this.task ? this.task.missionFlightDate : null), disabled: !!this.task }, [Validators.required]],
      operator: [{ value: this.task ? this.task.operatorId : this.authQuery.getUserId(), disabled: !!this.task }, [Validators.required]],
      cameraModel: [{ value: this.task ? this.selectCameraModelList[0] : null, disabled: !!this.task }],
      rollingShutter: [{ value: this.task ? this.task.rollingShutter : false, disabled: false }],
      comments: [{ value: this.task ? this.task.description : undefined, disabled: false }, [Validators.maxLength(512)]],
      rtk: [{ value: this.task ? this.task.rtk : true, disabled: this.mode === UploadWizardMode.EDIT && !this.task?.rtk }],
      geoReferencing: [{ value: this.task ? this.task.georefMethod : null, disabled: false }, [Validators.required]]
    });

    merge(this.flightInfoForm.get('rtk').valueChanges, this.flightInfoForm.get('rollingShutter').valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(this.recalcGeorefOptions);

    this.flightInfoForm
      .get('geoReferencing')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((georef: GeorefMethodEnum) => this.uploadService.setAutomodel(georef));

    this.uploadQuery.cameraModels$.pipe(untilDestroyed(this)).subscribe(cameraModels => {
      this.selectCameraModelList = cameraModels;

      // If in edit mode - select task camera model
      if (this.task && this.task.droneType && !cameraModels.includes(this.task.droneType)) {
        this.selectCameraModelList.push(this.task.droneType);
      }

      // Set selected value if it was null, or deleted from images
      if (!this.flightInfoForm.value.cameraModel || !cameraModels.includes(this.flightInfoForm.value.cameraModel)) {
        this.flightInfoForm.patchValue({ cameraModel: cameraModels[0] });
      }
    });

    this.uploadQuery.automodel$.pipe(untilDestroyed(this)).subscribe(automodel => (this.isAutomodel = automodel));

    this.uploadQuery.hasAllValidRTKImages$.pipe(untilDestroyed(this)).subscribe(hasAllValidRTKImages => {
      if (!isDefined(hasAllValidRTKImages)) {
        return;
      }

      this.hasAllValidRTKImages = hasAllValidRTKImages;

      const rtkField = this.flightInfoForm.get('rtk');
      if (hasAllValidRTKImages) {
        rtkField.enable();
        if (this.mode === UploadWizardMode.CREATE && !rtkField.dirty) {
          rtkField.patchValue(true);
        }
      } else {
        rtkField.disable();
        rtkField.patchValue(false);
      }
    });

    this.uploadQuery.rollingShutterRecommendation$.pipe(untilDestroyed(this)).subscribe(({ flagValue, disable, hint }) => {
      this.rollingShutterHint = hint;

      const rollingShutterField = this.flightInfoForm.get('rollingShutter');
      if (disable) {
        rollingShutterField.disable();
        rollingShutterField.patchValue(flagValue);
      } else {
        rollingShutterField.enable();
        if (this.mode === UploadWizardMode.CREATE && !rollingShutterField.dirty) {
          rollingShutterField.patchValue(flagValue);
        }
      }
    });

    // Init date field value as first image date
    this.uploadQuery.filesToUpload$.pipe(untilDestroyed(this)).subscribe(files => {
      const dateField = this.flightInfoForm.get('date');
      if (dateField.touched || this.mode !== UploadWizardMode.CREATE) {
        // Don't change date field once user entered value or not in flight create
        return;
      }

      let date: string = undefined;
      if (isDefined(files)) {
        const firstImageWithDate = files.find(file => isDefined(file?.exif?.date));
        date = firstImageWithDate?.exif.date;
      }

      if (dateField.value !== date) {
        this.flightInfoForm.patchValue({ date });
      }
    });

    this.recalcGeorefOptions();
  }

  get isRTK() {
    return this.flightInfoForm?.get('rtk')?.value;
  }

  get formValue(): Partial<Task> {
    const formValue = this.flightInfoForm.getRawValue();
    const dateUtcValue = moment(formValue.date).utc().locale('en');
    return {
      droneType: formValue.cameraModel,
      missionFlightDate: dateUtcValue.format() as any,
      description: formValue.comments || '',
      rollingShutter: formValue.rollingShutter,
      rtk: formValue.rtk,
      georefMethod: formValue.geoReferencing,
      operatorId: formValue.operator
    };
  }

  async submit() {
    switch (this.mode) {
      case UploadWizardMode.CREATE:
        await this.handleNewTaskSubmit();
        break;
      case UploadWizardMode.APPROVE:
        await this.approveTaskSubmit();
        break;
      case UploadWizardMode.EDIT:
        this.handleEditTaskSubmit();
        break;
    }
  }

  private handleEditTaskSubmit() {
    const dialogRef = this.confirmationDialog.openDialog(
      {
        title: $localize`:@@uploadWizard.flightInfo.editTaskConfirmationDialogTitle:Reprocess the model?`,
        content:
          $localize`:@@uploadWizard.flightInfo.editTaskConfirmationDialogContentQuestion:Are you sure you want to change the flight's Geo Referencing?` +
          '\n' +
          `<b>${$localize`:@@uploadWizard.flightInfo.editTaskConfirmationDialogContentConsequence:This operation will reprocess the model and overwrite all existing models, maps and measurements.`}</b>` +
          '\n\n' +
          $localize`:@@uploadWizard.flightInfo.editTaskConfirmationDialogContentAdditionalCharges:Additional charges will apply to this operation.`,
        yesCaption: $localize`:@@general.actionButtons.yes:Yes`,
        noCaption: $localize`:@@general.actionButtons.no:No`
      },
      { width: '480px' }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === ConfirmationDialogResultEnum.YES) {
        this.analytics.reprocessTask(
          this.uploadQuery.getTask(),
          this.formValue.georefMethod,
          this.formValue.rollingShutter,
          this.formValue.rtk
        );

        this.uploadService.updateTask(this.formValue, true).subscribe({
          next: response => {
            if (response.taskState === TaskStateEnum.WAITINGFORGCP) {
              this.uploadService.setStep(Step.GCP);
            } else if (response.taskState === TaskStateEnum.MLGCPINPROGRESS) {
              this.uploadService.setStep(Step.GCP);

              this.uploadService.pollMLGcpMarking().subscribe({
                error: error => {
                  this.snackbar.openError(
                    $localize`:@@uploadWizard.flightInfo.editTaskAIMarkingError:Error while AI marking GCPs`,
                    'Error while AI marking GCPs',
                    error
                  );
                }
              });
            } else {
              this.uploadService.setStep(this.isAutomodel ? Step.MODEL_GENERATION : Step.PREPROCESSING);
            }
          },
          error: error => {
            this.snackbar.openError($localize`:@@uploadWizard.flightInfo.editTaskError:Error updating task`, 'Error updating task', error);
          }
        });
      }
    });
  }

  private async approveTaskSubmit() {
    if (this.isAutomodel && this.uploadQuery.getIsFirstFlight()) {
      const loadingModalRef = this.loadingModal.open({
        title: $localize`:@@uploadWizard.flightInfo.loadingModalValidatingCoordinateSystem:Validating coordinate system, please wait...`
      });
      const images = this.uploadQuery.getFilteredImagesToApprove();
      const { valid, coordinateSystems } = await this.coordinateSystemValidator.validateSiteCoordinateSystemFromImages(
        this.uploadQuery.getSite(),
        images.map(image => ({ longitude: image.longitude, latitude: image.latitude }))
      );
      loadingModalRef.close();

      if (valid) {
        this.approveTask();
        this.uploadService.nextStep();
      } else {
        this.coordinateSystemModifierDialog.openDialog({
          siteId: this.uploadQuery.getSiteId(),
          task: this.uploadQuery.getTask(),
          recommendedCoordinateSystems: coordinateSystems,
          openedFrom: 'IMAGES',
          onContinue: () => {
            this.approveTask();
            this.uploadService.nextStep();
          }
        });
      }
    } else {
      this.approveTask();
      this.uploadService.nextStep();
    }
  }

  private approveTask() {
    const loadingModalRef = this.loadingModal.open({
      title: $localize`:@@uploadWizard.flightInfo.loadingModalApprovingImages:Preparing images, please wait...`
    });

    const dataToApprove: UpdateUploadedTaskRequest = {
      description: this.formValue.description,
      georefMethod: this.formValue.georefMethod,
      rollingShutter: this.formValue.rollingShutter,
      rtk: this.formValue.rtk
    };
    return this.uploadService.approveTask(dataToApprove).subscribe({
      complete: () => loadingModalRef.close(),
      error: response => {
        loadingModalRef.close();

        let msg = $localize`:@@uploadWizard.flightInfo.approveTaskError:Error approving flight`;
        if (response?.error?.code === ServerError.ExceptionInValidateCounter) {
          msg += '. ' + $localize`:@@uploadWizard.flightInfo.approveTaskErrorLimitReached:An account limit has been reached.`;
        }

        this.snackbar.openError(msg, 'Error approving task', response);

        this.uploadService.setStep(Step.FLIGHT_INFO);
      }
    });
  }

  private async handleNewTaskSubmit() {
    if (this.isAutomodel && this.uploadQuery.getIsFirstFlight()) {
      const loadingModalRef = this.loadingModal.open({
        title: $localize`:@@uploadWizard.flightInfo.loadingModalValidatingImages:Validating images, please wait...`
      });
      const files = this.uploadQuery.getFilteredFilesToUpload();
      const { valid, coordinateSystems } = await this.coordinateSystemValidator.validateSiteCoordinateSystemFromImages(
        this.uploadQuery.getSite(),
        files.map(f => ({ longitude: f.exif.location.longitude, latitude: f.exif.location.latitude }))
      );
      loadingModalRef.close();

      if (valid) {
        this.createNewTask();
        this.uploadService.nextStep();
      } else {
        this.coordinateSystemModifierDialog.openDialog({
          siteId: this.uploadQuery.getSiteId(),
          recommendedCoordinateSystems: coordinateSystems,
          openedFrom: 'IMAGES',
          onContinue: () => {
            this.createNewTask();
            this.uploadService.nextStep();
          }
        });
      }
    } else {
      this.createNewTask();
      this.uploadService.nextStep();
    }
  }

  private createNewTask() {
    this.uploadService.setUploadingLoading(true);
    const siteId = this.uploadQuery.getSiteId();
    this.uploadService
      .createTask({ siteId, name: `${siteId}-${new Date().getTime()}`, ...this.formValue })
      .pipe(
        catchError(error => {
          this.uploadService.setStep(Step.FLIGHT_INFO);

          this.snackbar.openError(
            $localize`:@@uploadWizard.flightInfo.createTaskError:Error creating new flight`,
            'Error creating new flight',
            error
          );
          return throwError(() => error);
        }),
        switchMap(taskId => {
          // Update site center if it's the first flight
          if (this.uploadQuery.getIsFirstFlight()) {
            return this.uploadService.updateSiteLocation().pipe(
              catchError(error => {
                console.error('Error updating site center', error);
                return of(null);
              }),
              map(() => taskId)
            );
          }

          return of(taskId);
        }),
        switchMap(taskId =>
          this.uploadService.uploadTaskImages(taskId).pipe(
            catchError(error => {
              this.snackbar.openError(
                $localize`:@@uploadWizard.flightInfo.uploadImagesError:Error uploading flight images`,
                'Error uploading flight images',
                error
              );
              return throwError(() => error);
            })
          )
        )
      )
      .subscribe();
  }

  private recalcGeorefOptions = () => {
    if (!this.flightInfoForm) {
      return;
    }

    let bestPercision = null;
    let validValues: GeorefMethodEnum[];
    if (this.isRTK) {
      validValues = [GeorefMethodEnum.RTKGCP, GeorefMethodEnum.RTK];
      if (this.tenantQuery.getMLGcpFeatureFlag()) {
        validValues.splice(1, 0, GeorefMethodEnum.MLRTKGCP);
      }
      bestPercision = GeorefMethodEnum.RTKGCP;
    } else {
      validValues = [GeorefMethodEnum.GCP, GeorefMethodEnum.GEOTAG];
      if (this.tenantQuery.getMLGcpFeatureFlag()) {
        validValues.splice(1, 0, GeorefMethodEnum.MLGCP);
      }
      bestPercision = GeorefMethodEnum.GCP;
    }

    // Add auto-georef if prev task had GCP
    if (this.prevTaskHadGCP) {
      validValues.unshift(GeorefMethodEnum.AUTOGEOREF, GeorefMethodEnum.MANUAL);
      bestPercision = null;
    }

    // Disabled georef methods and the reason for the disablement
    const disabledValues = {};

    // Disable some georefrencing methods if site has Local CS
    if (this.uploadQuery.getIsLocalCS()) {
      const disabledReason = $localize`:@@uploadWizard.flightInfo.georefMethodDisabledLCS:Not available in sites with local coordinate system.`;
      disabledValues[GeorefMethodEnum.GEOTAG] = disabledReason;
      disabledValues[GeorefMethodEnum.RTK] = disabledReason;
    }

    // Don't allow to re-run automodel flights (unless rolling shutter changed)
    if (
      this.task?.georefMethod &&
      AUTOMODEL_GEOREF_METHODS.includes(this.task.georefMethod) &&
      this.task.rollingShutter === this.flightInfoForm.get('rollingShutter').value
    ) {
      disabledValues[this.task.georefMethod] =
        $localize`:@@uploadWizard.flightInfo.georefMethodDisabledSameGeoref:Reprocessing will result in same model.`;
    }

    this.geoReferencingOptions = this.geoReferencingOptions.map(option => ({
      value: option.value,
      visible: validValues.includes(option.value),
      best: option.value === bestPercision,
      disabled: option.value in disabledValues,
      disabledReason: disabledValues[option.value]
    }));

    // If current field value doesn't exist anymore - clear field
    const geoReferencingField = this.flightInfoForm.get('geoReferencing');
    const currentValue = geoReferencingField.value;
    if ((currentValue && !validValues.includes(currentValue)) || currentValue in disabledValues) {
      geoReferencingField.patchValue(null);
    }
  };

  get sortedGeoRefOptions() {
    return this.geoReferencingOptions.sort((o1, o2) => GEOREF_SORT_ORDER.indexOf(o1.value) - GEOREF_SORT_ORDER.indexOf(o2.value));
  }

  formatGeorefMethod(georef: GeorefMethodEnum) {
    switch (georef) {
      case GeorefMethodEnum.RTKGCP:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelRTKGCP:RTK data & Manual GCP markup`;
      case GeorefMethodEnum.MLRTKGCP:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelMLRTKGCP:RTK data & AI assisted GCP markup`;
      case GeorefMethodEnum.RTK:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelRTK:Automodel based on RTK data only`;
      case GeorefMethodEnum.GEOTAG:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelGeotag:Automodel based on drone GPS`;
      case GeorefMethodEnum.GCP:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelGCP:Drone GPS & Manual GCP markup`;
      case GeorefMethodEnum.MLGCP:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelMLGCP:Drone GPS & AI assisted GCP markup`;
      case GeorefMethodEnum.AUTOGEOREF:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelAutoGeoref:Automatic geo-referencing based on previous flight`;
      case GeorefMethodEnum.MANUAL:
        return $localize`:@@uploadWizard.flightInfo.georefMethodLabelManual:Geo-referencing based on manual monuments marking`;
    }
  }

  getGeorefMethodHint({ value, disabled, disabledReason }: GeoReferencingOption) {
    let hint = '';

    switch (value) {
      case GeorefMethodEnum.GCP:
      case GeorefMethodEnum.RTKGCP:
        hint += $localize`:@@uploadWizard.flightInfo.georefMethodHintGCP:Model will be generated after ground control points are manually marked on images.`;
        break;

      case GeorefMethodEnum.MLGCP:
      case GeorefMethodEnum.MLRTKGCP:
        hint += $localize`:@@uploadWizard.flightInfo.georefMethodHintMLGCP:Model will be generated after ground control points marked by AI on images are approved.`;
        break;

      case GeorefMethodEnum.RTK:
      case GeorefMethodEnum.GEOTAG:
        hint += $localize`:@@uploadWizard.flightInfo.georefMethodHintAutomodel:Model will be automatically generated based on camera locations.`;
        break;

      case GeorefMethodEnum.AUTOGEOREF:
        hint += $localize`:@@uploadWizard.flightInfo.georefMethodHintAutoGeoref:Model will be automatically generated based on a previously geo-referenced model.`;
        break;

      case GeorefMethodEnum.MANUAL:
        hint += $localize`:@@uploadWizard.flightInfo.georefMethodHintManual:Model will be automatically generated based on a previously geo-referenced model, augmented by manual marking of monuments common to both flights and selected on the Orthophoto of previous flight.`;
        break;
    }

    if (disabled && disabledReason) {
      hint += '\n\n' + disabledReason;
    }

    return hint;
  }

  get dateFormat() {
    return this.localeService.getDateFormat();
  }
}
