import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GetAllNotificationsResponse } from '../../../../../generated/nms/model/getAllNotificationsResponse';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../../auth/state/auth.utils';
import PERMISSIONS from '../../../../auth/state/permissions';
import { ApiPollingService } from '../../../services/api-polling.service';
import { getServiceUrl } from '../../../utils/backend-services';
import { NotificationsQuery } from './notifications.query';
import { Notification, NotificationsStore } from './notifications.store';

const POLLING_FREQ = 2 * 60 * 1000;

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(
    private http: HttpClient,
    private apiPoller: ApiPollingService,
    private store: NotificationsStore,
    private query: NotificationsQuery
  ) {}

  poll() {
    return this.apiPoller.poll(`${getServiceUrl('nms')}/notifications`, PERMISSIONS.notifications.read, POLLING_FREQ, true).pipe(
      tap((response: GetAllNotificationsResponse) => {
        if (response.notifications && response.notifications.length > 0) {
          this.store.upsertMany(response.notifications);
        }
      })
    );
  }

  toggleRead(id: string, read: boolean) {
    return this.http
      .put(`${getServiceUrl('nms')}/notifications/${id}/markAsRead?read=${read}`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.notifications.update }
      })
      .pipe(
        tap(() => {
          this.store.update(id, { read });
        })
      );
  }

  markSeen() {
    // If all notifications are seen, do nothing
    if (this.query.getUnseenCount() === 0) {
      return of(null);
    }

    return this.http
      .put(`${getServiceUrl('nms')}/notifications/markAsSeen`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.notifications.update }
      })
      .pipe(
        tap(() => {
          this.store.update((entity: Notification) => !entity.seen, { seen: true });
        })
      );
  }
}
