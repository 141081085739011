<dialog-layout header="Update users" (closeDialog)="close()">
  <div class="column full-container dialog-container">
    <div class="row field-container">
      <label class="field-label" for="file"><ng-container i18n="@@detailedSite.shared.file">File</ng-container> *</label>
      <input
        class="field-input-control file-upload-field"
        placeholder="Click here to upload a file"
        [value]="fileName || null"
        readonly
        (click)="filePicker.click()"
      />
      <input #filePicker type="file" accept=".csv" (change)="onFileChange($event)" style="display: none" />
    </div>

    <a
      class="template-link"
      href="javascript:void(0);"
      role="button"
      (click)="$event.stopPropagation(); downloadTemplate()"
      i18n="@@shared.files.downloadFileTemplate"
      >Download file template</a
    >

    <div class="row bottom-row">
      <button mat-button class="light-btn" mat-dialog-close i18n="@@general.actionButtons.discard">Discard</button>
      <button mat-flat-button [disabled]="!fileControl.valid" class="dark-btn" (click)="submit()" i18n="@@general.actionButtons.update">Update</button>
    </div>
  </div>
</dialog-layout>
