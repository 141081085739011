import { AbstractControl, ValidatorFn } from '@angular/forms';
import saveAs from 'file-saver';

export const CSV_EXTENSION = 'csv';
export const KML_EXTENSION = 'kml';
export const DXF_EXTENSION = 'dxf';

export const fileExtensionFormValidator: (extension?: string, ...restExtensions: string[]) => ValidatorFn =
  (extension: string, ...restExtensions: string[]) =>
  (control: AbstractControl<File | File[] | undefined | null>) => {
    if (!extension) {
      return null;
    }

    const extensions = [extension, ...restExtensions];
    const value = control?.value;

    if (extensions.length > 0 && value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          const filenames = value.map(file => file.name);
          const invalidFileExtension = filenames.some(filename => !extensions.some(ext => filename.toLowerCase().endsWith('.' + ext)));

          return invalidFileExtension ? { invalidFileExtension: true } : null;
        }
      } else if (!extensions.some(ext => value.name.toLowerCase().endsWith('.' + ext))) {
        return { invalidFileExtension: true };
      }
    }

    return null;
  };

/**
 * Trim and remove "invalid character" sign for CSV headers and body
 *
 * @param value CSV cell string
 * @returns cleaned value string
 */
export function cleanString(value: string) {
  return (value || '').trim().replace(/\uFFFD/g, '');
}

export const fileExtension = (fileName: string) => {
  const extensionFirstIndex = fileName.lastIndexOf('.') + 1;
  return extensionFirstIndex > 0 ? fileName.slice(extensionFirstIndex).toLowerCase() : '';
};

export const fileNameWithoutExtension = (fileName: string) => {
  const extensionFirstIndex = fileName.lastIndexOf('.');
  return extensionFirstIndex > 0 ? fileName.slice(0, extensionFirstIndex) : fileName;
};

const BOM = '\uFEFF';
const CSV_FILE_TYPE = 'text/csv;charset=utf-8';
const CSV_SUFFIX = '.' + CSV_EXTENSION;

export function saveCSV(content: string, fileName: string) {
  saveAs(new Blob([BOM + content], { type: CSV_FILE_TYPE }), fileName.endsWith(CSV_SUFFIX) ? fileName : fileName + CSV_SUFFIX);
}

export function preloadIcons(...paths: string[]) {
  return paths.map(path => {
    const img = new Image();
    img.src = path;
    img.fetchPriority = 'high';
    return img;
  });
}
