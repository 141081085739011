<div class="row base-surface-row" [class.desktop]="device.isDesktop()">
  <span class="info-text">Set</span>
  <ng-select
    class="custom field-select-control hidden-border surface"
    placeholder="Surface"
    [items]="baseSurfaceOptions"
    [selectOnTab]="true"
    bindValue="id"
    bindLabel="name"
    [clearable]="false"
    [searchable]="false"
    [markFirst]="false"
    [ngModel]="baseSurface?.id"
    [readonly]="disabled"
    (change)="onChangeBaseSurface($event)"
    [title]="baseSurface?.name || ''"
  >
    <ng-template ng-option-tmp let-item="item"><span class="base-surface-item-name" [title]="item.name">{{ item.name }}</span></ng-template>
  </ng-select>
  <div
    class="row elevation-input-container"
    *ngIf="(baseSurface | isCustomElevation) && (elevationDisplayValue | isDefined)"
    (click)="onClickElevation()"
    [class.disabled]="disabled"
  >
    <input class="field-input-control" [value]="elevationDisplayValue + ' ' + unitSign" readonly />
    <mat-icon>edit</mat-icon>
  </div>
  <span class="info-text">as</span>
  <ng-select
    *ngIf="isReferenceTypeSelectable; else baseSurfaceLabel"
    class="custom field-select-control hidden-border reference-type"
    placeholder="Reference type"
    [items]="surfaceReferenceTypeItems"
    bindValue="type"
    bindLabel="name"
    [selectOnTab]="true"
    [clearable]="false"
    [searchable]="false"
    [markFirst]="false"
    [ngModel]="referenceType?.type"
    [readonly]="disabled"
    (change)="onChangeSurfaceReferenceType($event)"
  >
  <ng-template ng-option-tmp let-item="item">{{ item.name }}</ng-template>
  </ng-select>
  <ng-template #baseSurfaceLabel>
    <label class="base-surface-label">Base Surface</label>
  </ng-template>
</div>