/**
 * Flow Management Service
 * APIs document (version: 2.8.7)
 *
 * OpenAPI spec version: 2.8.7
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ArtifactModel } from './artifactModel';

export interface TaskResponse {
  additionalCorrections?: boolean;
  artifacts?: Array<ArtifactModel>;
  avgFlightSpeed?: number;
  creationTime?: Date;
  description?: string;
  droneType?: string;
  finishDate?: Date;
  flightSource?: TaskResponse.FlightSourceEnum;
  georefMethod?: TaskResponse.GeorefMethodEnum;
  id?: string;
  imagesCount?: number;
  imagesSize?: number;
  lastModifiedTime?: Date;
  missionFlightDate?: Date;
  mlgcpState?: TaskResponse.MlgcpStateEnum;
  name?: string;
  operatorId?: string;
  ownerId?: string;
  ownerName?: string;
  processCount?: number;
  rollingShutter?: boolean;
  rtk?: boolean;
  rtkOffset?: number;
  selectedImages?: number;
  shutterType?: TaskResponse.ShutterTypeEnum;
  siteId?: string;
  state?: TaskResponse.StateEnum;
  tenantId?: string;
  textureState?: TaskResponse.TextureStateEnum;
  thumbnail?: string;
  type?: TaskResponse.TypeEnum;
  warnings?: Array<TaskResponse.WarningsEnum>;
}
export namespace TaskResponse {
  export type FlightSourceEnum = 'IMAGES' | 'IMPORTED';
  export const FlightSourceEnum = {
    IMAGES: 'IMAGES' as FlightSourceEnum,
    IMPORTED: 'IMPORTED' as FlightSourceEnum
  };
  export type GeorefMethodEnum = 'NOT_AVAILABLE' | 'RTK_GCP' | 'RTK' | 'GEOTAG' | 'GCP' | 'AUTOGEOREF' | 'ML_GCP' | 'ML_RTK_GCP' | 'MANUAL';
  export const GeorefMethodEnum = {
    NOTAVAILABLE: 'NOT_AVAILABLE' as GeorefMethodEnum,
    RTKGCP: 'RTK_GCP' as GeorefMethodEnum,
    RTK: 'RTK' as GeorefMethodEnum,
    GEOTAG: 'GEOTAG' as GeorefMethodEnum,
    GCP: 'GCP' as GeorefMethodEnum,
    AUTOGEOREF: 'AUTOGEOREF' as GeorefMethodEnum,
    MLGCP: 'ML_GCP' as GeorefMethodEnum,
    MLRTKGCP: 'ML_RTK_GCP' as GeorefMethodEnum,
    MANUAL: 'MANUAL' as GeorefMethodEnum
  };
  export type MlgcpStateEnum = 'NOT_STARTED' | 'READY' | 'PROCESSING' | 'FAILED';
  export const MlgcpStateEnum = {
    NOTSTARTED: 'NOT_STARTED' as MlgcpStateEnum,
    READY: 'READY' as MlgcpStateEnum,
    PROCESSING: 'PROCESSING' as MlgcpStateEnum,
    FAILED: 'FAILED' as MlgcpStateEnum
  };
  export type ShutterTypeEnum = 'MECHANICAL' | 'ELECTRONIC' | 'UNKNOWN';
  export const ShutterTypeEnum = {
    MECHANICAL: 'MECHANICAL' as ShutterTypeEnum,
    ELECTRONIC: 'ELECTRONIC' as ShutterTypeEnum,
    UNKNOWN: 'UNKNOWN' as ShutterTypeEnum
  };
  export type StateEnum =
    | 'SUCCESS'
    | 'UPLOADING'
    | 'UPLOADING_BY_OPERATOR'
    | 'UPLOADED_BY_OPERATOR'
    | 'LINKING'
    | 'AUTO_GCP_IN_PROGRESS'
    | 'WAITING_FOR_GCP'
    | 'SBA_IN_PROGRESS'
    | 'GENERATING_3D_MODELS'
    | 'FAILED_LINK'
    | 'FAILED_DPC'
    | 'FAILED_AUTOGEOREF'
    | 'FAILED_SBA'
    | 'ML_GCP_IN_PROGRESS';
  export const StateEnum = {
    SUCCESS: 'SUCCESS' as StateEnum,
    UPLOADING: 'UPLOADING' as StateEnum,
    UPLOADINGBYOPERATOR: 'UPLOADING_BY_OPERATOR' as StateEnum,
    UPLOADEDBYOPERATOR: 'UPLOADED_BY_OPERATOR' as StateEnum,
    LINKING: 'LINKING' as StateEnum,
    AUTOGCPINPROGRESS: 'AUTO_GCP_IN_PROGRESS' as StateEnum,
    WAITINGFORGCP: 'WAITING_FOR_GCP' as StateEnum,
    SBAINPROGRESS: 'SBA_IN_PROGRESS' as StateEnum,
    GENERATING3DMODELS: 'GENERATING_3D_MODELS' as StateEnum,
    FAILEDLINK: 'FAILED_LINK' as StateEnum,
    FAILEDDPC: 'FAILED_DPC' as StateEnum,
    FAILEDAUTOGEOREF: 'FAILED_AUTOGEOREF' as StateEnum,
    FAILEDSBA: 'FAILED_SBA' as StateEnum,
    MLGCPINPROGRESS: 'ML_GCP_IN_PROGRESS' as StateEnum
  };
  export type TextureStateEnum = 'NOT_STARTED' | 'READY' | 'PROCESSING' | 'FAILED';
  export const TextureStateEnum = {
    NOTSTARTED: 'NOT_STARTED' as TextureStateEnum,
    READY: 'READY' as TextureStateEnum,
    PROCESSING: 'PROCESSING' as TextureStateEnum,
    FAILED: 'FAILED' as TextureStateEnum
  };
  export type TypeEnum = 'AUDIT' | 'INSPECTION';
  export const TypeEnum = {
    AUDIT: 'AUDIT' as TypeEnum,
    INSPECTION: 'INSPECTION' as TypeEnum
  };
  export type WarningsEnum =
    | 'TIME_DIFFERENCE'
    | 'DIFFERENT_MODELS'
    | 'DIFFERENT_RESOLUTIONS'
    | 'INVALID_OVERLAP'
    | 'MISSING_COVERAGE'
    | 'NO_LOCATION'
    | 'HEIGHT_DIFFERENCE'
    | 'TOTAL_TIME_DIFFERENCE'
    | 'INVALID_RTK_DATA'
    | 'NO_MODEL';
  export const WarningsEnum = {
    TIMEDIFFERENCE: 'TIME_DIFFERENCE' as WarningsEnum,
    DIFFERENTMODELS: 'DIFFERENT_MODELS' as WarningsEnum,
    DIFFERENTRESOLUTIONS: 'DIFFERENT_RESOLUTIONS' as WarningsEnum,
    INVALIDOVERLAP: 'INVALID_OVERLAP' as WarningsEnum,
    MISSINGCOVERAGE: 'MISSING_COVERAGE' as WarningsEnum,
    NOLOCATION: 'NO_LOCATION' as WarningsEnum,
    HEIGHTDIFFERENCE: 'HEIGHT_DIFFERENCE' as WarningsEnum,
    TOTALTIMEDIFFERENCE: 'TOTAL_TIME_DIFFERENCE' as WarningsEnum,
    INVALIDRTKDATA: 'INVALID_RTK_DATA' as WarningsEnum,
    NOMODEL: 'NO_MODEL' as WarningsEnum
  };
}
