@if (!data.disableClose) {
  <button mat-icon-button (click)="close(undefined, ConfirmationDialogResultEnum.NO)" class="close-button" [disabled]="loading">
    <mat-icon>close</mat-icon>
  </button>
}

<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
  <div [innerHTML]="data.content"></div>
  @if (data.confirmationPhrase) {
    <div class="column confirmation-phrase-container">
      <div class="confirmation-phrase-text">
        <ng-container i18n="@@shared.cofirmationDialog.confirmationPhraseText">To confirm, enter the phrase</ng-container>
        <span class="confirmation-phrase">"{{ data.confirmationPhrase }}"</span>
      </div>
      <input class="field-input-control confirmation-phrase-control" [formControl]="confirmationPhraseControl" />
    </div>
  }
</div>

<div mat-dialog-actions>
  @if (data.alternativeButtonCaption) {
    <button
      mat-stroked-button
      class="light-btn alternative-button"
      (click)="close($event, ConfirmationDialogResultEnum.ALTERNATIVE)"
      [disabled]="loading && beforeClosedValidationDialogResult !== ConfirmationDialogResultEnum.ALTERNATIVE"
      [class.disable-click]="loading"
    >
      @if (loading && beforeClosedValidationDialogResult === ConfirmationDialogResultEnum.ALTERNATIVE) {
        <mat-spinner diameter="18" />
      } @else {
        {{ data.alternativeButtonCaption }}
      }
    </button>
  }
  @if (data.noCaption) {
    <button
      mat-stroked-button
      class="light-btn"
      (click)="close($event, ConfirmationDialogResultEnum.NO)"
      [disabled]="loading && beforeClosedValidationDialogResult !== ConfirmationDialogResultEnum.NO"
      [class.disable-click]="loading"
    >
      @if (loading && beforeClosedValidationDialogResult === ConfirmationDialogResultEnum.NO) {
        <mat-spinner diameter="18" />
      } @else {
        {{ data.noCaption }}
      }
    </button>
  }
  @if (data.yesCaption) {
    <button
      mat-flat-button
      class="dark-btn"
      (click)="close($event, ConfirmationDialogResultEnum.YES)"
      [disabled]="!confirmationPhraseValid || loading && beforeClosedValidationDialogResult !== ConfirmationDialogResultEnum.YES"
      [class.disable-click]="loading"
    >
      @if (loading && beforeClosedValidationDialogResult === ConfirmationDialogResultEnum.YES) {
        <mat-spinner diameter="18" />
      } @else {
        {{ data.yesCaption }}
      }
    </button>
  }
</div>
